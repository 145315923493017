import {
  IBancruptApplicationData,
  INITIAL_HUMAN_DATA,
} from "../interfaces/bancruptApplication.interface";

export const getInitialBancruptApplicationData =
  (): IBancruptApplicationData => ({
    relationshipStatus: "Холост",
    spouse: null,
    presenceOfChildren: "Нет",
    children: [],

    conditions: [],

    alliance: {
      id: "",
      code: "",
      name: "",
    },
  });
