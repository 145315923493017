import {PROJECT_NAME_HTML_RUS} from "data/common.data";
import { APP_PATHS } from "data/paths.data";
import { IResetPasswordDto } from "interfaces/auth.interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { resetPasswordService } from "services/auth/login.service";
import { sendVerificationSMSService } from "services/auth/register.service";

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [isCodeSent, setIsCodeSent] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState<IResetPasswordDto>({
    phoneNumber: "",
    code: "",
    password: "",
    passwordConfirm: "",
  });

  const handleChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setErrorMessage(null);

    const { passwordConfirm, ...formDto } = form;

    if (passwordConfirm !== form.password) {
      return setErrorMessage("Убедитесь что повторили пароль правильно");
    }

    const res = await resetPasswordService(formDto);

    console.log(res)

    if (!res) {
      return setErrorMessage("Ошибка сервера");
    }

    if (!res.success && !isCodeSent) {
      return setErrorMessage(
        res.message || "Ошибка сервера"
      );
    }

    if (res?.isCodeSent) {
      return setIsCodeSent(res.isCodeSent);
    }

    if (!res.success) {
      return setErrorMessage("Код не верен");
    }

    history.push(APP_PATHS.LOGIN);
  };

  return (
    <>
      <div className='wrapper__little entrance'>
        <h1 className='title'><img src="img/logo.png" style={{height: "250px", width: "300px"}} alt="" /></h1>
        <p className='text null entrance__about'>
           банкронства физических лиц
        </p>
        <form className='form' onSubmit={handleSubmit}>
          <h2
            className='form__title title_28 null'
            style={{ marginBottom: "20px" }}
          >
            Восстановление пароля
          </h2>
          <input
            type='tel'
            name='phoneNumber'
            pattern='^((\+?[0-9]{1,2})+([0-9]){10})$'
            value={form.phoneNumber}
            onChange={handleChange}
            className='input input_mt2'
            placeholder='Мобильный телефон'
            required
            disabled={isCodeSent}
          />

          {isCodeSent && (
            <>
              <input
                type='text'
                name='code'
                onChange={handleChange}
                placeholder='Код с СМС'
                className='input input_mt2'
                inputMode='numeric'
                pattern='[0-9]*'
                autoComplete='one-time-code'
                required
              />
              <input
                type='password'
                name='password'
                value={form.password}
                onChange={handleChange}
                className='input input_mt2'
                placeholder='Пароль'
                autoComplete='new-password'
                required
              />
              <input
                type='password'
                name='passwordConfirm'
                value={form.passwordConfirm}
                onChange={handleChange}
                className='input input_mt2'
                placeholder='Повторите пароль'
                autoComplete='new-password'
                required
              />
            </>
          )}

          {errorMessage && (
            <span
              style={{ display: "block", color: "#FF4040", marginTop: "34px" }}
            >
              {errorMessage}
            </span>
          )}

          <button type='submit' className='entrance_btn button trz'>
            Продолжить
          </button>
        </form>

        {isCodeSent && (
          <span
            className='entrance__texts text_14 link_blue'
            onClick={async () => await sendVerificationSMSService()}
          >
            Отправить ещё раз?
          </span>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
