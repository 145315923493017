import htmr from "htmr";
import { Fragment, useEffect, useState } from "react";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import {
  CREDITORS_TYPES,
  INIT_OBLIGATION_ITEM,
  INIT_PAYMENT_ITEM,
} from "data/creditors.data";
import { useDispatch, useSelector } from "react-redux";
import { setCreditors } from "store/modules/Application/creditors.module";
import {
  insertDocumentService,
  generateDocumentsService,
} from "services/docs/docs.service";
import { useHistory } from "react-router-dom";
import { APP_PATHS } from "data/paths.data";
import { BankInput } from "components/common/BankInput";
import { ApplicationButton } from "components/common/ApplicationButton";
import { AddressInput } from "components/common/Addressnput";
import { IRootState } from "../../store";
import { Input } from "antd";
import { QuestionTooltip } from "../../components/common/Tooltip";

const CreditorsPage: React.FC = () => {
  const history = useHistory();

  const creditors = useSelector(
    (state: IRootState) => state.creditors
  );
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const res = await insertDocumentService(
      "listOfCreditorsAndDebtors",
      creditors
    );

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    const resGeneration = await generateDocumentsService();

    setIsLoading(false);

    if (!resGeneration.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.BANCRUPT_APPLICATION);
  };

  const handleChange = (e: any, section, table, i?) => {
    if (table === "nonMoneyObligations") {
      creditors[section][table] = e.target.value;
    } else {
      creditors[section][table][i][e.target.name] = e.target.value;
    }

    dispatch(setCreditors({ ...creditors }));
  };

  const handleTargetChange = (value, targetName, section, table, i) => {
    creditors[section][table][i][targetName] = value;

    dispatch(setCreditors({ ...creditors }));
  };

  const addItem = (e, section, table) => {
    e.preventDefault();

    if (table === "obligations") {
      creditors[section][table].push({ ...INIT_OBLIGATION_ITEM });
    }

    if (table === "payments") {
      creditors[section][table].push({ ...INIT_PAYMENT_ITEM });
    }

    dispatch(setCreditors({ ...creditors }));
  };

  const removeItem = (e, section, table) => {
    e.preventDefault();

    if (creditors[section][table].length > 1) {
      creditors[section][table].pop();
      dispatch(setCreditors({ ...creditors }));
    }
  };

  const handleFocus = (e: any, section, table, i) => {
    let currencied =
      Math.floor(
        parseFloat(
          e.target.value.replace(" руб.", "").replace(" ", "").replace(",", ".")
        ) * 100
      ) / 100;

    creditors[section][table][i][e.target.name] = !isNaN(currencied)
      ? currencied
      : "";

    dispatch(setCreditors({ ...creditors }));
  };

  const setRUB = (e: any, section, table, i) => {
    let currencied =
      Math.floor(
        parseFloat(
          e.target.value.replace(" руб.", "").replace(" ", "").replace(",", ".")
        ) * 100
      ) / 100;

    creditors[section][table][i][e.target.name] = !isNaN(currencied)
      ? currencied + " руб."
      : "";

    dispatch(setCreditors({ ...creditors }));
  };

  const handleCreditorOrDebtorSelect = (option, section, table, i) => {
    creditors[section][table][i]["creditorOrDebtor"] = option.value;
    creditors[section][table][i]["location"] = option.strcuraddr;

    dispatch(setCreditors({ ...creditors }));
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <div className='application__form application__form_p'>
          {Object.keys(creditors).map((section, i) => (
            <Fragment key={i}>
              <div style={{marginTop: '50px'}} className='formLineOver'>
                {/* formLine_middle */}
                <div
                  className={`formLine ${
                    !section.includes("creditor") ? "formLine_middle" : ""
                  }`}
                >
                  <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                    {CREDITORS_TYPES[section].title}
                  </span>
                  <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21 mt_20'>
                    Денежные обязательства (кредиты, займы, коммунальные платежи, поручительства и пр.)
                  </span>
                  <div className='formLine__table mt_12'>
                    <ul className='null formLine__list formLine__list_title'>
                      <li className='formLine__item formLine__item_52 formLine__item_title text_12_14'>
                        № п/п
                      </li>
                      <li className='formLine__item formLine__item_108 formLine__item_title text_12_14'>
                        Содержание обязательства
                        <QuestionTooltip text={section.includes("creditor") ? "Пример: Кредит" : "Пример: Заем"}/>
                      </li>
                      <li
                        className={`formLine__item ${
                          section.includes("creditor")
                            ? "formLine__item_108"
                            : "formLine__item_158"
                        } formLine__item_title text_12_14`}
                      >
                        {section.includes("creditor") ? "Кредитор" : "Должник"}
                        <QuestionTooltip
                          text={
                            section.includes("creditor")
                              ? "Пример: Банк БТБ (ПАО)"
                              : "Пример: Иванов Иван Иванович"
                          }
                        />
                      </li>
                      <li className='formLine__item formLine__item_218 formLine__item_title text_12_14'>
                        Место нахождения (место жительства) кредитора
                        <QuestionTooltip
                          text={
                            section.includes("creditor")
                              ? "Пример: 190000; г. Санкт-Петербург, ул. Большая Морская, 29"
                              : "Пример: Г. Москва, ул. Тверская 1"
                          }
                        />
                      </li>
                      <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                        Основание возникновения
                        <QuestionTooltip
                          text={
                            section.includes("creditor")
                              ? "Пример: Кредитный договор № 625/0040-0530318 от 06.10.2016 года"
                              : "Пример: Договор займа от 01.01.2001"
                          }
                        />
                      </li>
                      <li className='formLine__item formLine__item_108 formLine__item_title text_12_14'>
                        Задолженность, всего
                        <QuestionTooltip text='Пример: 823980 руб.' />
                      </li>
                      {section.includes("creditor") && (
                        <>
                          <li className='formLine__item formLine__item_108 formLine__item_title text_12_14'>
                            в том числе задолженность
                            <QuestionTooltip text='Пример: 823980 руб.' />
                          </li>
                          <li className='formLine__item formLine__item_108 formLine__item_title text_12_14'>
                            Штрафы, пени и иные санкции
                            <QuestionTooltip text='Пример: 823980 руб.' />
                          </li>
                        </>
                      )}
                    </ul>

                    {creditors[section].obligations.map(
                      (item: any, j: number) => (
                        <Fragment key={j}>
                          <ul className='null formLine__list formLine__list_start'>
                            <li className='formLine__item formLine__item_number formLine__item_52 text_12_14'>
                              {i + 1 + "." + (j + 1)}
                            </li>
                            <li className='formLine__item formLine__item_108'>
                              {section.includes("creditor") ? (
                                <select
                                  name='type'
                                  className='formLine__input'
                                  value={item.type}
                                  style={{ height: "29px" }}
                                  onChange={(e) =>
                                    handleChange(e, section, "obligations", j)
                                  }
                                >
                                  {[
                                    "",
                                    "Кредит",
                                    "Заём",
                                    "Поручительство",
                                    "Ипотека",
                                    "Расписка",
                                    "Долг по коммунальным платежам",
                                  ].map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type='text'
                                  name='type'
                                  className='formLine__input'
                                  style={{ height: "29px" }}
                                  value={item.type}
                                  onChange={(e) =>
                                    handleChange(e, section, "obligations", j)
                                  }
                                />
                              )}
                            </li>
                            {section.includes("creditor") ? (
                              <>
                                <li className='formLine__item formLine__item_108'>
                                  <BankInput
                                    value={item.creditorOrDebtor}
                                    name='creditorOrDebtor'
                                    className='formLine__input'
                                    handleChange={(value) =>
                                      handleTargetChange(
                                        value,
                                        "creditorOrDebtor",
                                        section,
                                        "obligations",
                                        j
                                      )
                                    }
                                    handleSelect={(option) =>
                                      handleCreditorOrDebtorSelect(
                                        option,
                                        section,
                                        "obligations",
                                        j
                                      )
                                    }
                                  />
                                </li>
                                <li className='formLine__item formLine__item_218'>
                                  <Input.TextArea
                                    className='formLine__input formLine__input_textarea'
                                    name='location'
                                    value={item.location}
                                    onChange={(e) =>
                                      handleChange(e, section, "obligations", j)
                                    }
                                    autoSize
                                  />
                                </li>
                              </>
                            ) : (
                              <>
                                <li className='formLine__item formLine__item_158'>
                                  <input
                                    type='text'
                                    name='creditorOrDebtor'
                                    className='formLine__input '
                                    value={item.creditorOrDebtor}
                                    onChange={(e) =>
                                      handleChange(e, section, "obligations", j)
                                    }
                                  />
                                </li>
                                <li className='formLine__item formLine__item_218'>
                                  <AddressInput
                                    name='location'
                                    value={item.location}
                                    handleChange={(value) =>
                                      handleTargetChange(
                                        value,
                                        "location",
                                        section,
                                        "obligations",
                                        j
                                      )
                                    }
                                    className='formLine__input formLine__input_textarea'
                                    rows={1}
                                    autoSize
                                  />
                                </li>
                              </>
                            )}
                            <li className='formLine__item formLine__item_170'>
                              <Input.TextArea
                                className='formLine__input formLine__input_textarea'
                                name='origin'
                                value={item.origin}
                                onChange={(e) =>
                                  handleChange(e, section, "obligations", j)
                                }
                                autoSize
                              />
                            </li>
                            <li className='formLine__item formLine__item_108'>
                              <input
                                type='text'
                                name='total'
                                className='formLine__input '
                                value={item.total}
                                onChange={(e) =>
                                  handleChange(e, section, "obligations", j)
                                }
                                onBlur={(e) =>
                                  setRUB(e, section, "obligations", j)
                                }
                                onFocus={(e) =>
                                  handleFocus(e, section, "obligations", j)
                                }
                              />
                            </li>
                            {section.includes("creditor") && (
                              <>
                                <li className='formLine__item formLine__item_108'>
                                  <input
                                    type='text'
                                    name='indebtedness'
                                    className='formLine__input'
                                    value={item.indebtedness}
                                    onChange={(e) =>
                                      handleChange(e, section, "obligations", j)
                                    }
                                    onBlur={(e) =>
                                      setRUB(e, section, "obligations", j)
                                    }
                                    onFocus={(e) =>
                                      handleFocus(e, section, "obligations", j)
                                    }
                                  />
                                </li>
                                <li className='formLine__item formLine__item_108'>
                                  <input
                                    type='text'
                                    name='fine'
                                    className='formLine__input'
                                    value={item.fine}
                                    onChange={(e) =>
                                      handleChange(e, section, "obligations", j)
                                    }
                                    onBlur={(e) =>
                                      setRUB(e, section, "obligations", j)
                                    }
                                    onFocus={(e) =>
                                      handleFocus(e, section, "obligations", j)
                                    }
                                  />
                                </li>
                              </>
                            )}
                          </ul>
                        </Fragment>
                      )
                    )}
                    <div style={{
                      position: "relative",
                      bottom: 0,
                      margin: "20px auto",
                    }}>
                    <button
                      className='formLine__add'
                      onClick={(e) => addItem(e, section, "obligations")}
                    >
                      <img
                        src='img/add.svg'
                        alt='add'
                        className='svg formLine__addIcon'
                      />
                    </button>
                    {creditors[section]["obligations"].length > 1 && <button
                        className='formLine__remove'
                        onClick={(e) => removeItem(e, section, "obligations")}
                    >
                      <img
                          src='img/minus.svg'
                          alt='add'
                          className='svg formLine__addIcon'
                      />
                    </button>}
                    </div>
                  </div>
                </div>
              </div>

              <div className='formLineOver formLineOver_nb mt_60'>
                <div className='formLine formLine_little formLine_nb'>
                  <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                    Обязательные платежи (налоги, штрафы, исполнительские сборы)
                  </span>
                  <div className='formLine__table mt_12'>
                    <ul className='null formLine__list formLine__list_title'>
                      <li className='formLine__item formLine__item_52 formLine__item_title text_12_14'>
                        № п/п
                      </li>
                      <li className='formLine__item formLine__item_218 formLine__item_title text_12_14'>
                        Наименование налога, сбора или иного обязательного
                        платежа
                        <QuestionTooltip text='Пример: Транспортный налог' />
                      </li>
                      <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                        Недоимка
                        <QuestionTooltip text='Пример: 823980 руб.' />
                      </li>
                      <li className='formLine__item formLine__item_200 formLine__item_title text_12_14'>
                        Штрафы, пени и иные санкции
                        <QuestionTooltip text='Пример: 823980 руб.' />
                      </li>
                    </ul>
                    {creditors[section].payments.map((item: any, j: number) => (
                      <ul
                        key={j}
                        className='null formLine__list formLine__list_start'
                      >
                        <li className='formLine__item formLine__item_number formLine__item_52 text_12_14'>
                          {i + 1 + "." + (j + 1)}
                        </li>
                        <li className='formLine__item formLine__item_218'>
                          <input
                            type='text'
                            name='name'
                            className='formLine__input '
                            value={item.name}
                            onChange={(e) =>
                              handleChange(e, section, "payments", j)
                            }
                          />
                        </li>
                        <li className='formLine__item formLine__item_170'>
                          <input
                            type='text'
                            name='arrears'
                            className='formLine__input '
                            value={item.arrears}
                            onChange={(e) =>
                              handleChange(e, section, "payments", j)
                            }
                            onBlur={(e) => setRUB(e, section, "payments", j)}
                            onFocus={(e) =>
                              handleFocus(e, section, "payments", j)
                            }
                          />
                        </li>
                        <li className='formLine__item formLine__item_200'>
                          <input
                            type='text'
                            name='fine'
                            className='formLine__input '
                            value={item.fine}
                            onChange={(e) =>
                              handleChange(e, section, "payments", j)
                            }
                            onBlur={(e) => setRUB(e, section, "payments", j)}
                            onFocus={(e) =>
                              handleFocus(e, section, "payments", j)
                            }
                          />
                        </li>
                      </ul>
                    ))}
                    <div style={{
                      position: "relative",
                      bottom: 0,
                      margin: "20px auto",
                    }}>
                      <button
                          className='formLine__add'
                          onClick={(e) => addItem(e, section, "payments")}
                      >
                        <img
                            src='img/add.svg'
                            alt='add'
                            className='svg formLine__addIcon'
                        />
                      </button>
                      {creditors[section]["payments"].length > 1 && <button
                          className='formLine__remove'
                          onClick={(e) => removeItem(e, section, "payments")}
                      >
                        <img
                            src='img/minus.svg'
                            alt='add'
                            className='svg formLine__addIcon'
                        />
                      </button>}
                    </div>
                  </div>
                </div>
              </div>

              <div className='formLineOver formLineOver_nb mt_60'>
                <div className='formLine formLine_little formLine_nb'>
                  <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                    Неденежные обязательства (Ваше обязательство выполнить работу, оказать услугу и пр.)
                  </span>
                  <div className='formLine__table mt_12'>
                    <ul className='null formLine__list formLine__list_title'>
                      <li
                        className='formLine__item formLine__item_title text_12_14'
                        style={{ width: "100%" }}
                      >
                        <span>Содержание обязательств</span>
                        <QuestionTooltip text='Например: обязательства подрядчика - выполнить работы или устранить их недостатки' />
                      </li>
                    </ul>
                    <ul className='null formLine__list formLine__list_start'>
                      <li className='formLine__item' style={{ width: "100%" }}>
                        <textarea
                          name='content'
                          className='formLine__input '
                          style={{ height: "150px" }}
                          value={creditors[section].nonMoneyObligations}
                          onChange={(e) =>
                            handleChange(e, section, "nonMoneyObligations")
                          }
                          onKeyPress={(e) =>
                            e.key === "Enter" && e.stopPropagation()
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}

          {errorMessage && (
            <span
              style={{
                display: "block",
                color: "#FF4040",
                marginTop: "20px",
              }}
            >
              {errorMessage}
            </span>
          )}
        </div>
        <div className='applicationForms__inputs_line mt_20'>
          <div className='checkbox checkbox_ml'>
            <input
              type='checkbox'
              className='check'
              id='check'
              onClick={() => setIsAgreed(!isAgreed)}
            />
            <label className='checkbox__text text_14' htmlFor='check'>
              <span className='checkbox__textInner'>
                Достоверность и полноту настоящих сведений подтверждаю
              </span>
            </label>
          </div>
        </div>
        {errorMessage && (
          <span
            style={{
              display: "block",
              color: "#FF4040",
              marginTop: "40px",
            }}
          >
            {errorMessage}
          </span>
        )}

        <ApplicationButton disabled={!isAgreed} isLoading={isLoading}>
          Заполнить данные. Шаг 5
        </ApplicationButton>
      </form>
    </ApplicationWrapper>
  );
};

export default CreditorsPage;
