import React from 'react';
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";

import { APP_PATHS } from "data/paths.data";

type ILink = {
  path: string;
  title: string;
};

type IProps = {
  links: ILink[];
  progressLine?: JSX.Element;
};

const BreadCrumbs: React.FC<IProps> = ({
  links,
  progressLine: ProgressLine,
}) => {
  const location = useLocation();
  if (links.length) {
    links[links.length - 1].path = location.pathname;
  }

  return (
    <div className='breadCrumbs breadCrumbs_light'>
      <div className='wrapper__big'>
        <ul className='breadCrumbs__list null'>
          <li className='breadCrumbs__item'>
            <Link to={APP_PATHS.HOME} className='breadCrumbs__link'>
              <img className='homeIcon' src='img/home.png' alt='' /> Главная
            </Link>
          </li>
          {links &&
            links.map((link, i) => (
              <li key={i} className='breadCrumbs__item'>
                <img
                  src='img/right.svg'
                  alt={link.title}
                  className='svg breadCrumbs__right'
                />
                <Link
                  to={`${i < links.length ? link.path : "/lol"}`}
                  className={classNames("breadCrumbs__link", {
                    active: i === links.length - 1,
                  })}
                >
                  {link.title}
                </Link>
              </li>
            ))}
        </ul>
        {ProgressLine}
      </div>
    </div>
  );
};

export default BreadCrumbs;
