import './style.scss';

import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IRootState } from 'store';
import { BreadCrumbs } from 'components/common';
import { APP_PATHS } from 'data/paths.data';
import { PAGE_TITLES } from 'data/strings.data';
import ChangePasswordModal from 'components/other/ChangePasswordModal';
import SecurityQuestionModal from 'components/other/SecurityQuestionModal';
import TwoFAModal from 'components/other/TwoFAModal';
import ChangeAvatarModal from 'components/other/ChangeAvatarModal';
import DeleteAccountModal from 'components/other/DeleteAccountModal';
import {getDocumentsData, getSubjects} from "../../services/docs/docs.service";

const getAddressString = (data) => {
  const region = data.region ? `${data.region},` : ``;
  const city = data.city ? `г. ${data.city},` : ``;
  const street = data.street ? `ул. ${data.street},` : ``;
  const homeNumber = data.homeNumber ? `д. ${data.homeNumber},` : ``;
  const apartmentNumber = data.apartmentNumber
      ? `кв. ${data.apartmentNumber}`
      : ``;
  return `${region} ${city} ${street} ${homeNumber} ${apartmentNumber}`;
};

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SettingsPage: React.FC<IProps> = ({ auth }) => {
  const [data, setData] = useState({
    snils: '',
    dateOfBirth: '',
    placeOfBirth: '',
    region: '',
    city: '',
    street: '',
    homeNumber: '',
    apartmentNumber: '',
    firstName: '',
    lastName: '',
    middleName: ''
  });
  const [state, setState] = useState({
    modalsVisible: {
      changePassword: false,
      securityQuestion: false,
      twoFA: false,
      changeAvatar: false,
      deleteAccount: false,
    },
    contactInfoVisible: false,
  });

  const toggleModalVisible = (label: string, value?: boolean) => {
    const isVisible = value || !state.modalsVisible[label];
    setState({
      ...state,
      modalsVisible: {
        ...state.modalsVisible,
        [label]: isVisible,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDocumentsData('debtorsQuestionnaire');
      if (res?.data?.fields) {
        setData(res?.data?.fields);
      }
    };
    fetchData();
  }, []);

  return (
    <Fragment>
      <div className='titleBlock'>Настройки учетной записи</div>

      <BreadCrumbs
        links={[{ title: PAGE_TITLES.SETTINGS, path: APP_PATHS.SETTINGS }]}
      />

      <div className='settings settings_mt'>
        <div className='settings__block'>
          <div className='settings__titles'>
            <span className='settings__title title_27'>
              Основная информация
            </span>
            <Link to={APP_PATHS.CLIENT_DATA} className='link_20 trz'>
              Редактировать
            </Link>
          </div>
          <ul className='settings__list null'>
            <li className='settings__item'>
              <span className='settings__parametr'>ФИО</span>
              <span className='settings__value'>
                {data.lastName} {data.firstName}{' '}
                {data.middleName}
              </span>
            </li>
            <li className='settings__item'>
              <span className='settings__parametr'>Дата рождения</span>
              <span className='settings__value'>{data['dateOfBirth'] ? data['dateOfBirth'] : ''}</span>
            </li>
            <li className='settings__item'>
              <span className='settings__parametr'>Место рождения</span>
              <span className='settings__value'>{data['placeOfBirth'] ? data['placeOfBirth'] : ''}</span>
            </li>
            <li className='settings__item'>
              <span className='settings__parametr'>
                Документ, удостоверяющий личность
              </span>
              <span className='settings__value'>
                {data['documentType'] ? data['documentType'] : ''}
                {' '}
                {data['documentSerial'] ? data['documentSerial'] : ''}
              </span>
            </li>
            <li className='settings__item settings__item_abs'>
              <span className='settings__parametr settings__parametr_quest'>
                СНИЛС
              </span>
              <span className='settings__value'>{data?.snils ? data['snils'] : ''}</span>
            </li>
          </ul>
        </div>

        <div className='settings__block'>
          <div className='settings__titles'>
            <span className='settings__title title_27'>Безопасность</span>
          </div>
          <ul className='settings__list null'>
            <li className='settings__item'>
              <a
                href='#twosign'
                onClick={() => toggleModalVisible('changePassword')}
                className='fancybox link_20 settings__link'
              >
                Изменить пароль
              </a>
            </li>
            <li className='settings__item'>
              <a
                href='#twosign4'
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalVisible('deleteAccount');
                }}
                className='fancybox link_20 settings__link'
              >
                Удалить учетную запись
              </a>
            </li>
          </ul>
        </div>

        <div className='settings__block'>
          <div className='settings__titles settings__titles_acc'>
            <span className='settings__title title_27'>
              Контактная информация
            </span>
            <div
              className={classNames('settings__down trz', {
                active: state.contactInfoVisible,
              })}
              onClick={() =>
                setState({
                  ...state,
                  contactInfoVisible: !state.contactInfoVisible,
                })
              }
            >
              <img
                src='img/down_2.png'
                alt=''
                className='trz settings__downIcon'
              />
            </div>
          </div>
          <div
            className={classNames('settings__down_add', {
              active: state.contactInfoVisible,
            })}
          >
            <ul className='settings__list null'>
              <li className='settings__item settings__item_2'>
                <span className='settings__parametr'>Электронная почта</span>
                <span className='settings__value settings__value_v2'>
                  {auth.user.email}
                  {auth.user.isVerifiedEmail && (
                    <div
                      className='checking checking__position'
                      style={{ marginTop: -6 }}
                    >
                      <img
                        src='img/check_2.svg'
                        alt=''
                        className='svg check__icon'
                      />
                    </div>
                  )}
                </span>
              </li>
              <li className='settings__item settings__item_2'>
                <span className='settings__parametr'>Мобильный телефон</span>
                <span className='settings__value settings__value_v2'>
                  {auth.user.phoneNumber}
                  {auth.user.isVerifiedSMS && (
                    <div
                      className='checking checking__position'
                      style={{ marginTop: -6 }}
                    >
                      <img
                        src='img/check_2.svg'
                        alt=''
                        className='svg check__icon'
                      />
                    </div>
                  )}
                </span>
              </li>
              <li className='settings__item settings__item_2'>
                <span className='settings__parametr'> Адрес регистрации</span>
                <span className='settings__value settings__value_v2'>
                  {getAddressString(data)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ChangePasswordModal
        isVisible={state.modalsVisible.changePassword}
        onCancel={() => toggleModalVisible('changePassword', false)}
        width='auto'
      />
      <SecurityQuestionModal
        isVisible={state.modalsVisible.securityQuestion}
        onCancel={() => toggleModalVisible('securityQuestion', false)}
        width='auto'
      />
      <TwoFAModal
        isVisible={state.modalsVisible.twoFA}
        onCancel={() => toggleModalVisible('twoFA', false)}
        width='auto'
      />
      <ChangeAvatarModal
        isVisible={state.modalsVisible.changeAvatar}
        onCancel={() => toggleModalVisible('changeAvatar', false)}
        width='auto'
      />
      <DeleteAccountModal
        isVisible={state.modalsVisible.deleteAccount}
        onCancel={() => toggleModalVisible('deleteAccount', false)}
        width='auto'
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
