import axios from "axios";
import { ILoginDto, IResetPasswordDto } from "interfaces/auth.interfaces";
import { API_SERVER_URL } from "config/env";
import { loginMutation, resetPasswordMutation } from "data/mutations.data";

export const loginService = async ({
  emailOrPhoneNumber,
  password,
  useSessionStorage,
}: ILoginDto) => {
  let token;

  try {
    const res = await axios.post(API_SERVER_URL, {
      query: loginMutation,
      variables: { emailOrPhoneNumber, password },
    });

    token = res.data.data?.login;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  if (!token) {
    return {
      success: false,
      message: "Неверный логин или пароль",
    };
  }

  useSessionStorage
    ? sessionStorage.setItem("authToken", token)
    : localStorage.setItem("authToken", token);

  return {
    success: true,
  };
};

export const resetPasswordService = async ({
  phoneNumber,
  code,
  password,
}: IResetPasswordDto) => {
  let status;

  try {
    const res = await axios.post(API_SERVER_URL, {
      query: resetPasswordMutation,
      variables: { phoneNumber, code, password },
    });

    status = res.data.data;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  console.log(status)

  if (status?.errors && status?.errors[0].message === "Authenticate") {
    return {
      success: false,
      message: "Такого номера телефона не существует",
    };
  }

  if (!status?.resetPassword) {
    return {
      success: false,
      message: "Пользователь с таким номером телефона не существует",
    };
  }

  if (status.resetPassword === "waitingForSMSCode") {
    return {
      success: true,
      isCodeSent: true,
    };
  }

  if (status.resetPassword === "done") {
    return {
      success: true,
    };
  }

  return {
    success: false,
  };
};
