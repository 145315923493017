import React, {useEffect, useState} from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import { IRootState } from 'store';
import { APP_PATHS } from 'data/paths.data';
import { fetchAuthUserService } from 'services/auth/fetchAuthUser.service';
import AuthLayout from 'components/layouts/AuthLayout';

const AuthRoute: React.FC<RouteProps> = (props) => {
	const auth = useSelector((state: IRootState) => state.auth);
	const dispatch = useDispatch()
	const [isDataReady, setIsDataReady] = useState(false);

	useEffect(() => {
		fetchAuthUserService()(dispatch);
		setIsDataReady(true);
	}, [dispatch]);

	if (!isDataReady) {
		return <></>;
	}

	if (auth.loading) {
		return <></>;
	}

	// is authenticated and verified
	if (auth.isAuthenticated && auth.isVerified) {
		return <Redirect to={APP_PATHS.HOME} />;
	}

	if (
		auth.isAuthenticated &&
		!auth.isVerified &&
		props?.path !== APP_PATHS.SMS_VERIFICATION
	) {
		return <Redirect to={APP_PATHS.SMS_VERIFICATION} />;
	}

	if (!auth.isAuthenticated && props?.path === APP_PATHS.SMS_VERIFICATION) {
		return <Redirect to={APP_PATHS.LOGIN} />;
	}

	return (
		<AuthLayout>
			<Route {...props} />
		</AuthLayout>
	);
};

export default AuthRoute;
