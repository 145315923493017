import { Input } from "antd";
import { PROJECT_NAME_HTML_RUS } from "data/common.data";
import { APP_PATHS } from "data/paths.data";
import { ILoginDto } from "interfaces/auth.interfaces";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { loginService } from "services/auth/login.service";

const LoginPage: React.FC = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState<ILoginDto>({
    emailOrPhoneNumber: "",
    password: "",
    useSessionStorage: false,
  });

  const handleChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await loginService(form);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.HOME);
  };

  return (
    <>
      <div className='wrapper__little entrance'>
        <h1 className='title'><img src="img/logo.svg" style={{height: "250px", width: "300px"}} alt="" /></h1>
        <p className='text null entrance__about'>
          Единая система формирования заявлений о банкротстве физических лиц
        </p>
        <form action='#' className='form'>
          <h2 className='form__title title_28 null'>Вход</h2>
          <span className='text_grey'>для портала</span>
          <label>
            <input
              type='text'
              name='emailOrPhoneNumber'
              onChange={handleChange}
              placeholder='Электронная почта'
              className='input input_mt'
            />
          </label>
          <label>
            <Input.Password
              name='password'
              onChange={handleChange}
              placeholder='Пароль'
              required
            />
          </label>
          <div className='checkbox checkbox_mt checkbox_mt'>
            <input
              type='checkbox'
              name='useSessionStorage'
              className='check'
              checked={form.useSessionStorage}
              onChange={handleCheckboxChange}
              id='check'
            />
            <label className='checkbox__text text_14' htmlFor='check'>
              Чужой компьютер
            </label>
          </div>

          {errorMessage && (
            <span
              style={{ display: "block", color: "#FF4040", marginTop: "20px" }}
            >
              {errorMessage}
            </span>
          )}

          <button
            type='submit'
            onClick={handleSubmit}
            className='entrance_btn button trz'
          >
            Войти
          </button>
          <Link
            to={APP_PATHS.RESET_PASSWORD}
            className='entrance__password trz text_greys'
          >
            Я не помню пароль
          </Link>
        </form>
        <span className='entrance__texts text_14'>
          <Link to={APP_PATHS.REGISTER} className='link_blue'>
            Зарегистрируйтесь
          </Link>{" "}
          для полного доступа
        </span>
      </div>
    </>
  );
};

export default LoginPage;
