import { IUser } from 'interfaces/users.interfaces';

export const getInitialUser = (): IUser => ({
	id: null,
	firstName: null,
	lastName: null,
	middleName: null,
	email: null,
	phoneNumber: null,
	avatar: null,
	isVerifiedSMS: false,
	isVerifiedEmail: false,
	isPaid: false,
	notifications: []
});
