import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { rootReducer } from 'store';
import {YMInitializer} from "react-yandex-metrika";

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<YMInitializer accounts={[87357132]} options={{ defer: true, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true }} />
		<App />
	</Provider>,
	document.getElementById('root')
);
