import { IUser } from 'interfaces/users.interfaces';
import { typedAction } from 'store/helpers';

export const setAuthUser = (user: IUser) => {
	return typedAction('auth/SET_AUTH_USER', user);
};

export const setAuthUserIsAuthenticated = (isAuthenticated: boolean) => {
	return typedAction('auth/SET_IS_AUTHENTICATED', isAuthenticated);
};

export const setAuthUserIsVerified = (isVerified: boolean) => {
	return typedAction('auth/SET_IS_VERIFIED', isVerified);
};

export const setAuthLoading = (loading: boolean) => {
	return typedAction('auth/SET_LOADING', loading);
};

export const logout = () => {
	return typedAction('user/LOGOUT');
};

export type IAuthAction = ReturnType<
	| typeof setAuthUser
	| typeof setAuthLoading
	| typeof setAuthUserIsAuthenticated
	| typeof setAuthUserIsVerified
	| typeof logout
>;
