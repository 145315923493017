import { LoadingOutlined } from "@ant-design/icons";

export const ApplicationButton: React.FC<{
  isLoading: boolean;
  disabled?: boolean;
}> = ({ isLoading, disabled, children }) => (
  <button
    type='submit'
    className={`button applicationBtn ${
      disabled || isLoading ? "button_disabled" : ""
    }`}
    disabled={disabled || isLoading}
  >
    {isLoading ? <LoadingOutlined style={{ fontSize: 14 }} spin /> : children}
  </button>
);
