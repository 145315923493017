import React from 'react'

import { Modal } from 'components/common'
import { IModalProps } from 'components/common/Modal/interfaces'


const ChangeAvatarModal: React.FC<IModalProps> = (props) => {
	return (
		<Modal {...props}>
			<div className="change" id="change">
				<span className="change__title title_27">Изменение аватара</span>

				<div className="change__blocks change__blocks_mt">
					<div className="change__block">
						<div className="change__images">
							<img src="img/user.png" alt="" className="removeImg change__img" />
							<img src="" alt="" className="addImage" />
						</div>
						<div className="change__inputs">
							<span className="link_20">Загрузить новый аватар</span>
							<input type="file" className="change__input" placeholder="Загрузить новый аватар" />
						</div>
					</div>
					<div className="change__block">
						<div className="change__pravImages">
							<div className="change__assessments">
								<img src="img/people.png" alt="" />
								<div className="change__assessment">
									<img src="img/false.png" alt="" />
								</div>
							</div>
							<div className="change__assessments">
								<img src="img/people2.png" alt="" />
								<div className="change__assessment">
									<img src="img/true.png" alt="" />
								</div>
							</div>
						</div>
						<p className="null change__texts text_18_30">
							Измениь аватпр учетн апую записьыдан о ь учетную заь учетную заь учет ную заь учетную заь
						</p>
					</div>
				</div>

				<div className="twoBtns">
					<button
						onClick={props.onCancel}
						className="button twoBtns__btn button_transparent"
					>Отмена</button>
					<button className="button twoBtns__btn noactive button_blue">Сохранить</button>
				</div>
			</div>
		</Modal>
	)
}

export default ChangeAvatarModal
