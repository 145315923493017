import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cleanDeep from "clean-deep";
import { IRootState } from "store";
import { setPropertyData } from "store/modules/Application/property.module";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import { APP_PATHS } from "data/paths.data";
import {
  immovablePropertyTypes,
  movablePropertyTypes,
  valuablePropertyTypes,
} from "data/property.data";
import {
  INITIAL_IMMOVABLE_PROPERTY_ITEM,
  INITIAL_MOVABLE_PROPERTY_ITEM,
  INITIAL_BANK_ACCOUNT,
  INITIAL_STOCK,
  INITIAL_OTHER_SECURITIES,
  INITIAL_VALUABLE_PROPERTY_ITEM,
  IValuableProperty,
  IBankAccount,
  IMovablePropertyItem,
  IImmovablePropertyItem,
} from "interfaces/property.interfaces";
import {
  getAvtokodQuery,
  getDadataQuery,
  getEgrnQuery,
  getGibddQuery,
  insertDocumentService,
} from "services/docs/docs.service";
import { BankInput } from "components/common/BankInput";
import { AddressSuggestions } from "react-dadata";
import { REACT_DADATA_TOKEN } from "../../config/env";
import { Input } from "antd";
import { ApplicationButton } from "components/common/ApplicationButton";
import { AddressInput } from "components/common/Addressnput";
import { QuestionTooltip } from "../../components/common/Tooltip";

const PropertyPage: React.FC = () => {
  const history = useHistory();

  const data = useSelector((state: IRootState) => state.property);
  const dispatch = useDispatch();

  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [immovableSearchValue, setImmovableSearchValue] = useState({});
  const [movableSearchValue, setMovableSearchValue] = useState({});
  const [isKadastrSearch, setIsKadastrSearch] = useState(
    Object.keys(data.immovableProperty).reduce(
      (res, key) => ({ ...res, [key]: false }),
      {}
    )
  );
  const [isVinSearch, setIsVinSearch] = useState(
    Object.keys(data.movableProperty).reduce(
      (res, key) => ({ ...res, [key]: false }),
      {}
    )
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const res = await insertDocumentService(
      "inventoryOfAcitizensProperty",
      cleanDeep(data)
    );

    setIsLoading(false);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.DEALS);
  };

  const dadataQuery = async (key: string) => {
    const lastIndex = data["immovableProperty"][key].length - 1;
    data["immovableProperty"][key][lastIndex]["location"] = "Загрузка...";
    data["immovableProperty"][key][lastIndex]["space"] = "Загрузка...";
    dispatch(setPropertyData({ ...data }));
    const res = await getDadataQuery(immovableSearchValue[key]);

    const location = res.data[0].result;
    const space = res.data[0].flat_area;
    data["immovableProperty"][key][lastIndex]["location"] = location
      ? location
      : "";
    data["immovableProperty"][key][lastIndex]["space"] = space ? space : "";

    dispatch(setPropertyData({ ...data }));
  };

  const apiegrnQuery = async (key: string) => {
    const lastIndex = data["immovableProperty"][key].length - 1;
    data["immovableProperty"][key][lastIndex] = {
      location: "Загрузка...",
      space: "Загрузка...",
      name: "Загрузка...",
    };
    dispatch(setPropertyData({ ...data }));
    const res = await getEgrnQuery(immovableSearchValue[key]);

    if (res.data.error.code) {
      setImmovableSearchValue((prevState) => ({
        ...prevState,
        [key]: `Error service: ${res.data.error.mess}`,
      }));
      return;
    }

    const location = res.data["EGRN"]["object"]["ADDRESS"];
    const space = res.data["EGRN"]["object"]["AREA"];
    const type = res.data["EGRN"]["object"]["TYPE"];

    data["immovableProperty"][key][lastIndex] = {
      location: location ? location : "",
      space: space ? space : "",
      name: type ?
          type + `, кадастровый номер ${immovableSearchValue[key]}` :
          `${immovablePropertyTypes[key].type}, кадастровый номер ${immovableSearchValue[key]}`,
    };

    dispatch(setPropertyData({ ...data }));
  };

  const gibddQuery = async (key: string) => {
    const lastIndex = data["movableProperty"][key].length - 1;
    data["movableProperty"][key][lastIndex]["name"] = "Загрузка...";
    data["movableProperty"][key][lastIndex]["id"] = "Загрузка...";
    dispatch(setPropertyData({ ...data }));

    const res = await getGibddQuery(movableSearchValue[key]);
    data["movableProperty"][key][lastIndex]["name"] = `Автомобиль ${
      res.data.RequestResult?.vehicle.model || ""
    }, ${res.data.RequestResult?.vehicle.year || ""} г.в., цвет ${
      res.data.RequestResult?.vehicle.color || ""
    }, ПТС ${res.data.RequestResult?.vehiclePassport.number || ""}`;
    data["movableProperty"][key][lastIndex]["id"] = movableSearchValue[key];
    dispatch(setPropertyData({ ...data }));
  };

  const avtokodQuery = async (key: string) => {
    const lastIndex = data["movableProperty"][key].length - 1;
    data["movableProperty"][key][lastIndex]["name"] = "Загрузка...";
    data["movableProperty"][key][lastIndex]["id"] = "Загрузка...";
    dispatch(setPropertyData({ ...data }));

    const res = await getAvtokodQuery(movableSearchValue[key]);
    data["movableProperty"][key][lastIndex]["name"] = `Автомобиль ${
      res.data?.data?.car_mark || ""
    } ${res.data?.data?.car_model || ""}, ${
      res.data?.data?.manufacturing_year || ""
    } г.в., ПТС ${res.data?.data?.number_plate || ""}`;
    data["movableProperty"][key][lastIndex]["id"] =
      res.data?.data?.vin_number || "Отсутствует";
    dispatch(setPropertyData({ ...data }));
  };

  useEffect(() => {
    const store = localStorage;
    if (store.getItem("propdata")) {
      dispatch(setPropertyData(JSON.parse(store.getItem("propdata"))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const store = localStorage;
    store.setItem("propdata", JSON.stringify(data));
  }, [data]);

  const handleChange = (e: any, { section, key, index }: any) => {
    e.preventDefault();
    data[section][key][index][e.target.name] = e.target.value;
    dispatch(setPropertyData({ ...data }));
  };

  const handleChangeSimple = (e: any, { section, index }: any) => {
    e.preventDefault();
    data[section][index][e.target.name] = e.target.value;
    dispatch(setPropertyData({ ...data }));
  };

  const handleTargetChange = ({
    value,
    section,
    key,
    i,
    name,
  }: {
    value: string;
    section: string;
    key?: string;
    i: number;
    name: string;
  }) => {
    key
      ? (data[section][key][i][name] = value)
      : (data[section][i][name] = value);
    dispatch(setPropertyData({ ...data }));
  };

  const addItem = (e: any, { section, key }: any) => {
    e.preventDefault();

    const newItem = () => {
      switch (section) {
        case "immovableProperty":
          return { ...INITIAL_IMMOVABLE_PROPERTY_ITEM(key) };
        case "movableProperty":
          return { ...INITIAL_MOVABLE_PROPERTY_ITEM };
        case "valuableProperty":
          return { ...INITIAL_VALUABLE_PROPERTY_ITEM };
        default:
          return null;
      }
    };

    data[section][key].push(newItem());
    dispatch(setPropertyData({ ...data }));
  };

  const removeItem = (e: any, { section, key }: any) => {
    e.preventDefault();

    if (data[section][key].length > 1) {
      data[section][key].pop();
      dispatch(setPropertyData({ ...data }));
    }
  };

  const removeSimpleItem = (e: any, { section }: any) => {
    e.preventDefault();

    if (data[section].length > 1) {
      data[section].pop();
      dispatch(setPropertyData({...data}));
    }
  };

  const addSimpleItem = (e: any, { section }: any) => {
    e.preventDefault();

    const newItem = () => {
      switch (section) {
        case "bankAccounts":
          return { ...INITIAL_BANK_ACCOUNT };
        case "stocks":
          return { ...INITIAL_STOCK };
        case "otherSecurities":
          return { ...INITIAL_OTHER_SECURITIES };
        default:
          return null;
      }
    };

    data[section].push(newItem());
    dispatch(setPropertyData({ ...data }));
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <div className='application__form application__form_p'>
          <ul className='decorContent__list'>
            <li className='decorContent__item'>
              <div className='decorContent__lines'>
                <div className='decorContent__linesCircle2'>1</div>
              </div>
              <div className='decorContent__texts'>
                <span className='text_18 block-title'>Введите данные</span>
              </div>
            </li>
          </ul>

          <br />

          <div className='formLineOver'>
            <div className='formLine'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                I. Недвижимое имущество
              </span>
              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Вид и наименование имущества
                    <QuestionTooltip text='Пример: Земельный участок' />
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Вид собственности
                  </li>
                  <li className='formLine__item formLine__item_230 formLine__item_title text_12_14'>
                    Местонахождение (адрес)
                    <QuestionTooltip text='Пример: г. Санкт-Петербург, ул. Большая Морская, 29' />
                  </li>
                  <li className='formLine__item formLine__item_102 formLine__item_title text_12_14'>
                    Площадь (кв. м)
                    <QuestionTooltip text='Пример: 49.5 кв.м.' />
                  </li>
                  <li className='formLine__item formLine__item_102 formLine__item_title text_12_14'>
                    Основание приобретения и стоимость
                    <QuestionTooltip text='Пример договор купли-продажи от 01.01.2001' />
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Сведения о залоге и залогодержателе
                    <QuestionTooltip text='Пример: "нет" либо "Залог ПАО Сбербанк, по договору о залоге №1 от 01.01.20021"' />
                  </li>
                </ul>

                {Object.keys(data.immovableProperty).map((key, keyIndex) => {
                  const items: IImmovablePropertyItem[] =
                    data.immovableProperty[key];
                  return (
                    <div
                      className='key'
                      style={{
                        position: "relative",
                        marginTop: "15px",
                        borderBottom: "1px solid #000",
                      }}
                      key={key}
                    >
                      <span
                        className='text_12_14 formLine__titles'
                        style={{ paddingLeft: "65px" }}
                      >
                        {immovablePropertyTypes[key].title}:
                      </span>
                      {!isKadastrSearch[key] ? (
                        <div
                          className='mt_10'
                          style={{
                            display: "flex",
                            marginLeft: "65px",
                          }}
                        >
                          <AddressSuggestions
                            value={
                              immovableSearchValue[key] !== undefined
                                ? immovableSearchValue[key]
                                : ""
                            }
                            token={REACT_DADATA_TOKEN}
                            inputProps={{
                              placeholder: "Введите адрес",
                              type: "text",
                            }}
                            delay={500}
                            onChange={(e) => {
                              setImmovableSearchValue((prevState) => ({
                                ...prevState,
                                [key]: e.value,
                              }));
                            }}
                          />
                          <input
                            type='button'
                            className='button'
                            style={{
                              width: "70px",
                              backgroundColor: "#0075DB",
                            }}
                            onClick={(e) => dadataQuery(key)}
                            value='Поиск'
                          />
                        </div>
                      ) : (
                        <div
                          className='mt_10'
                          style={{
                            display: "flex",
                          }}
                        >
                          <Input.TextArea
                            className='formLine__input'
                            style={{
                              width: "25%",
                              marginLeft: "65px",
                            }}
                            placeholder='Введите кадастровый номер'
                            value={
                              immovableSearchValue[key] !== undefined
                                ? immovableSearchValue[key]
                                : ""
                            }
                            onChange={(e) => {
                              setImmovableSearchValue((prevState) => ({
                                ...prevState,
                                [key]: e.target.value,
                              }));
                            }}
                            autoSize
                          />
                          <input
                            type='button'
                            className='button'
                            style={{
                              width: "70px",
                              backgroundColor: "#0075DB",
                            }}
                            onClick={(e) => apiegrnQuery(key)}
                            value='Поиск'
                          />
                        </div>
                      )}
                      <div
                        className='mb_20 mt_5'
                        style={{
                          display: "flex",
                          marginLeft: "65px",
                        }}
                      >
                        <label className='text_12_14'>
                          <input
                            type='checkbox'
                            key={key}
                            checked={isKadastrSearch[key]}
                            onChange={(e) => {
                              setIsKadastrSearch((prevState) => ({
                                ...prevState,
                                [key]: !prevState[key],
                              }));
                              setImmovableSearchValue((prevState) => ({
                                ...prevState,
                                [key]: "",
                              }));
                            }}
                          />
                          <span className='ml_5'>
                            Объект не найден по адресу
                          </span>
                        </label>
                      </div>
                      {items.map((item, i) => {
                        return (
                          <ul
                            key={i}
                            className='null formLine__list formLine__list_start'
                            style={{ padding: "0 0 20px 0", border: "none" }}
                          >
                            <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                              {keyIndex + 1}.{i + 1}
                            </li>

                            <li className='formLine__item formLine__item_158'>
                              <Input.TextArea
                                name='name'
                                value={item.name}
                                onChange={(e) =>
                                  handleChange(e, {
                                    section: "immovableProperty",
                                    key,
                                    index: i,
                                  })
                                }
                                className='formLine__input'
                                placeholder={immovablePropertyTypes[key].type}
                                autoSize
                              />
                            </li>
                            <li className='formLine__item formLine__item_170'>
                              <select
                                name='ownType'
                                className='formLine__input'
                                value={item.ownType}
                                style={{ height: "29px" }}
                                onChange={(e) =>
                                  handleChange(e, {
                                    section: "immovableProperty",
                                    key,
                                    index: i,
                                  })
                                }
                              >
                                <option value='' />
                                <option value='Индивидуальная'>
                                  Индивидуальная
                                </option>
                                <option value='Долевая'>Долевая</option>
                                <option value='Совместная'>Совместная</option>
                              </select>
                            </li>
                            <li className='formLine__item formLine__item_230'>
                              <AddressInput
                                name='location'
                                value={item.location}
                                handleChange={(value) =>
                                  handleTargetChange({
                                    value,
                                    section: "immovableProperty",
                                    key,
                                    i,
                                    name: "location",
                                  })
                                }
                                className='formLine__input formLine__input_textarea'
                                placeholder=''
                                rows={1}
                                autoSize
                              />
                            </li>
                            <li className='formLine__item formLine__item_102'>
                              <Input.TextArea
                                name='space'
                                value={item.space}
                                onChange={(e) =>
                                  handleChange(e, {
                                    section: "immovableProperty",
                                    key,
                                    index: i,
                                  })
                                }
                                className='formLine__input'
                                autoSize
                              />
                            </li>
                            <li className='formLine__item formLine__item_102'>
                              <Input.TextArea
                                name='purchaseReason'
                                value={item.purchaseReason}
                                onChange={(e) =>
                                  handleChange(e, {
                                    section: "immovableProperty",
                                    key,
                                    index: i,
                                  })
                                }
                                className='formLine__input'
                                autoSize
                              />
                            </li>
                            <li className='formLine__item formLine__item_170'>
                              <Input.TextArea
                                name='ownerData'
                                value={item.ownerData}
                                onChange={(e) =>
                                  handleChange(e, {
                                    section: "immovableProperty",
                                    key,
                                    index: i,
                                  })
                                }
                                className='formLine__input'
                                autoSize
                              />
                            </li>
                          </ul>
                        );
                      })}
                      <button
                        className='formLine__add'
                        onClick={(e) =>
                          addItem(e, { section: "immovableProperty", key })
                        }
                      >
                        <img
                          src='img/add.svg'
                          alt='plus'
                          className='svg formLine__addIcon'
                        />
                      </button>
                      {data["immovableProperty"][key].length > 1 && <button
                          className='formLine__remove'
                          onClick={(e) =>
                              removeItem(e, { section: "immovableProperty", key })
                          }
                      >
                        <img
                            src='img/minus.svg'
                            alt='minus'
                            className='svg formLine__addIcon'
                        />
                      </button>}

                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='formLineOver'>
            <div className='formLine mt_60'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                II. Движимое имущество
              </span>

              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Вид, марка, модель транспортного средства, год изготовления
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Идентификационный номер
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Вид собственности
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Место нахождения/ место хранения (адрес)
                  </li>
                  <li className='formLine__item formLine__item_102 formLine__item_title text_12_14'>
                    Стоимость. Руб.
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Сведения о залоге и залогодержателе
                  </li>
                </ul>

                {Object.keys(data.movableProperty).map((key, keyIndex) => {
                  const items: IMovablePropertyItem[] =
                    data.movableProperty[key];
                  return (
                    <div
                      className='key'
                      style={{
                        position: "relative",
                        marginTop: "15px",
                        borderBottom: "1px solid #000",
                      }}
                      key={key}
                    >
                      <span
                        className='text_12_14 formLine__titles'
                        style={{ paddingLeft: "65px" }}
                      >
                        {movablePropertyTypes[key].title}:
                      </span>
                      {(key === "carPassenger" ||
                        key === "truck" ||
                        key === "motorVehicle") && (
                        <>
                          <div className='mt_10' style={{ display: "flex" }}>
                            <Input.TextArea
                              className='formLine__input'
                              style={{
                                width: "25%",
                                marginLeft: "65px",
                              }}
                              placeholder={
                                isVinSearch[key]
                                  ? "Введите vin номер"
                                  : "Введите гос номер"
                              }
                              value={
                                movableSearchValue[key] !== undefined
                                  ? movableSearchValue[key]
                                  : ""
                              }
                              onChange={(e) => {
                                setMovableSearchValue((prevState) => ({
                                  ...prevState,
                                  [key]: e.target.value,
                                }));
                              }}
                              autoSize
                            />
                            <input
                              type='button'
                              className='button'
                              style={{
                                width: "70px",
                                backgroundColor: "#0075DB",
                              }}
                              onClick={(e) =>
                                isVinSearch[key]
                                  ? gibddQuery(key)
                                  : avtokodQuery(key)
                              }
                              value='Поиск'
                            />
                          </div>
                          <div
                            className='mb_20 mt_5'
                            style={{
                              display: "flex",
                              marginLeft: "65px",
                            }}
                          >
                            <label className='text_12_14'>
                              <input
                                type='checkbox'
                                key={key}
                                checked={isVinSearch[key]}
                                onChange={(e) => {
                                  setIsVinSearch((prevState) => ({
                                    ...prevState,
                                    [key]: !prevState[key],
                                  }));
                                  setMovableSearchValue((prevState) => ({
                                    ...prevState,
                                    [key]: "",
                                  }));
                                }}
                              />
                              <span className='ml_5'>
                                Объект не найден по гос номеру
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                      {items.map((item, i) => (
                        <ul
                          key={i}
                          className='null formLine__list formLine__list_start'
                          style={{ padding: "0 0 20px 0", border: "none" }}
                          id='test1'
                        >
                          <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                            {keyIndex + 1}.{i + 1}
                          </li>

                          <li className='formLine__item formLine__item_158'>
                            <Input.TextArea
                              name='name'
                              value={item.name}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "movableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input formLine__input_textarea'
                              placeholder=''
                              rows={1}
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_170'>
                            <Input.TextArea
                              name='id'
                              value={item.id}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "movableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input'
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_170'>
                            <select
                              name='propertyType'
                              className='formLine__input'
                              style={{ height: "29px" }}
                              value={item.propertyType}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "movableProperty",
                                  key,
                                  index: i,
                                })
                              }
                            >
                              <option value='' />
                              <option value='Индивидуальная'>
                                Индивидуальная
                              </option>
                              <option value='Долевая'>Долевая</option>
                              <option value='Совместная'>Совместная</option>
                            </select>
                          </li>
                          <li className='formLine__item formLine__item_170'>
                            <AddressInput
                              name='location'
                              value={item.location}
                              handleChange={(value) =>
                                handleTargetChange({
                                  value,
                                  section: "movableProperty",
                                  key,
                                  i,
                                  name: "location",
                                })
                              }
                              className='formLine__input formLine__input_textarea'
                              placeholder=''
                              rows={1}
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_102'>
                            <Input.TextArea
                              name='cost'
                              value={item.cost}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "movableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input'
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_170'>
                            <Input.TextArea
                              name='ownerData'
                              value={item.ownerData}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "movableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input'
                              autoSize
                            />
                          </li>
                        </ul>
                      ))}
                      <button
                        className='formLine__add'
                        onClick={(e) =>
                          addItem(e, { section: "movableProperty", key })
                        }
                      >
                        <img
                          src='img/add.svg'
                          alt='plus'
                          className='svg formLine__addIcon'
                        />
                      </button>
                      {data["movableProperty"][key].length > 1 && <button
                          className='formLine__remove'
                          onClick={(e) =>
                              removeItem(e, { section: "movableProperty", key })
                          }
                      >
                        <img
                            src='img/minus.svg'
                            alt='minus'
                            className='svg formLine__addIcon'
                        />
                      </button>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='formLineOver'>
            <div className='formLine mt_60'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                III. Сведения о счетах в банках и иных кредитных организациях
              </span>
              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_440 formLine__item_title text_12_14'>
                    Наименование и адрес банка или иной кредитной организации
                    <QuestionTooltip text='Пример: ПАО «Сбербанк», г. Москва, ул. Вавилова, 19' />
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Вид и валюта счёта
                    <QuestionTooltip text='Пример: Текущий, рубли' />
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Дата открытия счёта
                    <QuestionTooltip text='Пример: 11.11.2011' />
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Остаток на счёте (руб.)
                    <QuestionTooltip text='Пример: 728,86' />
                  </li>
                </ul>

                {data.bankAccounts.map((item: IBankAccount, i: number) => (
                  <ul key={i} className='null formLine__list'>
                    <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                      1.{i + 1}
                    </li>
                    <li className='formLine__item formLine__item_440'>
                      <BankInput
                        value={item.bank}
                        name='bank'
                        className='formLine__input mt_5'
                        fullLabel
                        handleChange={(value) =>
                          handleTargetChange({
                            value,
                            section: "bankAccounts",
                            i,
                            name: "bank",
                          })
                        }
                        handleSelect={({ label }) =>
                          handleTargetChange({
                            value: label,
                            section: "bankAccounts",
                            i,
                            name: "bank",
                          })
                        }
                      />
                    </li>
                    <li className='formLine__item formLine__item_170'>
                      <Input.TextArea
                        name='currency'
                        value={item.currency}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "bankAccounts",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_170'>
                      <input
                        type='date'
                        name='openDate'
                        value={item.openDate}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "bankAccounts",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        id='openDate'
                      />
                    </li>
                    <li className='formLine__item formLine__item_158'>
                      <Input.TextArea
                        name='balance'
                        value={item.balance ?? 0}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "bankAccounts",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                  </ul>
                ))}
              </div>
              <button
                className='formLine__add'
                onClick={(e) => addSimpleItem(e, { section: "bankAccounts" })}
              >
                <img
                  src='img/add.svg'
                  alt='plus'
                  className='svg formLine__addIcon'
                />
              </button>
              {data["bankAccounts"].length > 1 && <button
                  className='formLine__remove'
                  onClick={(e) =>
                      removeSimpleItem(e, { section: "bankAccounts" })
                  }
              >
                <img
                    src='img/minus.svg'
                    alt='minus'
                    className='svg formLine__addIcon'
                />
              </button>}
            </div>
          </div>
          <div className='formLineOver'>
            <div className='formLine mt_60'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                IV. Акции и иное участие в коммерческих организациях
              </span>
              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_258 formLine__item_title text_12_14'>
                    Наименование и организационно-правовая форма организации
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Местонахождение организации (адрес)
                  </li>
                  <li className='formLine__item formLine__item_200 formLine__item_title text_12_14'>
                    Уставный, складочный капитал, паевый фонд (руб.)
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Доля участия
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Основание участия
                  </li>
                </ul>

                {data.stocks.map((item, i) => (
                  <ul key={i} className='null formLine__list'>
                    <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                      1.1
                    </li>
                    <li className='formLine__item formLine__item_258'>
                      <Input.TextArea
                        name='organization'
                        value={item.organization}
                        onChange={(e) =>
                          handleChangeSimple(e, { section: "stocks", index: i })
                        }
                        className='formLine__input mt_5'
                        placeholder='1) нет'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_170'>
                      <AddressInput
                        name='location'
                        value={item.location}
                        handleChange={(value) =>
                          handleTargetChange({
                            value,
                            section: "stocks",
                            i,
                            name: "location",
                          })
                        }
                        className='formLine__input formLine__input_textarea'
                        placeholder=''
                        rows={1}
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_200'>
                      <Input.TextArea
                        name='fund'
                        value={item.fund}
                        onChange={(e) =>
                          handleChangeSimple(e, { section: "stocks", index: i })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_158'>
                      <Input.TextArea
                        name='percent'
                        value={item.percent}
                        onChange={(e) =>
                          handleChangeSimple(e, { section: "stocks", index: i })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_158'>
                      <Input.TextArea
                        name='reasons'
                        value={item.reasons}
                        onChange={(e) =>
                          handleChangeSimple(e, { section: "stocks", index: i })
                        }
                        className='formLine__input'
                        placeholder=''
                        autoSize
                      />
                    </li>
                  </ul>
                ))}
              </div>
              <button
                className='formLine__add'
                onClick={(e) => addSimpleItem(e, { section: "stocks" })}
              >
                <img
                  src='img/add.svg'
                  alt='plus'
                  className='svg formLine__addIcon'
                />
              </button>
              {data["stocks"].length > 1 && <button
                  className='formLine__remove'
                  onClick={(e) =>
                      removeSimpleItem(e, { section: "stocks" })
                  }
              >
                <img
                    src='img/minus.svg'
                    alt='minus'
                    className='svg formLine__addIcon'
                />
              </button>}
            </div>
          </div>
          <div className='formLineOver'>
            <div className='formLine mt_60'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                V. Иные ценные бумаги
              </span>
              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_258 formLine__item_title text_12_14'>
                    Вид ценной бумаги
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Лицо, выпустившее ценную бумагу
                  </li>
                  <li className='formLine__item formLine__item_200 formLine__item_title text_12_14'>
                    Номинальная величина обязательства (руб.)
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Общее количество
                  </li>
                  <li className='formLine__item formLine__item_158 formLine__item_title text_12_14'>
                    Общая стоимость (руб.)
                  </li>
                </ul>

                {data.otherSecurities.map((item, i) => (
                  <ul key={i} className='null formLine__list'>
                    <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                      1.1
                    </li>
                    <li className='formLine__item formLine__item_258'>
                      <Input.TextArea
                        name='name'
                        value={item.name}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "otherSecurities",
                            index: i,
                          })
                        }
                        className='formLine__input mt_5'
                        placeholder='1) нет'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_170'>
                      <Input.TextArea
                        name='author'
                        value={item.author}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "otherSecurities",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_200'>
                      <Input.TextArea
                        name='startAmount'
                        value={item.startAmount}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "otherSecurities",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_158'>
                      <Input.TextArea
                        name='totalAmount'
                        value={item.totalAmount}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "otherSecurities",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                    <li className='formLine__item formLine__item_158'>
                      <Input.TextArea
                        name='totalCost'
                        value={item.totalCost}
                        onChange={(e) =>
                          handleChangeSimple(e, {
                            section: "otherSecurities",
                            index: i,
                          })
                        }
                        className='formLine__input'
                        autoSize
                      />
                    </li>
                  </ul>
                ))}
              </div>
              <button
                className='formLine__add'
                onClick={(e) =>
                  addSimpleItem(e, { section: "otherSecurities" })
                }
              >
                <img
                  src='img/add.svg'
                  alt='plus'
                  className='svg formLine__addIcon'
                />
              </button>
              {data["otherSecurities"].length > 1 && <button
                  className='formLine__remove'
                  onClick={(e) =>
                      removeSimpleItem(e, { section: "otherSecurities" })
                  }
              >
                <img
                    src='img/minus.svg'
                    alt='minus'
                    className='svg formLine__addIcon'
                />
              </button>}
            </div>
          </div>
          <div className='formLineOver'>
            <div className='formLine mt_60'>
              <span className='applicationForms__inputs_description applicationForms__inputs_description_ml0 text_18_21'>
                VI. Сведения о наличных денежных средствах и ином ценном
                имуществе
              </span>
              <div className='formLine__table mt_12'>
                <ul className='null formLine__list formLine__list_title'>
                  <li className='formLine__item formLine__item_60 formLine__item_title text_12_14'>
                    № п/п
                  </li>
                  <li className='formLine__item formLine__item_258 formLine__item_title text_12_14'>
                    Вид и наименование имущества
                  </li>
                  <li className='formLine__item formLine__item_170 formLine__item_title text_12_14'>
                    Стоимость (сумма и валюта)
                  </li>
                  <li className='formLine__item formLine__item_276 formLine__item_title text_12_14'>
                    Место нахождения/ место хранения (адрес)
                  </li>
                  <li className='formLine__item formLine__item_230 formLine__item_title text_12_14'>
                    Сведения о залоге и залогодержателе
                  </li>
                </ul>

                {Object.keys(data.valuableProperty).map((key, keyIndex) => {
                  const items: IValuableProperty[] = data.valuableProperty[key];
                  return (
                    <div
                      className='key'
                      style={{
                        position: "relative",
                        marginTop: "20px",
                        borderBottom: "1px solid #000",
                      }}
                      key={key}
                    >
                      <span
                        className='text_12_14 formLine__titles'
                        style={{ paddingLeft: "65px" }}
                      >
                        {valuablePropertyTypes[key].title}:
                      </span>
                      {items.map((item, i) => (
                        <ul
                          key={i}
                          className='null formLine__list'
                          style={{ padding: "0 0 20px 0", border: "none" }}
                        >
                          <li className='formLine__item formLine__item_number formLine__item_60 text_12_14'>
                            {keyIndex + 1}.{i + 1}
                          </li>

                          <li className='formLine__item formLine__item_258'>
                            <Input.TextArea
                              name='name'
                              value={item.name}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "valuableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input mt_5'
                              placeholder='1) нет'
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_170'>
                            <Input.TextArea
                              name='cost'
                              value={item.cost}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "valuableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input'
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_276'>
                            <AddressInput
                              name='location'
                              value={item.location}
                              handleChange={(value) =>
                                handleTargetChange({
                                  value,
                                  section: "valuableProperty",
                                  key,
                                  i,
                                  name: "location",
                                })
                              }
                              className='formLine__input formLine__input_textarea'
                              placeholder=''
                              rows={1}
                              autoSize
                            />
                          </li>
                          <li className='formLine__item formLine__item_230'>
                            <Input.TextArea
                              name='ownerData'
                              value={item.ownerData}
                              onChange={(e) =>
                                handleChange(e, {
                                  section: "valuableProperty",
                                  key,
                                  index: i,
                                })
                              }
                              className='formLine__input'
                              autoSize
                            />
                          </li>
                        </ul>
                      ))}
                      <button
                        className='formLine__add'
                        onClick={(e) =>
                          addItem(e, { section: "valuableProperty", key })
                        }
                      >
                        <img
                          src='img/add.svg'
                          alt='plus'
                          className='svg formLine__addIcon'
                        />
                      </button>
                      {data["valuableProperty"][key].length > 1 && <button
                          className='formLine__remove'
                          onClick={(e) =>
                              removeItem(e, { section: "valuableProperty", key })
                          }
                      >
                        <img
                            src='img/minus.svg'
                            alt='minus'
                            className='svg formLine__addIcon'
                        />
                      </button>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='applicationForms__inputs_line mt_20'>
            <div className='checkbox checkbox_ml'>
              <input
                type='checkbox'
                className='check'
                id='check'
                onClick={() => setIsAgreed(!isAgreed)}
              />
              <label className='checkbox__text text_14' htmlFor='check'>
                <span className='checkbox__textInner'>
                  Достоверность и полноту настоящих сведений подтверждаю
                </span>
              </label>
            </div>
          </div>
          {errorMessage && (
            <span
              style={{
                display: "block",
                color: "#FF4040",
                marginTop: "40px",
              }}
            >
              {errorMessage}
            </span>
          )}
        </div>

        <ApplicationButton disabled={!isAgreed} isLoading={isLoading}>
          Заполнить данные. Шаг 3
        </ApplicationButton>
      </form>
    </ApplicationWrapper>
  );
};

export default PropertyPage;
