import { typedAction } from '../../helpers';
import { getInitialCreditors } from 'data/creditors.data';

const INITIAL_CREDITORS = getInitialCreditors();

type ICreditorsState = typeof INITIAL_CREDITORS;

export const setCreditors = (data: any) => {
  localStorage.setItem('creditorsData', JSON.stringify(data));
  return typedAction(
    'creditors/SET_DATA',
    JSON.parse(localStorage.getItem('creditorsData'))
  );
};

type ICreditorsAction = ReturnType<typeof setCreditors>;

export const creditorsReducer = (
  state = getInitialCreditors(),
  action: ICreditorsAction
): ICreditorsState => {
  switch (action.type) {
    case 'creditors/SET_DATA':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
