import React, { Fragment } from 'react'


const Error404Page: React.FC = () => {
	return (
		<Fragment>
			<div className="page error-404__page">
				<div className="container content">
					<h1>Ошибка 404</h1>
					<h2>Страница не найдена</h2>
				</div>
			</div>
		</Fragment>
	)
}

export default Error404Page
