import {PROJECT_NAME_HTML_RUS} from 'data/common.data';
import { ICodeDto } from 'interfaces/auth.interfaces';
import React, {useEffect, useState} from 'react';
import {
  phoneVerificationService,
  sendVerificationSMSService,
} from 'services/auth/register.service';
import {useHistory} from "react-router-dom";
import {APP_PATHS} from "../../data/paths.data";

const PhoneVerification: React.FC = () => {
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [form, setForm] = useState<ICodeDto>({
    code: '',
  });

  const handleChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const resendSms = async () => {
    if (timer > 0) return;
    await sendVerificationSMSService();
    setTimer(60);
  }

  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => {
        setTimer((prev => prev - 1));
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [timer])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const success = await phoneVerificationService(form);

    if (!success) {
      return setSuccess(false);
    }

    history.push(APP_PATHS.HOME);
  };

  return (
    <>
      <div className='wrapper__little entrance'>
        <h1 className='title'><img src="img/logo.png" style={{height: "250px", width: "300px"}} alt="" /></h1>
        <p className='text null entrance__about'>
          Единая система формирования заявлений о банкротстве физических лиц
        </p>
        <form className='form' onSubmit={handleSubmit}>
          <h2 className='form__title title_28 null'>Регистрация</h2>
          <span className='text_grey'>Проверка номера телефона</span>
          <input
            type='text'
            name='code'
            onChange={handleChange}
            placeholder='Код с СМС'
            className='input input_mt'
            inputMode='numeric'
            pattern='[0-9]*'
            autoComplete='one-time-code'
            required
          />

          {success === false && <>Код не верен</>}

          <button type='submit' className='entrance_btn button trz'>
            Продолжить
          </button>
        </form>
        <button
            className='entrance_btn2 button_aqua'
            onClick={resendSms}
            disabled={!!timer}
        >
          Отправить ещё раз?
        </button>
        {!!timer &&
          <span className='entrance__texts text_14'>
            Повторная отправка возможна через {timer} секунд.
          </span>
        }
      </div>
    </>
  );
};

export default PhoneVerification;
