/* eslint-disable jsx-a11y/anchor-is-valid */

import './style.scss';

import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { BreadCrumbs } from 'components/common';
import { APP_PATHS } from 'data/paths.data';
import { PAGE_TITLES } from 'data/strings.data';

const ServicesPage: React.FC = () => {
	const history = useHistory();

	const navigateBack = (e: any) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<Fragment>
			<BreadCrumbs
				links={[{ title: PAGE_TITLES.SERVICES, path: APP_PATHS.SERVICES }]}
			/>

			<div className='decor wrapper'>
				<div className='decorContent'>
					<div className='decorContent__title null'>
						<img src='img/portfolio.svg' alt='' className='svg' />
						<h2 className='decorContent__titleText title_28 null'>
							Оформление заявления о банкротстве физического лица
						</h2>
					</div>
					<div className='decorContentFunc'>
						<a href='#' onClick={navigateBack} className='back trz'>
							<img src='img/back.svg' alt='' className='back__icon svg' />
							<span className='back__text text_greys green'>Вернуться</span>
						</a>
						<Link to={APP_PATHS.FAQ} className='next next_ml'>
							<span className='next__text text_greys green'>
								Часто задаваемые вопросы
							</span>
							<img src='img/back.svg' alt='' className='next__icon svg' />
						</Link>
					</div>
					<div className='decorContentTexts'>
						{/* <div className='decorContentText'>
							<span className='text_18'>Сроки оказания услуги:</span>
							<span className='decorContentText__about text_14'>
								От одного до трех месяцев
							</span>
							<img
								src='img/question.svg'
								alt=''
								className='svg decorContentText__icon'
							/>
						</div> */}
						<div className='decorContentText'>
							<span className='text_18'>Стоимость услуги:  4 990 руб</span>
							{/* <span className='decorContentText__price'>5000 руб</span> */}
							{/*<span className='decorContentText__about text_14'>*/}
							{/*	- госпошлина за выдачу паспорта*/}
							{/*</span>*/}
							{/*<img*/}
							{/*	src='img/question.svg'*/}
							{/*	alt=''*/}
							{/*	className='svg decorContentText__icon'*/}
							{/*/>*/}
						</div>
					</div>

					<ul className='decorContent__list'>
						<li className='decorContent__item'>
							<div className='decorContent__lines'>
								<div
									className='decorContent__linesCircle'
									style={{ marginBottom: 5 }}
								/>
								<img src='img/downLine.svg' alt='' className='svg' />
							</div>
							<div className='decorContent__texts'>
								<span className='text_18' style={{ fontSize: 30 }}>
									Как получить услугу?
								</span>
							</div>
						</li>
						<li className='decorContent__item'>
							<div className='decorContent__lines'>
								<div className='decorContent__linesCircle2'>1</div>
								<div className='decorContent__line'></div>
							</div>
							<div className='decorContent__texts'>
								<span className='text_18'>
									Заполните необходимые поля формирования заявления
								</span>
								<div className='decorContent__checkboxs'>
									<Link
										to={APP_PATHS.CLIENT_DATA}
										className='checkbox decorContent__checkbox'
									>
										<input
											type='radio'
											name='radio'
											className='check'
											id='radio1'
										/>
										<label
											className='checkbox__text checkbox__text_2 text_14'
											htmlFor='radio1'
										>
											Заполнить заявление
										</label>
									</Link>
								</div>
							</div>
						</li>
						{/* <li className='decorContent__item'>
							<div className='decorContent__lines'>
								<div className='decorContent__linesCircle2'>2</div>
								<div className='decorContent__line'></div>
							</div>
							<div className='decorContent__texts'>
								<span className='text_18'>
									Загрузите фото необходимых документов
								</span>
								<div
									className='decorContentAbout decorContentAbout_mt'
									style={{ marginTop: 30 }}
								>
									<img
										src='img/alert.svg'
										alt=''
										className='svg decorContentAbout__icon'
										style={{ marginRight: 15 }}
									/>
									<div className='decorContentAbout__content'>
										<span className='text_18 decorContentAbout__title'>
											Программа автоматически сгенерирует заявление,
											<br />а так же все дополнительные необходимые документы
											для процесса банкротства
										</span>
									</div>
								</div>
							</div>
						</li> */}
						<br />
						<li className='decorContent__item'>
							<div className='decorContent__lines'>
								<div className='decorContent__linesCircle2'>2</div>
								<div className='decorContent__line'></div>
							</div>
							<div className='decorContent__texts'>
								<span className='text_18'>
									Для того чтобы скачать пакет документов, сгенерированный
									программой, оплатите услугу
								</span>
								<div className='decorContent__checkboxs'>
									<Link
										to={APP_PATHS.PAYMENT}
										className='checkbox decorContent__checkbox'
									>
										<input
											type='radio'
											name='radio'
											className='check'
											id='radio1'
										/>
										<label
											className='checkbox__text checkbox__text_2 text_14'
											htmlFor='radio1'
										>
											Оплата
										</label>
									</Link>
								</div>
							</div>
						</li>
						<li className='decorContent__item'>
							<div className='decorContent__lines'>
								<div className='decorContent__linesCircle2'>3</div>
							</div>
							<div className='decorContent__texts'>
								<span className='text_18'>
									Скачайте документы для подачи заявления в суд
								</span>
								<div className='decorContent__checkboxs'>
									<Link
										to={APP_PATHS.RESULT}
										className='checkbox decorContent__checkbox'
									>
										<input
											type='radio'
											name='radio'
											className='check'
											id='radio1'
										/>
										<label
											className='checkbox__text checkbox__text_2 text_14'
											htmlFor='radio1'
										>
											Скачать документы
										</label>
									</Link>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</Fragment>
	);
};

export default ServicesPage;
