export const DOCS_LIST = [
  'Анкета должника (сформирована на сервисе БанкротДок)',
  'Опись имущества гражданина (сформирована на сервисе БанкротДок)',
  'Список сделок должника за последние три года (сформирован на сервисе БанкротДок)',
  'Список кредиторов и должников гражданина (сформирован на сервисе БанкротДок)',
  'Копия паспорта, копия свидетельства о постановке на учет в налоговом органе (ИНН), СНИЛС;',
  'Копия свидетельства о заключении брака;',
  'Копия свидетельства о расторжении брака, если оно выдано в течение трех лет до даты подачи заявления;',
  'Копия брачного договора (при наличии);',
  'Копия соглашения или судебного акта о разделе общего имущества супругов, соответственно заключенного и принятого в течение трех лет до даты подачи заявления (при наличии);',
  'Копия свидетельства о рождении ребенка, если гражданин является его родителем, усыновителем или опекуном;',
  'Справка из налоговой об отсутствии регистрации в качестве ИП. Документ должен быть получен не ранее чем за пять рабочих дней до даты подачи в суд гражданином заявления о признании его банкротом (получить можно на сайте налоговой службы https://egrul.nalog.ru/)',
  'Копии документов, подтверждающих наличие долгов (это могут быть решения судов, кредитные договоры, справки из банков и МФО о наличии задолженности, выписка из БКИ о кредитной истории и т.д.)',
  'Копии документов, подтверждающих право собственности гражданина на имущество (выписка из ЕГРН, ПТС на автомобиль и т.д.).',
  'Копии документов о совершавшихся гражданином в течение трех лет до даты подачи заявления сделках с недвижимым имуществом, ценными бумагами, долями в уставном капитале, транспортными средствами и сделках на сумму свыше трехсот тысяч рублей (договоры купли-продажи, дарения, мены и т.д.);',
  'Выписка из реестра акционеров (участников) юридического лица, акционером (участником) которого является гражданин (при наличииакций или долей в уставном капитале организаций);',
  'Выданная банком справка о наличии счетов, вкладов в банке и об остатках денежных средств на счетах, во вкладах;',
  'Выписки банка по операциям на счетах, по вкладам за последние три года;',
  'Справки об остатках электронных денежных средств и о переводах электронных денежных средств (при наличии);',
  'Сведения о полученных физическим лицом доходах и об удержанных суммах налога за последние три года (справки 2-ндфл или декларации 3-ндфл);',
  'Сведения о состоянии индивидуального лицевого счета застрахованного лица (можно получить на сайте Госуслуг или в Пенсионном фонде вашего региона);',
  'Копия решения о признании гражданина безработным, выданная государственной службой занятости населения (для безработных);',
];

export const FINISH_NEXT_STEPS = [
  'Оплатить госпошлину 300 руб. Реквизиты можно посмотреть на сайте Арбитражного суда вашего региона',
  'Внести на депозитный счёт Арбитражного суда 25000 руб. (назначение платежа - Вознаграждение финансового управляющего. Должник ФИО). Реквизиты можно посмотреть на сайте Арбитражного суда вашего региона',
  'В случае отсутствия у вас имущества, подлежащего продаже в ходе процедуры банкротства, внести на депозитный счёт Арбитражного суда 15000 руб. (назначение платежа - Финансирование расходов по процедуре банкротства. Должник ФИО). Реквизиты можно посмотреть на сайте Арбитражного суда вашего региона',
  'Распечатать сформированное заявление о банкротстве (без приложений) в количестве равном количеству кредиторов (см. таблицу Список кредиторов) плюс 3 экземпляра.',
  'Разослать заявление о банкротстве (без приложений)  заказными письмами всем кредиторам и в Управление Федеральной налоговой службы вашего региона',
  'Составить опись приложений к заявлению согласно списку необходимых приложений. Дополнительно в опись включаются чеки об оплате госпошлины и о внесении средств на депозит суда, а также почтовые квитанции о рассылке заявления кредиторам и в налоговую. Документы, прилагаемые в копиях необходимо заверить (копия верна, дата, подпись, расшифровка)',
  'Сдать заявление о банкротстве (2 экземплярах) и приложения с составленной описью в канцелярию Арбитражного суда вашего региона. Проверив приложения по описи  специалист поставит отметку о принятии на вашем экземпляре заявления и вернёт его вам.',
  'В течение 2-3 дней в <a target="_blank" href="https://kad.arbitr.ru/">картотеке дел</a> появится ваше дело. Через строки поиска найдите его и для удобства последующего отслеживания движения дела <a target="_blank" href="https://guard.arbitr.ru/">добавьте его в отслеживание</a>',
  'В случае наличия у судьи замечаний по вашим документам он вынесет определение в котором будет указано, что необходимо устранить/донести. Это определение никак не влияет на успех процедуры банкротства и носит оформительский характер',
  'В случае отсутствия замечаний либо после их устранения судья вынесет определение о принятии вашего заявления и назначит дату судебного заседания по его рассмотрению.',
  'После того как ваше заявление будет рассмотрено в судебном заседании судья введёт процедуру банкротства в отношении вас и назначит финансового управляющего',
  'Свяжитесь с финансовым управляющим для того чтобы передать ему необходимые документы и сведения. Далее продолжайте поддерживать контакт с финансовым управляющим.'
];