import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import {PROJECT_NAME_HTML_RUS} from 'data/common.data';
import { APP_PATHS } from 'data/paths.data';
import { emailVerificationService } from 'services/auth/register.service';

const EmailConfirmation: React.FC<any> = ({ match }) => {
  const [success, setSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      const res = await emailVerificationService({ code: match.params.code });
      console.log(res, match.params.code);
      setSuccess(res);
    })();
  }, [match.params.code]);

  if (success === null) {
    return <></>;
  }

  if (success === false) {
    return <Redirect to={APP_PATHS.HOME} />;
  }

  return (
    <div className='wrapper__little entrance'>
      <h1 className='title'><img src="img/logo.png" style={{height: "250px", width: "300px"}} alt="" /></h1>
      <p className='text null entrance__about'>
        Единая система формирования заявлений о банкротстве физических лиц
      </p>
      <h2 className='form__title title_28 null'>
        {success === true && 'Email был успешно подтверждён!'}
      </h2>

      <a
        href={APP_PATHS.HOME}
        className='entrance_btn button trz'
        style={{ width: '100%' }}
      >
        Домой
      </a>
    </div>
  );
};

export default EmailConfirmation;
