import { firstnames, middlenames, lastnames } from "data/names";

export const getMonthRus = (date: Date): string => {
  const monthList = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  const monthIndex = date.getMonth();
  return monthList[monthIndex];
};

export const getDateText = (date: Date | string): string => {
  if (typeof date === "string") {
    date = new Date(+date);
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const result = `${day.toString().length === 1 ? "0" : ""}${day}.${
    month.toString().length === 1 ? "0" : ""
  }${month}.${date.getFullYear()} ${
    hours.toString().length === 1 ? "0" : ""
  }${hours}:${minutes.toString().length === 1 ? "0" : ""}${minutes}`;
  return result;
};

const sample = <T>(items: T[]) =>
  items[Math.floor(Math.random() * items.length)];

type SexType = "male" | "female";

export const getRandomSex = (): SexType => sample(["male", "female"]);

export const getRandomPlaceholder = (
  type: "fio" | "firstname" | "middlename" | "lastname",
  sex?: SexType
) => {
  sex = sex || getRandomSex();

  if (type === "fio")
    return `${sample(lastnames[sex])} ${sample(firstnames[sex])} ${sample(
      middlenames[sex]
    )}`;

  if (type === "firstname") return sample(firstnames[sex]);
  if (type === "middlename") return sample(middlenames[sex]);
  if (type === "lastname") return sample(lastnames[sex]);
};
