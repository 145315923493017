import React from 'react'

import { Modal } from 'components/common'
import { IModalProps } from 'components/common/Modal/interfaces'


const TwoFAModal: React.FC<IModalProps> = (props) => {
	return (
		<Modal {...props}>
			<div className="twosign" id="twosign3">
				<span className="twosign__title title_27">Включение двухэтапной проверки входа </span>
				<div className="attention attention_mt">
					<div className="attention__content">
						<div className="attention__v">
							!
						</div>
						<p className="attention__text null text_18_30">
							Удалить учетную записьыдан орорпыр ывпрров ытв рсыв рпаывап оы впа дан орорпыр ывпрров ытврсыв р паымм мм мммм мв дан ор опыр ывп   рров ытврсыв рпаывап оы впа пр
						</p>
					</div>
				</div>
				<div className="password password_mt">
					<span className="password__text text_18_2">Введите пароль</span>
					<div className="password__inputBlock">
						<input type="password" className="password__input trz input" />
					<span className="password__show trz">Показать</span>
					</div>
				</div>
				<div className="twoBtns">
					<button
						onClick={props.onCancel}
						className="button twoBtns__btn button_transparent"
					>Отмена</button>
					<button className="button twoBtns__btn button_blue">Включить</button>
				</div>
			</div>
		</Modal>
	)
}

export default TwoFAModal
