import axios from "axios";
import cleanDeep from "clean-deep";
import { API_SERVER_URL } from "config/env";
import { insertDocumentMutation } from "data/mutations.data";
import {
  downloadDocumentData,
  downloadDocumentsQuery,
  requestAlliances, requestAvtokodQuery,
  requestDadataQuery, requestEgrnQuery,
  requestGibddQuery,
  subjectsQuery
} from "data/queries.data";
import { STRINGS } from "data/strings.data";
import { DocumentKindEnum } from "interfaces/document.interface";

export const DocumentKinds = [
  "debtorsQuestionnaire",
  "inventoryOfAcitizensProperty",
  "listOfCreditorsAndDebtors",
  // "listOfLitigation",
  "listOfTransactions",
  "bancruptApplication"
];

export const insertDocumentService = async (
  kind: DocumentKindEnum,
  fields: object
) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  let error;

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: insertDocumentMutation,
        variables: { kind, fields },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*"
        },
      }
    );

    console.log(res);

    error = res.data?.error;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  if (error) {
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
  };
};

export const generateDocumentsService = async () => {
  DocumentKinds.forEach(async (kind: DocumentKindEnum) => {
    const data =
      sessionStorage.getItem(kind) && JSON.parse(sessionStorage.getItem(kind));
    data && (await insertDocumentService(kind, data));
  });

  return {
    success: true,
  };
};

export const getDownloadsDocumentService = async () => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  let downloads = {};

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: downloadDocumentsQuery,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    DocumentKinds.forEach(
      (kind: DocumentKindEnum) => downloads[kind] = res.data?.data[kind]?.download
    );
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    downloads: cleanDeep(downloads),
  };
};

export const getDadataQuery = async (query: string) => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: requestDadataQuery(query),
      },
      
    );
    result = res.data.data.dadata;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: JSON.parse(result)
  };
};

export const getGibddQuery = async (query: string) => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: requestGibddQuery(query),
      },

    );
    result = res.data.data.gibddApi;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: JSON.parse(result)
  };
};

export const getAvtokodQuery = async (query: string) => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: requestAvtokodQuery(query),
      },

    );
    result = res.data.data.avtokodApi;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: JSON.parse(result)
  };
};

export const getEgrnQuery = async (query: string) => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: requestEgrnQuery(query),
      },

    );
    result = res.data.data.apiegrn;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: JSON.parse(result)
  };
};

export const getAlliances = async () => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: requestAlliances,
      },

    );
    result = res.data.data.alliances;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: result
  };
};

export const getSubjects = async () => {
  let result;
  try {
    const res = await axios.post(

      API_SERVER_URL,
      {
        query: subjectsQuery,
      },

    );
    result = res.data.data.arbitrationCourts;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: result
  };
};

export const getDocumentsData = async (kind: DocumentKindEnum) => {
  const token =
      sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
      localStorage.getItem(STRINGS.AUTH_TOKEN);

  let result;
  try {
    const res = await axios.post(
        API_SERVER_URL,
        {
          query: downloadDocumentData(kind),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    result = res.data.data[kind];
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    data: result
  };
};

