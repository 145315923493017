import { IClientData } from "interfaces/clientData.interfaces";

export const getInitialClientData = (): IClientData => ({
  firstName: "",
  lastName: "",
  middleName: "",

  changedFirstName: "",
  changedMiddleName: "",
  changedLastName: "",

  dateOfBirth: "",
  placeOfBirth: "",
  snils: "",
  inn: "",
  documentType: "Паспорт",
  documentSerial: "",

  subject: "",
  region: "",
  city: "",
  locality: "",
  street: "",
  homeNumber: "",
  corpusNumber: "",
  apartmentNumber: "",
});
