import axios from "axios";
import { API_SERVER_URL } from "config/env";
import { getConfirmationToken } from "data/mutations.data";
import { STRINGS } from "data/strings.data";

export const fetchConfirmationToken = async (
  idempotenceKey: string,
) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  let error;

  let confirmationToken;

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: getConfirmationToken,
        variables: { idempotenceKey },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*"
        },
      }
    );

    confirmationToken = res.data?.data.confirmationToken;
    console.log(res);

    error = res.data?.error;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  if (error) {
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    confirmationToken,
  };
}; 