export const APP_PATHS = {
  ABOUT: '/about',
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  SMS_VERIFICATION: '/register/verification',
  RESET_PASSWORD: '/reset-password',
  EMAIL_CONFIRMATION: '/confirm/:code',
  SERVICES: '/services',
  PAYMENT: '/payment',
  PAYMENT_CONFIRM: '/payment-confirm',
  FAQ: '/faq',
  CLIENT_DATA: '/client-data',
  PROPERTY: '/property',
  DEALS: '/deals',
  BANCRUPT_APPLICATION: '/bancruptApplication',
  ALLIANCES: '/alliances',
  CREDITORS: '/creditors',
  RESULT: '/result',
  DOCS_CHECKLIST: '/checklist',
  SETTINGS: '/settings',
  SUGGESTIONS: '/suggestions',
  FEEDBACK: '/feedback',
  ADMIN: '/admin'
};

export const API_PATHS = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  USER_PROFILE: '/users/profile',
};
