export type HumanData = {
  fullName: string;
  dateOfBirth: string;
};

export const INITIAL_HUMAN_DATA = {
  fullName: "",
  dateOfBirth: "",
};

export type Alliance = {
  id: string;
  code: string;
  name: string;
};

export interface IBancruptApplicationData {
  relationshipStatus: "Холост" | "В браке";
  spouse?: HumanData;
  presenceOfChildren: "Нет" | "Есть";
  children?: HumanData[];

  conditions: string[];

  alliance: Alliance;
}
