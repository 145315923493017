import {STRINGS} from "../../data/strings.data";
import axios from "axios";
import {API_SERVER_URL} from "../../config/env";
import {deleteUser} from "../../data/mutations.data";
import {logout} from "../../store/modules/Auth/actions";
import {logoutService} from "./logout.service";

export const deleteUserService = async (callback?: () => void) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  let error;

  let userId;

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: deleteUser,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*"
        },
      }
    );

    logoutService(callback);

    userId = res.data?.data.deleteUser.id;
    error = res.data?.error;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  if (error) {
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
    userId,
  };
};