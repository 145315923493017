import { Modal } from "components/common";
import { IModalProps } from "components/common/Modal/interfaces";
import { FINISH_NEXT_STEPS } from "../../../data/documents.data";
import React from "react";
import htmr from "htmr";

export const FinishModal: React.FC<IModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className='mt_15 mb_15 ml_15 mr_15'>
        <h2>
          Поздравляем! Ваше заявление о банкротстве сформировано! Что нужно сделать дальше:
        </h2>
        {FINISH_NEXT_STEPS.map((step, i) => (
          <div>
            <span>{i+1}) </span>
            <span>{htmr(step)}</span>
          </div>
        ))}
      </div>
    </Modal>
  );
};
