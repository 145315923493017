import React from 'react'

import { BreadCrumbs } from 'components/common'


interface ISuggestionsProps {

}

const Suggestions: React.FC<ISuggestionsProps> = ({}) => {
	return <>
		<BreadCrumbs
			links={[
					{title: 'Отзывы и предложения', path: ''},
			]}
		/>
		<h1>Suggestions page</h1>
	</>
}

export default Suggestions
