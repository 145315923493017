import { combineReducers } from 'redux';
import { creditorsReducer } from './modules/Application/creditors.module';
import { dealsReducer } from './modules/Application/deals.module';
import { propertyReducer } from './modules/Application/property.module';

import { authReducer } from './modules/Auth/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  property: propertyReducer,
  deals: dealsReducer,
  creditors: creditorsReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
