import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { APPLICATION_STEPS } from 'data/application.data'


const ProgressLine: React.FC = () => {
	const location = useLocation()
	const currentStepIndex = APPLICATION_STEPS.findIndex(el => el.link === location.pathname)

	return (
		<div className="breadCrumbs__btns">
			{APPLICATION_STEPS.map((step, i) => (
				<Link
					key={i}
					to={step.link}
					className={classNames('breadCrumbs__btn text_12', {
						'active': i <= currentStepIndex
					})}
				>{i + 1}</Link>
			))}
		</div>
	)
}

export default ProgressLine
