import { useState } from "react";

import "./style.scss";

const Tooltip = ({ children, text, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className='tooltip-container'>
      <div className={show ? "tooltip-box visible" : "tooltip-box"}>
        {text}
        <span className='tooltip-arrow' />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

export const QuestionTooltip = ({ text }) => (
  <Tooltip text={text}>
    <img
      src='img/question2.svg'
      alt='question2'
      className='svg applicationForms__questionIcon'
    />
  </Tooltip>
);

export default Tooltip;
