export const APPLICATION_STEPS = [
  {
    previous: '/',
    link: '/client-data',
    title: 'Анкета должника',
    img: 'img/curriculum.svg',
  },
  {
    previous: '/client-data',
    link: '/property',
    title: 'Опись имущества гражданина',
    img: 'img/curriculum.svg',
  },
  {
    previous: '/property',
    link: '/deals',
    title: 'Перечень сделок за последние 3 года',
    img: 'img/curriculum.svg',
  },
  {
    previous: '/deals',
    link: '/creditors',
    title: 'Список кредиторов и должников гражданина',
    img: 'img/curriculum.svg',
  },
  {
      previous: '/creditors',
      link: '/bancruptApplication',
      title: 'Дополнительные сведения',
      img: 'img/curriculum.svg',
    },
  {
    previous: '/litigations',
    link: '/alliances',
    title: 'Выбор СРО финансового управляющего',
    img: 'img/curriculum.svg',
  },
];
