import React from 'react'
import { Link } from 'react-router-dom'
import {getInitialClientData} from "../../../data/clientData.data";


type IProps = {
	title: string
	linkBack?: string
	img: string
}

const PageTitle: React.FC<IProps> = ({ title, linkBack, img }) => {

	const clearLocalStorage = () => {
		localStorage.removeItem('userData')
		localStorage.removeItem('propdata')
		localStorage.removeItem('dealsData')
		localStorage.removeItem('creditorsData')
		localStorage.removeItem('bancruptApplicationData')
		sessionStorage.removeItem('debtorsQuestionnaire')
		sessionStorage.removeItem('inventoryOfAcitizensProperty')
		sessionStorage.removeItem('listOfTransactions')
		sessionStorage.removeItem('listOfCreditorsAndDebtors')
		window.location.reload();
	}

	return (
		<div className="applicationTitles">
			<img src={img} alt={title} className="application__img svg" />
			<h2 className="null applicationTitle title_28">{title}</h2>
			<button onClick={clearLocalStorage} className="button button_aqua mr_5 applicationTitle__btn">
				Очистить все данные
			</button>
			{linkBack && (
				<Link to={linkBack} className="button button_grey applicationTitle__btn">
					Назад
				</Link>
			)}
		</div>
	)
}

export default PageTitle
