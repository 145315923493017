import axios from "axios";
import { useState } from "react";
import { AutoComplete, Input } from "antd";
import { REACT_DADATA_TOKEN } from "config/env";

type IAddressOptionType = { label: string; value: string };

export const AddressInput: React.FC<{
  value: string;
  handleChange: (value: string) => void;
  [prop: string]: any;
}> = ({ value, handleChange, ...inputProps }) => {
  const [options, setOptions] = useState<IAddressOptionType[]>([]);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>(null);

  const handleSearch = async (value: string) => {
    handleChange(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (value.trim().length <= 0) return;

    setSearchTimeout(
      setTimeout(async () => {
        const res = await axios.post(
          "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
          { query: value, count: 5, locations: [{ country: "Россия" }] },
          { headers: { Authorization: `Token ${REACT_DADATA_TOKEN}` } }
        );

        if (!res.data) return;

        setOptions(
          res.data.suggestions.map(({ value }) => ({ label: value, value }))
        );
      }, 500)
    );
  };

  return (
    <AutoComplete
      value={value}
      options={options}
      onSearch={handleSearch}
      onSelect={(_, option) => {
        setOptions([]);
        handleChange(option.value);
      }}
      onBlur={() => setOptions([])}
    >
      <Input.TextArea {...inputProps} autoSize />
    </AutoComplete>
  );
};
