import { useEffect, useState } from "react";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import { Checkbox } from "../../components/common/Checkbox";
import {
  IBancruptApplicationData,
  INITIAL_HUMAN_DATA,
} from "../../interfaces/bancruptApplication.interface";
import { getInitialBancruptApplicationData } from "../../data/bancruptApplication.data";
import cleanDeep from "clean-deep";
import { useHistory } from "react-router";
import { APP_PATHS } from "data/paths.data";
import { ApplicationButton } from "components/common/ApplicationButton";

const conditions = [
  "Гражданин прекратил расчеты с кредиторами, то есть перестал исполнять денежные обязательства и (или) обязанность по уплате обязательных платежей, срок исполнения которых наступил;",
  "Более чем десять процентов совокупного размера денежных обязательств и (или) обязанности по уплате обязательных платежей, которые имеются у гражданина и срок исполнения которых наступил, не исполнены им в течение более чем одного месяца со дня, когда такие обязательства и (или) обязанность должны быть исполнены;",
  "Размер задолженности гражданина превышает стоимость его имущества, в том числе права требования;",
  "Наличие постановления об окончании исполнительного производства в связи с тем, что у гражданина отсутствует имущество, на которое может быть обращено взыскание.",
];

const BancruptApplicationPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const [data, setData] = useState<IBancruptApplicationData>(() => {
    const storeData =
      localStorage.getItem("bancruptApplicationData") &&
      JSON.parse(localStorage.getItem("bancruptApplicationData"));
    return { ...getInitialBancruptApplicationData(), ...storeData };
  });

  useEffect(() => {
    localStorage.setItem("bancruptApplicationData", JSON.stringify(data));
  }, [data]);

  const handleSelectChange = (e: any) => {
    const toUpdateData = JSON.parse(JSON.stringify(data));
    toUpdateData[e.target.name] = e.target.value;


    if (e.target.name === "relationshipStatus") {
      if (e.target.name === "Холост") {
        toUpdateData.spouse = null;
      }
      if (e.target.value === "В браке") {
        toUpdateData.spouse = toUpdateData.spouse ? toUpdateData.spouse : INITIAL_HUMAN_DATA;
      }
    }

    if (e.target.name === "presenceOfChildren") {
      if (e.target.value === "Нет") {
        toUpdateData.children = [];
      }
      if (e.target.value === "Есть") {
        toUpdateData.children = toUpdateData.children.length ? toUpdateData.children : [INITIAL_HUMAN_DATA];
      }
    }

    setData(toUpdateData);
  };

  const handleSpouseChange = (e: any) => {
    const toUpdateData = JSON.parse(JSON.stringify(data));
    toUpdateData.spouse[e.target.name] = e.target.value;
    setData(toUpdateData);
  }

  const handleChildrenChange = (e: any, i: number) => {
    const toUpdateData = JSON.parse(JSON.stringify(data));
    toUpdateData.children[i][e.target.name] = e.target.value;
    setData(toUpdateData);
  };

  const handleCheckboxChange = (condition: string) => {
    const conditions = data.conditions;
    const index = conditions.findIndex((item) => item === condition);
    index === -1 ? conditions.push(condition) : conditions.splice(index, 1);

    setData({
      ...data,
      conditions,
    });
  };

  const addChildren = () => {
    const toUpdateData = JSON.parse(JSON.stringify(data));
    toUpdateData.children.push(INITIAL_HUMAN_DATA);
    setData(toUpdateData);
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    localStorage.setItem(
      "bancruptApplicationData",
      JSON.stringify(cleanDeep(data))
    );

    setIsLoading(false);

    history.push(APP_PATHS.ALLIANCES);
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={
          (e) => e.key === 'Enter' && e.preventDefault()
        }
      >
        <div className='application__form application__form_p'>
          <ul className='decorContent__list'>
            <li className='mb_30'>
              <span className='text_18 inline-block' style={{ width: "160px" }}>
                Семейное положение:
              </span>
              <select
                value={data.relationshipStatus}
                onChange={handleSelectChange}
                name='relationshipStatus'
                className='applicationForms__inputs applicationForms__inputs_mt15 text_14_17'
              >
                {["Холост", "В браке"].map((status, key) => (
                  <option key={key} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </li>

            <li>
              {data.relationshipStatus !== "Холост" && (
                <div className='applicationForms__inputs_line mb_30'>
                  <span className='text_18 block w_100'>
                    Данные супруга/супруги:
                  </span>
                  <div className='applicationForms__inputs applicationForms__inputs_big2 applicationForms__inputs_mt15'>
                    <span className='applicationForms__value text_12'>
                      ФИО
                    </span>
                    <input
                      type='text'
                      name='fullName'
                      autoComplete='off'
                      value={data.spouse.fullName}
                      onChange={handleSpouseChange}
                      className='applicationForms__input applicationForms__input_dis'
                      required
                    />
                  </div>

                  <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                    <span className='applicationForms__value text_12'>
                      Дата рождения
                    </span>
                    <input
                      type='date'
                      name='dateOfBirth'
                      value={data.spouse.dateOfBirth}
                      onChange={handleSpouseChange}
                      className='applicationForms__input'
                      id='birth'
                      placeholder='11.11.1986'
                      required
                    />
                  </div>
                </div>
              )}
            </li>

            <li className='mb_30'>
              <span className='text_18 inline-block' style={{ width: "160px" }}>
                Дети:
              </span>
              <select
                value={data.presenceOfChildren}
                onChange={handleSelectChange}
                name='presenceOfChildren'
                className='applicationForms__inputs applicationForms__inputs_mt15 text_14_17'
              >
                {["Нет", "Есть"].map((value, key) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </li>

            <li>
              {data.presenceOfChildren === "Есть" && (
                <>
                  <span className='text_18 block w_100'>Данные детей:</span>
                  {data.children.map((children, i) => (
                    <div
                      className='applicationForms__inputs_line mb_10'
                      key={i}
                    >
                      <div className='applicationForms__inputs applicationForms__inputs_big2 applicationForms__inputs_mt15'>
                        <span className='applicationForms__value text_12'>
                          ФИО
                          {i === 0}
                        </span>
                        <input
                          type='text'
                          name='fullName'
                          autoComplete='off'
                          value={children.fullName}
                          onChange={(e) => handleChildrenChange(e, i)}
                          className='applicationForms__input applicationForms__input_dis'
                          required={i === 0}
                        />
                      </div>

                      <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                        <span className='applicationForms__value text_12'>
                          Дата рождения
                          {i === 0}
                        </span>
                        <input
                          type='date'
                          name='dateOfBirth'
                          value={data.children[i].dateOfBirth}
                          onChange={(e) => handleChildrenChange(e, i)}
                          className='applicationForms__input'
                          id='birth'
                          placeholder='11.11.1986'
                          required={i === 0}
                        />
                      </div>
                    </div>
                  ))}

                  <button
                    className='formLine__add'
                    type='button'
                    onClick={addChildren}
                    style={{
                      position: "relative",
                      bottom: 0,
                      margin: "30px auto",
                    }}
                  >
                    <img
                      src='img/add.svg'
                      alt='add'
                      className='svg formLine__addIcon'
                    />
                  </button>
                </>
              )}
            </li>

            <li>
              <p className='application__text text_18_21'>
                На момент обращения в арбитражный суд, фактически являюсь
                неплатёжеспособным, поскольку, имеют место следующие
                обстоятельства, указанные в п. 3 ст. 213.6 Закона:
              </p>
              <div className='applicationForms__inputs_line mt_20'></div>
              {conditions.map((item, i) => (
                <Checkbox
                  key={i}
                  handleCheckboxChange={handleCheckboxChange}
                  label={item}
                  isCheckedDefault={
                    !!data.conditions.find((condition) => condition === item)
                  }
                />
              ))}
            </li>
          </ul>
        </div>

        <ApplicationButton isLoading={isLoading}>
          Заполнить данные. Шаг 6
        </ApplicationButton>
      </form>
    </ApplicationWrapper>
  );
};

export default BancruptApplicationPage;
