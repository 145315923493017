import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "store";

import { BreadCrumbs } from "components/common";
import { PROJECT_NAME_HTML_RUS } from "../../data/common.data";
import "./style.scss";
import { Link } from "react-router-dom";
import { APP_PATHS } from "data/paths.data";
import { CollectModal } from "components/other/CollectModal";
import { useHistory } from "react-router";
import { TelegramModal } from "components/other/TelegramModal";


const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
});

type AboutPageType = ReturnType<typeof mapStateToProps>;

const AboutPage: React.FC<AboutPageType> = ({ auth }) => {
  const [telegramModalIsVisible, setTelegramModalIsVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>();
  const history = useHistory();

  useEffect(() => {
    if (!videoRef.current) return;

    if (isVideoPlaying) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      return;
    }

    videoRef.current.pause();
    videoRef.current.currentTime = 34;
  }, [videoRef, isVideoPlaying]);

  return (
    <>
      {auth.isAuthenticated && auth.isVerified && (
        <BreadCrumbs links={[{ title: "О сервисе", path: "" }]} />
      )}
      <div>
        <div className='container'>
          <div className='section section-first'>
            <div className="top-phone-number">
              <div className="footer__socials">
                <a href="https://t.me/bankrot_doc_bot" target="_blank" className="footer__social-link">
                  <img src="/img/telegram-logo.png" alt="Telegram" />
                </a>
                <a href="whatsapp://send?phone=+79933822122" target="_blank" className="footer__social-link">
                  <img src="/img/whatsapp-logo.png" alt="WhatsApp" />
                </a>
                <a href="viber://chat?number=79933822122" target="_blank" className="footer__social-link">
                  <img src="/img/viber-logo.png" alt="Viber" />
                </a>
              </div>
              <span className="footer__phone-number">
                <a href="tel:+79933822122">8 993 382 21 22</a>
              </span>
            </div>
            <div className='section-first__content-container content-container'>
              <div className='content-container__left'>
                {/* <div className='title'>{PROJECT_NAME_HTML_RUS}</div> */}
                <div className='title'><img src="img/logo.svg" style={{height: "250px", width: "300px"}} alt="" /></div>
                <div className='content'>
                  Единственный онлайн-сервис в России для подготовки и подачи
                  заявления о банкротстве самостоятельно. Каждый гражданин
                  сможет освоить несложный процесс и не платить большие суммы за
                  услуги юристов.
                </div>
                <Link to={APP_PATHS.REGISTER}>
                  <button className='mt_25 button button_primary trz'>
                    Начать процедуру
                  </button>
                </Link>
              </div>
              <div className='content-container__right'>
                <img src='img/aboutTop.png' alt='about bancrupt' />
              </div>
            </div>
          </div>

          <div
            className={`section mt_70 section-video ${
              !isVideoPlaying ? "notplaying" : ""
            }`}
            onClick={() => !isVideoPlaying && setIsVideoPlaying(true)}
          >
            <video
              src='video/about.mp4'
              ref={videoRef}
              controls={isVideoPlaying}
              controlsList='nodownload noplaybackrate'
              disablePictureInPicture
              onEnded={() => setIsVideoPlaying(false)}
            />
          </div>

          <div className='section section-second'>
            <div className='section-second__title section-title'>
              Почему стоит выбрать {PROJECT_NAME_HTML_RUS}, а не юридическое
              агентство?
            </div>
            <div className='section-second__content-container content-container'>
              <div className='content-container__text'>
                Многим кажется, что заявления для суда необходимо готовить под
                присмотром юриста. На самом деле это далеко не всегда так.
              </div>
            </div>
          </div>
          <div className='section section-third'>
            <div className='section-third__title section-title'>
              За что вы платите юридическому агентству?
            </div>
            <div className='section-third__content-container content-container'>
              <div className='content-container__top top'>
                <div className='top__left'>
                  <div className='section'>
                    <div className='section__title'>
                      Знание юридических аспектов
                    </div>
                    <div className='section__content'>
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        {`НО! `}
                      </span>
                      гражданам необязательно знать закон, чтобы заявить о
                      собственном банкротстве. Это подтверждает практика: на
                      данный момент{" "}
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        не существует случаев непризнания гражданина банкротом
                        из-за юридической неграмотности должника
                      </span>
                    </div>
                  </div>
                  <div className='section large-hidden'>
                    <div className='section__title'>Консультация</div>
                    <div className='section__content'>
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        {`НО! `}
                      </span>
                        обычно это бесплатная услуга, также на сервисе БанкротДок вы можете получить консультацию финансового управляющего в {" "}
                      <span
                          style={{
                            color: "#0061FE",
                            fontWeight: "bold",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => setTelegramModalIsVisible(true)}
                      >телеграм боте.</span>
                    </div>
                  </div>
                  <div style={{marginTop: '28px'}} />
                  <div className='section'>
                    <div className='section__title'>
                      Сбор документов
                    </div>
                    <div className='section__content'>
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        {`НО! `}
                      </span>
                      Большая часть документов есть у вас{" "}
                      <span
                        style={{
                          color: "#0061FE",
                          fontWeight: "bold",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => history.push(APP_PATHS.DOCS_CHECKLIST)}
                      >на руках,</span> кроме того вы
                      можете воспользоваться{" "}
                      <span
                        style={{
                          color: "#0061FE",
                          fontWeight: "bold",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setIsVisible(true)}
                      >
                      услугой сбора документов{" "}
                    </span>
                      в БанкротДок
                    </div>
                  </div>
                  <div className='section'>
                    <div className='section__title'>
                      Обязательное участие представителя в суде
                    </div>
                    <div className='section__content'>
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        {`НО! `}
                      </span>
                      юридические агентства берут деньги за присутствие юриста,
                      не обращая внимания на такие моменты:
                    </div>
                  </div>
                </div>
                <div className='top__right'>
                  <div className='section'>
                    <div className='section__title'>Консультация</div>
                    <div className='section__content'>
                      <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                        {`НО! `}
                      </span>
                      обычно это бесплатная услуга, также на сервисе БанкротДок вы можете получить консультацию финансового управляющего в {" "}
                      <span
                        style={{
                          color: "#0061FE",
                          fontWeight: "bold",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setTelegramModalIsVisible(true)}
                      >телеграм боте.</span>
                    </div>
                  </div>
                  <img src='img/aboutBottom.png' alt='aboutBottom' />
                </div>
              </div>
              <div className='content-container__bottom bottom'>
                <div className='bottom__top'>
                  <div className='section'>
                    <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                      Вы можете участвовать в судебных заседаниях
                      самостоятельно,
                    </span>{" "}
                    но не стоит этого бояться. Достаточно объяснить причину
                    банкротства судье, с этим справится каждый и без участия
                    юриста.
                  </div>
                  <div className='section'>
                    Участвовать в судебных заседаниях возможно в режиме{" "}
                    <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                      онлайн не выходя из дома.
                    </span>
                  </div>
                </div>
                <div className='bottom__bottom'>
                  <div className='section'>
                    Можно и вовсе не присутствовать на заседании и{" "}
                    <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                      попросить суд рассмотреть его без вашего участия.
                    </span>{" "}
                    Известно очень много случаев введения банкротства только на
                    основании документов.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container__footer'>
            <div className='title'>
              Стоимость подготовки и подачи заявления в сервисе{" "}
              {PROJECT_NAME_HTML_RUS} всего
            </div>
            <div className='content'>10 000 руб.</div>
          </div>
        </div>
        <div className='footer-container'>
          <div className='footer-container__section footer-section'>
            <div className='footer-section__title'>
              Основатели онлайн-сервиса {PROJECT_NAME_HTML_RUS}
            </div>
            <div className='footer-section__content'>
              <div className='text'>
                Профессиональные финансовые управляющие, которые применили свои
                знания о тонкостях данного процесса для вашей пользы.
                <br />
                <span style={{ color: "#0061FE", fontWeight: "bold" }}>
                  {PROJECT_NAME_HTML_RUS}
                </span>{" "}
                обязательно вернет деньги, если подготовленное заявление будет
                признано судом как некорректное.
              </div>
            </div>
          </div>
        </div>
      </div>

      <TelegramModal
        isVisible={telegramModalIsVisible}
        onCancel={() => setTelegramModalIsVisible(false)}
      />
      <CollectModal
        isVisible={isVisible}
        onCancel={() => setIsVisible(false)}
      />
    </>
  );
};

export default connect(mapStateToProps)(AboutPage);
