import { BrowserRouter } from "react-router-dom";

import CustomSwitch from "utils/CustomSwitch";
import UpdateRoute from "utils/UpdateRoute";
import PublicRoute from "routers/PublicRoute";
import ProtectedRoute from "routers/ProtectedRoute";
import AuthRoute from "routers/AuthRoute";
import { APP_PATHS } from "data/paths.data";

import AboutPage from "pages/About.page";
import FAQPage from "pages/FAQ.page";
import Error404Page from "pages/Error404.page";
import LoginPage from "pages/Login.page";
import RegisterPage from "pages/Register.page";
import PhoneVerification from "pages/PhoneVerification.page";
import EmailConfirmation from "pages/EmailConfirmation.page";
import ServicesPage from "pages/Services.page";
import SettingsPage from "pages/Settings.page";
import ResultPage from "pages/Result.page";
import PaymentPage from "pages/Payment.page";
import PaymentConfirmPage from "pages/PaymentConfirm.page";
import ClientDataPage from "pages/ClientData.page";
import PropertyPage from "pages/Property.page";
import DealsPage from "pages/Deals.page";
import CreditorsPage from "pages/Creditors.page";
import SuggestionsPage from "pages/Suggestions.page";
import AlliancesPage from "./pages/Alliances.page";
import BancruptApplicationPage from "./pages/BancruptApplication.page";

import "styles/main.scss";
import DocsChecklistPage from "./pages/DocsChecklist.page";
import AdminPage from "pages/Admin.page";
import ResetPassword from "pages/ResetPassword.page";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <UpdateRoute />

      <CustomSwitch>
        <ProtectedRoute exact path={APP_PATHS.HOME} component={ServicesPage} />
        <ProtectedRoute exact path={APP_PATHS.FAQ} component={FAQPage} />
        <PublicRoute exact path={APP_PATHS.ABOUT} component={AboutPage} />
        {/* <ProtectedRoute
          exact
          path={APP_PATHS.SERVICES}
          component={ServicesPage}
        /> */}
        <ProtectedRoute
          exact
          path={APP_PATHS.PAYMENT}
          component={PaymentPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.PAYMENT_CONFIRM}
          component={PaymentConfirmPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.SETTINGS}
          component={SettingsPage}
        />
        <PublicRoute
            exact
            path={APP_PATHS.RESET_PASSWORD}
            component={ResetPassword}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.DOCS_CHECKLIST}
          component={DocsChecklistPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.CLIENT_DATA}
          component={ClientDataPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.PROPERTY}
          component={PropertyPage}
        />
        <ProtectedRoute exact path={APP_PATHS.DEALS} component={DealsPage} />
        <ProtectedRoute
          exact
          path={APP_PATHS.BANCRUPT_APPLICATION}
          component={BancruptApplicationPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.ALLIANCES}
          component={AlliancesPage}
        />
        <ProtectedRoute
          exact
          path={APP_PATHS.CREDITORS}
          component={CreditorsPage}
        />
        <ProtectedRoute exact path={APP_PATHS.RESULT} component={ResultPage} />
        <ProtectedRoute
          exact
          path={APP_PATHS.SUGGESTIONS}
          component={SuggestionsPage}
        />
        <ProtectedRoute exact path={APP_PATHS.ADMIN} component={AdminPage} />
        {/* <ProtectedRoute
          exact
          path={APP_PATHS.FEEDBACK}
          component={FeedbackPage}
        /> */}
        <PublicRoute
          path={APP_PATHS.EMAIL_CONFIRMATION}
          component={EmailConfirmation}
        />

        {/* Auth */}
        <AuthRoute exact path={APP_PATHS.LOGIN} component={LoginPage} />
        <AuthRoute exact path={APP_PATHS.REGISTER} component={RegisterPage} />
        <AuthRoute
          exact
          path={APP_PATHS.SMS_VERIFICATION}
          component={PhoneVerification}
        />

        {/* Errors */}
        <PublicRoute path='/' component={Error404Page} />
      </CustomSwitch>
    </BrowserRouter>
  );
};

export default App;
