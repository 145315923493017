import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { STRINGS } from "../../data/strings.data";
import { IUser } from "../../interfaces/users.interfaces";
import { userListBySroQuery } from "../../data/queries.data";
import { APP_PATHS } from "data/paths.data";
import { API_SERVER_URL } from "../../config/env";
import { Table } from "antd";

const AdminPage: React.FC = () => {
  const [userList, setUserList] = useState<IUser[]>([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token =
        sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
        localStorage.getItem(STRINGS.AUTH_TOKEN);

      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const res = await axios.post(
          API_SERVER_URL,
          { query: userListBySroQuery },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!res.data?.errors) setHasPermission(true);

        setUserList(res.data.data?.userListBySro);

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { title: "ФИО", dataIndex: "fullname", key: "fullname" },
    { title: "Телефон", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Субъект", dataIndex: "subject", key: "subject" },
  ];

  if (!loading && !hasPermission) return <Redirect to={APP_PATHS.HOME} />;

  return (
    <div className='application application_mt wrapper h_100'>
      <div className='h_100 bg_white p_20'>
        <h1 className='block-title mb_20'>Админ. панель</h1>
        <Table
          columns={columns}
          dataSource={
            userList &&
            userList.map((user, i) => ({
              key: i,
              fullname: `${user.firstName} ${user.middleName} ${user.lastName}`,
              phoneNumber: user.phoneNumber,
              email: user.email,
              subject: user?.document?.fields?.subject,
            }))
          }
        />
      </div>
    </div>
  );
};

export default AdminPage;
