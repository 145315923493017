import './style.scss'

import React, { Fragment } from 'react'
import { useHistory } from 'react-router'

import { BreadCrumbs } from 'components/common'
import { APP_PATHS } from 'data/paths.data'


const PaymentConfirmPage: React.FC = () => {
	const history = useHistory()

	const navigateToResult = (e: any) => {
		e.preventDefault()
		history.push(APP_PATHS.RESULT)
	}

	return (
		<Fragment>
			<BreadCrumbs links={[
				{title: 'Оплата', path: APP_PATHS.PAYMENT},
				{title: 'Подтверждение', path: ''},
			]} />

			<div className="confirm confirm_mt">
				<span className="title_53 confirm__title">Подтвердите платеж</span>
				<div className="confirmBlock">
					<div className="confirmBlock__column">
						<span className="confirmBlock__naming text_18_2">Наименование платежа</span>
						<span className="confirmBlock__gos text_18_2 bold">Оплата услуги формирования заявления банкротства физического лица</span>
						<div className="confirmBlock__datas">
							<div className="confirmBlock__data">
								<span className="text_18_2">Дата выставления счета:</span>
								<span className="text_18_2 confirmBlock__indefication bold">24.04.2018</span>
							</div>
							<div className="confirmBlock__data">
								<span className="text_18_2">Уникальный идентификатор:</span>
								<span className="text_18_2 confirmBlock__indefication bold">273468264874687234</span>
							</div>
						</div>
						<p className="null text_18_2 confirmBlock__text">Наименование платеж Наименование пл Наименование плНаименование плНаименование плНаименование плНаи енован ие плНаименован ие плНаименование пл Н аимено вание плНаименование плНаименование плНаи менование плаi</p>
					</div>
					<div className="confirmBlock__column">
						<span className="text_22">Итого к оплате:</span>
						<span className="confirm__price title_35">1400.00 Р</span>
						<div className="confirm__inf text_18_2">Сумма платежа: <span className="bold">1400Р</span></div>
						<div className="confirm__inf text_18_2">Комиссия: <span className="bold">0.00 Р</span></div>
						<button className="confirmBlock_btn trz button button_blue" onClick={navigateToResult}>Оплатить</button>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default PaymentConfirmPage
