import { DocumentKindEnum } from "interfaces/document.interface";

export const iamQuery = `
	query Iam{
		iam {
			id
			email
			phoneNumber
			firstName
			middleName
			lastName	
			isVerifiedSMS
			isPaid
			isVerifiedEmail
			notifications {
				body
				date: createdAt
			}
		}
	}
`;

export const downloadDocumentData = (kind: DocumentKindEnum) => `
	query DownloadDocuments {
		${kind}: document(kind: ${kind}) {fields}
	}
`;

export const downloadDocumentQuery = (kind: DocumentKindEnum) => `
	query DownloadDocuments {
		${kind}: document(kind: ${kind}) {download}
	}
`;

export const downloadDocumentsQuery = `
	query DownloadDocuments {
		debtorsQuestionnaire: document(kind: debtorsQuestionnaire) {download}
		inventoryOfAcitizensProperty: document(kind: inventoryOfAcitizensProperty) {download}
		listOfCreditorsAndDebtors: document(kind: listOfCreditorsAndDebtors) {download}
		listOfLitigation: document(kind: listOfLitigation) {download}
		listOfTransactions: document(kind: listOfTransactions) {download}
		bancruptApplication: document(kind: bancruptApplication) {download}
	}
`;

export const requestDadataQuery = (query: string) => `
	query {
		dadata(query: "${query}")
	}
`;

export const requestGibddQuery = (query: string) => `
	query {
		gibddApi(query: "${query}")
	}
`;

export const requestAvtokodQuery = (query: string) => `
	query {
		avtokodApi(query: "${query}")
	}
`;

export const requestEgrnQuery = (query: string) => `
	query {
		apiegrn(query: "${query}")
	}
`;

export const requestAlliances = `
	query {
		alliances {id, code, name}
	}
`;

export const creditOrganizationsQuery = `
	query($searchValue: String!) {
		creditOrganizations(searchValue: $searchValue) {
			csname
			strcuraddr
		}
	}
`;

export const subjectsQuery = `
	query {
	  arbitrationCourts(searchValue: "") {subject}
	}
`;

export const userListBySroQuery = `
	query {
	  userListBySro { firstName, lastName, middleName, phoneNumber, email, isPaid, document { fields } }
	}
`;
