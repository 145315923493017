import { DealType } from 'interfaces/deal.interface';

export const INITIAL_DEAL_ITEM: DealType = {
  id: '',
  name: '',
  requisites: '',
  terms: '',
};

export const DEAL_TYPES = {
  immovableProperty: {
    title: 'I. Сделки с недвижимым имуществом',
    nameTitle: 'Объект недвижимости',
  },
  securities: {
    title: 'II. Сделки с ценными бумагами',
    nameTitle: 'Ценная бумага',
  },
  sharesInAuthorizedCapital: {
    title: 'III. Сделки с долями в уставном капитале',
    nameTitle: 'Доля в уставном капитале',
  },
  transport: {
    title: 'IV. Сделки с транспортными средствами',
    nameTitle: 'Транспортное средство',
  },
  over300000: {
    title: 'V. Сделки на сумму свыше 300 000 рублей',
    nameTitle: null,
  },
};

export const getInitialDeals = () => {
  const dataFromLS = localStorage.getItem('dealsData');
  if (dataFromLS) return JSON.parse(dataFromLS);

  const result = {};

  Object.keys(DEAL_TYPES).forEach((key) => {
    result[key] = [{ ...INITIAL_DEAL_ITEM }];
  });

  return result;
};
