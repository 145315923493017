import axios from "axios";
import { useState } from "react";
import { AutoComplete, Input } from "antd";
import { API_SERVER_URL } from "config/env";
import { creditOrganizationsQuery } from "data/queries.data";

export type BankInputOptionType = {
  value: string;
  csname: string;
  strcuraddr: string;
};

export const BankInput: React.FC<{
  value: string;
  fullLabel?: boolean;
  handleChange: (value: string) => void;
  handleSelect: (option: any) => void;
  [prop: string]: any;
}> = ({
  value,
  fullLabel = false,
  handleChange,
  handleSelect,
  ...inputProps
}) => {
  const [options, setOptions] = useState<BankInputOptionType[]>([]);

  const [searchTimeout, setSearchTimeout] = useState(null as NodeJS.Timeout);

  const handleSearch = async (value: string) => {
    handleChange(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (value.trim().length <= 0) return;

    setSearchTimeout(
      setTimeout(async () => {
        const res = await axios.post(API_SERVER_URL, {
          query: creditOrganizationsQuery,
          variables: { searchValue: value },
        });
        if (!res) return;
        setOptions(res.data?.data?.creditOrganizations || []);
      }, 500)
    );
  };

  return (
    <AutoComplete
      value={value}
      options={(options || []).map((option, i) => ({
        key: i,
        label: fullLabel
          ? option.csname + ", " + option.strcuraddr
          : option.csname,
        value: option.csname,
        strcuraddr: option.strcuraddr,
      }))}
      onSearch={handleSearch}
      onSelect={(_, option) => {
        setOptions([]);
        handleSelect(option);
      }}
      onBlur={() => setOptions([])}
    >
      <Input.TextArea {...inputProps} autoSize />
    </AutoComplete>
  );
};
