import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import { APP_PATHS } from "data/paths.data";
import {
  getAlliances,
  insertDocumentService,
} from "services/docs/docs.service";
import { IBancruptApplicationData } from "../../interfaces/bancruptApplication.interface";
import { getInitialBancruptApplicationData } from "../../data/bancruptApplication.data";
import { ApplicationButton } from "components/common/ApplicationButton";
import {TelegramModal} from "../../components/other/TelegramModal";

const AlliancesPage: React.FC = () => {
  const history = useHistory();
  const [telegramModalIsVisible, setTelegramModalIsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [alliances, setAlliances] = useState([]);
  const [selectedAllianceCode, setSelectedAllianceCode] = useState("0019");
  const [data, setData] = useState<IBancruptApplicationData>(() => {
    const storeData =
      localStorage.getItem("bancruptApplicationData") &&
      JSON.parse(localStorage.getItem("bancruptApplicationData"));
    return { ...getInitialBancruptApplicationData(), ...storeData };
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAlliances();
      setAlliances(res.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setData({
      ...data,
      alliance: alliances.find((item) => item.code === selectedAllianceCode),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAllianceCode, alliances.length]);

  useEffect(() => {
    localStorage.setItem("bancruptApplicationData", JSON.stringify(data));
  }, [data]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const res = await insertDocumentService("bancruptApplication", data);

    setIsLoading(false);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.PAYMENT);
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={
          (e) => e.key === 'Enter' && e.preventDefault()
        }
      >
        <div className='application__form application__form_p'>
          <ul className='decorContent__list'>
            <p className='application__text text_18_21'>
              Выберите финансового управляющего. При выборе «Союз арбитражных
              управляющих "Саморегулируемая организация "ДЕЛО"», БанкротДок
              предоставляет скидку 50% на услуги сервиса.
            </p>
            <div>
              <span
                className='application__text text_18_21'
                style={{
                  color: "#0061FE",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => setTelegramModalIsVisible(true)}
              >
                Обсудить условия работы с финансовым управляющим.
              </span>
            </div>
            <div className='applicationForms__inputs_line mt_20'></div>
            <select
              value={selectedAllianceCode}
              onChange={(e) => setSelectedAllianceCode(e.target.value)}
              name='alliance'
              className='applicationForms__inputs applicationForms__inputs_big3 w_100 text_14_17'
            >
              {alliances.map((alliance) => (
                <option value={alliance.code}>{alliance.name}</option>
              ))}
            </select>
          </ul>
        </div>

        {errorMessage && (
          <span
            style={{
              display: "block",
              color: "#FF4040",
              marginTop: "20px",
            }}
          >
            {errorMessage}
          </span>
        )}

        <ApplicationButton isLoading={isLoading}>
          Сгенерировать заявление
        </ApplicationButton>

        {/* <button type='submit' className='button applicationBtn'>
          Сгенерировать заявление
        </button> */}
      </form>
      <TelegramModal
        isVisible={telegramModalIsVisible}
        onCancel={() => setTelegramModalIsVisible(false)}
      />
    </ApplicationWrapper>
  );
};

export default AlliancesPage;
