import { Modal } from "components/common";
import { IModalProps } from "components/common/Modal/interfaces";
import { useState } from "react";
import { emailService } from "services/auth/email.service";
import "./styles.scss";

export const CollectModal: React.FC<IModalProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState<{
    fullname?: string;
    phoneNumber: string;
    email: string;
  }>({
    fullname: "",
    phoneNumber: "+7",
    email: "",
  });

  const handleChange = (e: any) => {
    if (e.target.name === "phoneNumber") {
      if (/^((\+[0-9]{1,2})+([0-9]){0,10})$/.test(e.target.value)) {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await emailService(form);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    props.onCancel();
  };

  return (
    <Modal {...props}>
      <form className='form collect_form' onSubmit={handleSubmit}>
        <h2 className='form__title title_28 null mb_20'>
          Подать заявку на сбор документов
        </h2>
        <input
          type='text'
          name='fullname'
          value={form.fullname}
          onChange={handleChange}
          className='input input_mt2'
          placeholder='Имя'
        />
        <input
          type='tel'
          name='phoneNumber'
          pattern='^((\+?[0-9]{1,2})+([0-9]){10})$'
          value={form.phoneNumber}
          onChange={handleChange}
          className='input input_mt2'
          placeholder='+7ХХХХХХХХХХ'
          minLength={12}
          maxLength={12}
          required
        />
        <input
          type='email'
          name='email'
          value={form.email}
          onChange={handleChange}
          className='input input_mt2'
          placeholder='Электронная почта'
          required
        />

        {errorMessage && (
          <span
            style={{ display: "block", color: "#FF4040", marginTop: "10px" }}
          >
            {errorMessage}
          </span>
        )}

        <button type='submit' className='button_primary white clickable trz'>
          Отправить
        </button>
      </form>
    </Modal>
  );
};
