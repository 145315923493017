import React, {useEffect, useState} from 'react'
import { Route, RouteProps } from 'react-router-dom'
import {useDispatch} from 'react-redux'

import MainLayout from 'components/layouts/MainLayout'
import { fetchAuthUserService } from 'services/auth/fetchAuthUser.service'


const PublicRoute: React.FC<RouteProps> = (props) => {
	const dispatch = useDispatch()
	const [isDataReady, setIsDataReady] = useState(false);

	useEffect(() => {
		fetchAuthUserService()(dispatch)
		setIsDataReady(true);
	}, [dispatch])

	if (!isDataReady) {
		return <></>;
	}

	return (
		<MainLayout>
			<Route {...props} />
		</MainLayout>
	)
}

export default PublicRoute;
