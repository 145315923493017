export const loginMutation = `
	mutation Login($emailOrPhoneNumber: String!, $password: String!) {
		login(emailOrPhoneNumber: $emailOrPhoneNumber, password: $password)
	}
`;

export const resetPasswordMutation = `
	mutation ResetPassword($phoneNumber: String!, $code: String, $password: String) {
		resetPassword(phoneNumber: $phoneNumber, code: $code, password: $password)
	}
`;

export const registerMutation = `
	mutation Register($email: String!, $phoneNumber: String!, $firstName: String!, $middleName: String!, $lastName: String!, $password: String!) {
		register(email: $email, phoneNumber: $phoneNumber, firstName: $firstName, middleName: $middleName, lastName: $lastName, password: $password)
	}
`;

export const sendVerificationSMSMutation = `
	mutation sendVerificationSMSMutation {
		sendVerificationSMS
	}
`;

export const sendVerificationEmailMutation = `
	mutation sendVerificationEmailMutation {
		sendVerificationEmail
	}
`;

export const verifySMSMutation = `
	mutation VerifySMS($code: String!) {
		verifySMS(code: $code)
	}
`;

export const verifyEmailMutation = `
	mutation VerifyEmail($code: String!) {
		verifyEmail(code: $code)
	}
`;

export const insertDocumentMutation = `
	mutation InsertDocument($kind: DocumentKind!, $fields: DocumentFields) {
		insertDocument(
			kind: $kind,
			fields: $fields
		){
			id
			kind
			fields
		}
	}
`;

export const getConfirmationToken = `
	mutation confirmationToken($idempotenceKey: String!) {
		confirmationToken(
			idempotenceKey: $idempotenceKey,
		)
	}
`;

export const deleteUser = `
	mutation deleteUser { 
		deleteUser { 
		  id 
		}
	}
`;

export const emailMutation = `
	mutation Email($email: String!, $phoneNumber: String!, $fullname: String!,) {
		email(email: $email, phoneNumber: $phoneNumber, fullname: $fullname)
	}
`;
