export const STRINGS = {
	AUTH_TOKEN: 'authToken',
}

export const PAGE_TITLES = {
	SERVICES: 'Услуги',
	SETTINGS: 'Настройки профиля',
	RESULT: 'Результат',
	DOCS_CHECKLIST: 'Чеклист'
}
