import {immovablePropertyTypes} from "../data/property.data";

export type IPropertyTypeList = {
  [key: string]: {
    title: string;
    type?: string;
  };
};

export interface IImmovablePropertyItem {
  name: string;
  ownType: string;
  location: string;
  space: string;
  purchaseReason: string;
  ownerData: string;
}

export const INITIAL_IMMOVABLE_PROPERTY_ITEM = (typeKey): IImmovablePropertyItem => ({
  name: immovablePropertyTypes[typeKey].type,
  ownType: '',
  location: '',
  space: '',
  purchaseReason: '',
  ownerData: '',
});

export interface IMovablePropertyItem {
  name: string;
  id: string;
  propertyType: string;
  location: string;
  cost: string;
  ownerData: string;
}

export const INITIAL_MOVABLE_PROPERTY_ITEM: IMovablePropertyItem = {
  name: '',
  id: '',
  propertyType: '',
  location: '',
  cost: '',
  ownerData: '',
};

export interface IBankAccount {
  bank: string;
  currency: string;
  openDate: string;
  balance: string;
}

export const INITIAL_BANK_ACCOUNT: IBankAccount = {
  bank: '',
  currency: '',
  openDate: '',
  balance: '',
};

export interface IStock {
  organization: string;
  location: string;
  fund: string;
  percent: string;
  reasons: string;
}

export const INITIAL_STOCK: IStock = {
  organization: '',
  location: '',
  fund: '',
  percent: '',
  reasons: '',
};

export interface IOtherSecurities {
  name: string;
  author: string;
  startAmount: string;
  totalAmount: string;
  totalCost: string;
}

export const INITIAL_OTHER_SECURITIES: IOtherSecurities = {
  name: '',
  author: '',
  startAmount: '',
  totalAmount: '',
  totalCost: '',
};

export interface IValuableProperty {
  name: string;
  cost: string;
  location: string;
  ownerData: string;
}

export const INITIAL_VALUABLE_PROPERTY_ITEM: IValuableProperty = {
  name: '',
  cost: '',
  location: '',
  ownerData: '',
};
