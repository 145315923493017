import { Dispatch, AnyAction } from 'redux';
import { typedAction } from '../../helpers';
import { INITIAL_PROPERTY } from '../../../data/property.data';

const PROPERTY_INITIAL_STATE = INITIAL_PROPERTY();

type IPropertyState = typeof PROPERTY_INITIAL_STATE;

export const setPropertyData = (data: any) => {
  sessionStorage.setItem('inventoryOfAcitizensProperty', JSON.stringify(data));
  return typedAction('property/SET_DATA', data);
};

export const setPropertyItemValue = ({
  state,
  section,
  key,
  index,
  name,
  value,
}: {
  state: IPropertyState;
  section: string;
  key: string;
  index: number;
  name: string;
  value: any;
}) => {
  return (dispatch: Dispatch<AnyAction>) => {
    // const keyValue = state[section][key]
    // keyValue[index][name] = value
    // const newState = state
    // newState[section] = {
    // 	...state[section],
    // 	land: [
    // 		...keyValue
    // 	]
    // }
    const newState = state;
    newState[section]['land'][index][name] = value;
    console.log(newState);
    dispatch(
      setPropertyData({
        ...newState,
      })
    );
  };
};

type IPropertyAction = ReturnType<typeof setPropertyData>;

export const propertyReducer = (
  state = PROPERTY_INITIAL_STATE,
  action: IPropertyAction
): IPropertyState => {
  switch (action.type) {
    case 'property/SET_DATA':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
