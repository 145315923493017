import React, { Fragment } from 'react'

import Header from '../complex/Header'
import Footer from '../complex/Footer'


const MainLayout: React.FC = ({ children }) => {
	return (
		<Fragment>
			{/* <Head>
				<meta charSet="UTF-8" />
				<link rel="icon" href="/favicon.ico" />
				<title>Физбанкрот</title>

				<link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css?family=Ubuntu+Condensed&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet" />
			</Head> */}

			<Header />
			<main className="main">
				{children}
			</main>
			<Footer />
		</Fragment>
	)
}

export default MainLayout
