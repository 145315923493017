import React from 'react'

import { Modal } from 'components/common'
import { IModalProps } from 'components/common/Modal/interfaces'


const ChangePasswordModal: React.FC<IModalProps> = (props) => {
	return (
		<Modal {...props}>
			<div className="twosign" id="twosign">
				<span className="twosign__title title_27">Изменение пароля</span>

				<div className="password password_mt">
					<span className="password__text text_18_2">Старый пароль</span>
					<div className="password__inputBlock">
						<input type="password" className="password__input trz input" />
						<span className="password__show trz">Показать</span>
					</div>
				</div>
				<div className="password password_mt2">
					<span className="password__text text_18_2">Новый пароль</span>
					<div className="password__inputBlock">
						<input type="password" className="password__input trz input" />
						<span className="password__show trz">Показать</span>
					</div>
				</div>
				<div className="password__inputBlock password__inputBlock_generate">
					<div className="passwordGenerate">Сгенерировать пароль</div>
				</div>
				<div className="password password_mt2">
					<span className="password__text text_18_2">Новый пароль ещё раз</span>
					<div className="password__inputBlock">
						<input type="password" className="password__input trz input" />
						<span className="password__show trz">Показать</span>
					</div>
				</div>
				<div className="twoBtns">
					<button
						onClick={props.onCancel}
						className="button twoBtns__btn button_transparent"
					>Отмена</button>
					<button className="button twoBtns__btn noactive button_blue">Изменить</button>
				</div>
			</div>
		</Modal>
	)
}

export default ChangePasswordModal
