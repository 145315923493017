import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getInitialClientData } from "data/clientData.data";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import { APP_PATHS } from "data/paths.data";
import { IClientData } from "interfaces/clientData.interfaces";
import { getSubjects, insertDocumentService } from "services/docs/docs.service";
import { AddressSuggestions } from "react-dadata";
import { REACT_DADATA_TOKEN } from "config/env";
import { ApplicationButton } from "components/common/ApplicationButton";

import "react-dadata/dist/react-dadata.css";

const ClientDataPage: React.FC = () => {
  const history = useHistory();

  const store = localStorage;

  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [data, setData] = useState<IClientData>(() => {
    const storeData =
      store.getItem("userData") && JSON.parse(store.getItem("userData"));
    return { ...getInitialClientData(), ...storeData,  };
  });

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getSubjects();
      setData({
        ...data,
        subject: data.subject ? data.subject : res.data[0].subject,
      })
      setSubjects(res.data);
    };

    fetchData();
  }, []);

  const suggestionsRef = useRef<AddressSuggestions>(null);

  const showCity = (e: any) => {
    setData({
      ...data,
      city: e["data"]["city"],
      street: e["data"]["street"],
      region: e["data"]["city_district"],
      homeNumber: e["data"]["house"],
      apartmentNumber: e["data"]["flat"],
      locality: e["data"]["settlement"],
      corpusNumber: e["data"]["block"],
    });
  };

  useEffect(() => {
    sessionStorage.setItem("debtorsQuestionnaire", JSON.stringify(data));
    store.setItem("userData", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    sessionStorage.setItem("debtorsQuestionnaire", JSON.stringify(data));
    store.setItem("userData", JSON.stringify(data));
  }, [subjects.length]);

  const handleChange = (e: any) => {
    const value = e.target.value;

    if (e.target.name === "documentSerial") {
      if (/^\d{0,4}\s{0,1}\d{0,6}$/.test(value)) {
        const newValue = value.length > 4
          ? value
            .replace(/\s/g, "")
            .match(/[\d\s]{0,4}/g)
            .reduce(((res, item, index) => !index ? res + `${item} ` : res + item),
              "")
          : value;

        setData({
          ...data,
          [e.target.name]: newValue,
        });
      }
    } else {
      setData({
        ...data,
        [e.target.name]: value
      });
    }
  };

  const handleDashed = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
        ? e.target.value
            .replace(/-/g, "")
            .match(/.{1,3}/g)
            .join("-")
        : "",
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const { snils } = data;

    const resQuestionnaire = await insertDocumentService(
      "debtorsQuestionnaire",
      {
        ...data,
        snils: snils.replace(/-/g, ""),
      }
    );

    setIsLoading(false);

    if (!resQuestionnaire.success) {
      return setErrorMessage(
        resQuestionnaire.message ||
          "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.PROPERTY);
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={
          (e) => e.key === 'Enter' && e.preventDefault()
        }
      >
        <div className='application__form application__form_p'>
          <ul className='decorContent__list'>
            <li className='decorContent__item'>
              <div className='decorContent__lines'>
                <div className='decorContent__linesCircle2'>1</div>
              </div>
              <div className='decorContent__texts'>
                <span className='text_18 block-title'>
                  Введите личные данные
                </span>

                <div className='applicationForms__inputsList'>
                  <div className='applicationForms__inputs '>
                    <span className='applicationForms__value text_12'>
                      Фамилия
                    </span>
                    <input
                        type='text'
                        name='lastName'
                        value={data.lastName}
                        onChange={handleChange}
                        className='applicationForms__input'
                        required
                    />
                  </div>
                  <div className='applicationForms__inputs '>
                    <span className='applicationForms__value text_12'>
                      Имя
                    </span>
                    <input
                      type='text'
                      name='firstName'
                      value={data.firstName}
                      onChange={handleChange}
                      className='applicationForms__input'
                      required
                    />
                  </div>
                  <div className='applicationForms__inputs '>
                    <span className='applicationForms__value text_12'>
                      Отчество
                    </span>
                    <input
                      type='text'
                      name='middleName'
                      value={data.middleName}
                      onChange={handleChange}
                      className='applicationForms__input'
                    />
                    <span className='applicationForms__abs text_12'>
                      необязательно
                    </span>
                  </div>
                  <div className='applicationForms__inputs_line mt_40'>
                    <span className='applicationForms__inputs_description text_14_17'>
                      В случае изменения фамилии, имени, отчества указать
                      прежние фамилии, имена, отчества
                    </span>
                    <div className='applicationForms__inputs applicationForms__inputs_mt8 applicationForms__inputs_dis'>
                      <span className='applicationForms__value text_12'>
                        Имя
                      </span>
                      <input
                        type='text'
                        name='changedFirstName'
                        value={data.changedFirstName || ""}
                        onChange={handleChange}
                        className='applicationForms__input applicationForms__input_dis'
                      />
                    </div>
                    <div className='applicationForms__inputs applicationForms__inputs_mt8 applicationForms__inputs_dis'>
                      <span className='applicationForms__value text_12'>
                        Фамилия
                      </span>
                      <input
                        type='text'
                        name='changedLastName'
                        value={data.changedLastName || ""}
                        onChange={handleChange}
                        className='applicationForms__input applicationForms__input_dis'
                      />
                    </div>
                    <div className='applicationForms__inputs applicationForms__inputs_mt8 applicationForms__inputs_dis'>
                      <span className='applicationForms__value text_12'>
                        Отчество
                      </span>
                      <input
                        type='text'
                        name='changedMiddleName'
                        value={data.changedMiddleName || ""}
                        onChange={handleChange}
                        className='applicationForms__input applicationForms__input_dis'
                      />
                    </div>
                  </div>
                  <div className='applicationForms__inputs_line'>
                    <div className='applicationForms__inputs '>
                      <span className='applicationForms__value text_12'>
                        Дата рождения
                      </span>
                      <input
                        type='date'
                        name='dateOfBirth'
                        value={data.dateOfBirth}
                        onChange={handleChange}
                        className='applicationForms__input'
                        id='birth'
                        required
                      />
                    </div>
                  </div>
                  <div className='applicationForms__inputs_line'>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15 applicationForms__inputs_big2'>
                      <span className='applicationForms__value text_12'>
                        Место рождения
                      </span>
                      <input
                        type='text'
                        name='placeOfBirth'
                        value={data.placeOfBirth}
                        onChange={handleChange}
                        className='applicationForms__input '
                        required
                      />
                    </div>
                  </div>
                  <div className='applicationForms__inputs_line'>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                      <span className='applicationForms__value text_12'>
                        СНИЛС
                      </span>
                      <input
                        type='text'
                        name='snils'
                        value={data.snils}
                        onChange={handleDashed}
                        className='applicationForms__input applicationForms__input_snils'
                        minLength={14}
                        maxLength={14}
                        required
                      />
                    </div>
                  </div>
                  <div className='applicationForms__inputs_line'>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                      <span className='applicationForms__value text_12'>
                        ИНН
                      </span>
                      <input
                        type='text'
                        name='inn'
                        value={data.inn}
                        onChange={handleChange}
                        className='applicationForms__input applicationForms__input_snils'
                        minLength={12}
                        maxLength={12}
                        required
                      />
                    </div>
                  </div>
                  <div className='applicationForms__inputs_line mt_20'>
                    <span className='applicationForms__inputs_description text_18_21'>
                      Документ, удостоверяющий личность
                    </span>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                      <span className='applicationForms__value text_12'>
                        Вид документа
                      </span>
                      <input
                        type='text'
                        name='documentType'
                        value={data.documentType}
                        onChange={handleChange}
                        className='applicationForms__input'
                        placeholder='Паспорт'
                        disabled
                        required
                      />
                    </div>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15'>
                      <span className='applicationForms__value text_12'>
                        Серия (при наличии) и номер
                      </span>
                      <input
                        type='text'
                        name='documentSerial'
                        value={data.documentSerial}
                        onChange={handleChange}
                        className='applicationForms__input applicationForms__input_ser'
                        pattern='[0-9 ]+'
                        minLength={5}
                        maxLength={11}
                        required
                      />
                    </div>
                  </div>

                  <div className='applicationForms__inputs_line mt_20'>
                    <span className='applicationForms__inputs_description text_18_21'>
                      Адрес регистрации по месту жительства в Российской
                      Федерации*
                    </span>

                    <div className='applicationForms__inputs applicationForms__inputs_mt15 applicationForms__inputs_big2'>
                      <span className='applicationForms__value text_12'>
                        Место проживания
                      </span>
                      <span className='text_12 ml-5'>
                        <span className='redStar'>*</span>
                      </span>
                      <AddressSuggestions
                        ref={suggestionsRef}
                        token={REACT_DADATA_TOKEN}
                        delay={500}
                        inputProps={{ className: "applicationForms__input" }}
                        onChange={showCity}
                      />
                    </div>
                    <div className='applicationForms__inputs applicationForms__inputs_mt15 applicationForms__inputs_big2'>
                      <span className='applicationForms__value text_12'>
                        Субъект Российской Федерации
                      </span>
                      <select
                        value={data.subject}
                        onChange={handleChange}
                        name='subject'
                        className='applicationForms__input '
                      >
                        {subjects.map((subject) => (
                          <option value={subject.subject}>
                            {subject.subject}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <p className='applicationForms__inputs_big2 applicationForms__inputs_text text_14_17'>
                    * При отсутствии регистрации по месту жительства в пределах
                    Российской Федерации указать наименование субъекта
                    Российской Федерации без указания конкретного адреса:
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className='applicationForms__inputs_line mt_20'>
            <div className='checkbox checkbox_ml'>
              <input
                type='checkbox'
                className='check'
                id='check'
                onClick={() => setIsAgreed(!isAgreed)}
              />
              <label className='checkbox__text text_14' htmlFor='check'>
                <span className='checkbox__textInner'>
                  Достоверность и полноту настоящих сведений подтверждаю
                </span>
              </label>
            </div>
          </div>
          {errorMessage && (
            <span
              style={{
                display: "block",
                color: "#FF4040",
                marginTop: "20px",
              }}
            >
              {errorMessage}
            </span>
          )}

          {/* <div className="applicationTextsBl">
						<span className="application__about dtext_18" style={{ fontSize: '25px' }}>Укажите ваши персональные данные</span>
						<span className="application__or dtext_18" style={{ fontSize: '25px' }}>или</span>
						<div className="applicationTextsBl__input input__wrapper">
							<input name="file" type="file" id="input__file" className="inputfile" multiple />
							<label htmlFor="input__file" className="inputfile__label dtext_18">
								<span className="inputfile__text" style={{ fontSize: '25px' }}>Загрузите сканированное изображение</span>
							</label>
						</div>
					</div> */}
        </div>

        <ApplicationButton disabled={!isAgreed} isLoading={isLoading}>
          Заполнить данные. Шаг 2
        </ApplicationButton>

        {/* <input type="button" className='button applicationBtn' onClick={showCity} value="Step 2"></input> */}
      </form>
    </ApplicationWrapper>
  );
};

export default ClientDataPage;
