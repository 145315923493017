import {
  ICreditorOrDebtor,
  ObligationType,
  PaymentType,
} from "interfaces/creditors.interface";

export const INIT_OBLIGATION_ITEM: ObligationType = {
  type: "",
  creditorOrDebtor: "",
  location: "",
  origin: "",
  total: "",
  indebtedness: "",
  fine: "",
};

export const INIT_PAYMENT_ITEM: PaymentType = {
  name: "",
  arrears: "",
  fine: "",
};

export const INIT_CREDITOR_OR_DEBTOR_ITEM: ICreditorOrDebtor = {
  obligations: [{...INIT_OBLIGATION_ITEM}],
  nonMoneyObligations: '',
  payments: [{...INIT_PAYMENT_ITEM}],
};

export const CREDITORS_TYPES = {
  creditors: {
    title: "I. Сведения о Ваших ДОЛГАХ, НЕ СВЯЗАННЫХ с предпринимательской деятельностью",
    description:
      "(по денежным обязательствам и (или) обязанности по уплате обязательных платежей, <b>НЕ СВЯЗАННЫХ</b> с предпринимательской деятельностью)",
  },
  creditorsBusiness: {
    title: "II. Сведения о Ваших ДОЛГАХ, СВЯЗАННЫХ с предпринимательской деятельностью",
    description: `(по денежным обязательствам и (или) обязанности по уплате обязательных платежей, <b>СВЯЗАННЫХ</b> с предпринимательской деятельностью)`,
  },
  debtors: {
    title: "III. Сведения о Ваших ДОЛЖНИКАХ по долгам, НЕ СВЯЗАННЫМ с предпринимательской деятельностью",
    description:
      "(по денежным обязательствам и (или) обязанности по уплате обязательных платежей, <b>НЕ СВЯЗАННЫХ</b> с предпринимательской деятельностью)",
  },
  debtorsBusiness: {
    title: "IV. Сведения о Ваших ДОЛЖНИКАХ по долгам, СВЯЗАННЫМ с предпринимательской деятельностью \n",
    description: `(по денежным обязательствам и (или) обязанности по уплате обязательных платежей, <b>СВЯЗАННЫХ</b> с предпринимательской деятельностью)`,
  },
};

export const getInitialCreditors = () => {
  const dataFromLS = localStorage.getItem("creditorsData");
  if (dataFromLS) return JSON.parse(dataFromLS);

  const result = {};

  Object.keys(CREDITORS_TYPES).forEach((key) => {
    result[key] = JSON.parse(JSON.stringify(INIT_CREDITOR_OR_DEBTOR_ITEM));
  });

  return result;
};
