import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cleanDeep from "clean-deep";
import ApplicationWrapper from "components/other/ApplicationWrapper";
import { INITIAL_DEAL_ITEM, DEAL_TYPES } from "data/deals.data";
import { APP_PATHS } from "data/paths.data";
import { getMonthRus } from "utils/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { setDeals } from "store/modules/Application/deals.module";
import {
  getAvtokodQuery,
  getDadataQuery,
  getEgrnQuery,
  getGibddQuery,
  insertDocumentService,
} from "services/docs/docs.service";
import { REACT_DADATA_TOKEN } from "../../config/env";
import { AddressSuggestions } from "react-dadata";
import { Input } from "antd";
import { ApplicationButton } from "components/common/ApplicationButton";
import { IRootState } from "../../store";
import { QuestionTooltip } from "../../components/common/Tooltip";

const DealsPage: React.FC = () => {
  const history = useHistory();

  const deals = useSelector((state: IRootState) => state.deals);
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [transportSearchValue, setTransportSearchValue] = useState("");
  const [immovableSearchValue, setImmovableSearchValue] = useState<any>();
  const [isKadastrSearch, setIsKadastrSearch] = useState(false);
  const [isVinSearch, setIsVinSearch] = useState(false);

  const date = new Date();

  const apiegrnQuery = async () => {
    const lastIndex = deals["immovableProperty"].length - 1;
    deals["immovableProperty"][lastIndex]["name"] = `Загрузка...`;
    dispatch(setDeals({ ...deals }));

    const res = await getEgrnQuery(immovableSearchValue);

    if (res.data.error.code) {
      setImmovableSearchValue(`Error service: ${res.data.error.mess}`);
      return;
    }

    const location = res.data["EGRN"]["object"]["ADDRESS"];
    const space = res.data["EGRN"]["object"]["AREA"];
    const type = res.data["EGRN"]["object"]["TYPE"];

    deals["immovableProperty"][lastIndex][
      "name"
    ] = `${location}, ${type}, ${space}`;

    dispatch(setDeals({ ...deals }));
  };

  const dadataQuery = async () => {
    const lastIndex = deals["immovableProperty"].length - 1;
    deals["immovableProperty"][lastIndex]["name"] = `Загрузка...`;
    dispatch(setDeals({ ...deals }));

    const res = await getDadataQuery(immovableSearchValue);
    const location = res.data[0].result || "";
    const space = res.data[0].flat_area || "-";
    const type = res.data[0].flat_type_full || "-";
    deals["immovableProperty"][lastIndex][
      "name"
    ] = `${location}, ${type}, ${space}`;
    dispatch(setDeals({ ...deals }));
  };

  const gibddQuery = async () => {
    const lastIndex = deals["transport"].length - 1;
    deals["transport"][lastIndex]["name"] = "Загрузка...";
    dispatch(setDeals({ ...deals }));

    const res = await getGibddQuery(transportSearchValue);
    deals["transport"][lastIndex]["name"] = `Автомобиль ${
      res.data.RequestResult?.vehicle.model || ""
    }, ${res.data.RequestResult?.vehicle.year || ""} г.в., цвет ${
      res.data.RequestResult?.vehicle.color || ""
    }, ПТС ${res.data.RequestResult?.vehiclePassport.number || ""}`;
    dispatch(setDeals({ ...deals }));
  };

  const avtokodQuery = async () => {
    const lastIndex = deals["transport"].length - 1;
    deals["transport"][lastIndex]["name"] = "Загрузка...";
    dispatch(setDeals({ ...deals }));

    const res = await getAvtokodQuery(transportSearchValue);

    deals["transport"][lastIndex]["name"] = `${
      res.data?.data?.category || "Автомобиль"
    } ${res.data?.data?.car_mark || ""} ${res.data?.data?.car_model || ""}, ${
      res.data?.data?.manufacturing_year || ""
    } г.в., ПТС ${res.data?.data?.number_plate || ""}`;

    dispatch(setDeals({ ...deals }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {};

    Object.keys(deals).forEach((deal) => (data[deal] = cleanDeep(deals[deal])));

    const res = await insertDocumentService("listOfTransactions", data);

    setIsLoading(false);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    history.push(APP_PATHS.CREDITORS);
  };

  const handleChange = (e: any, key, i) => {
    deals[key][i][e.target.name] = e.target.value;

    if (i + 1 === deals[key].length) {
      deals[key].push(INITIAL_DEAL_ITEM);
    }

    dispatch(setDeals({ ...deals }));
  };

  const removeDeal = (e: any, key, i) => {
    e.preventDefault();

    if (deals[key].length > 1) {
      deals[key].pop();
    }

    dispatch(setDeals({ ...deals }));
  };

  return (
    <ApplicationWrapper>
      <form
        onSubmit={handleSubmit}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <div className='application__form application__form_p'>
          <ul className='decorContent__list'>
            <li className='decorContent__item'>
              <div className='decorContent__lines'>
                <div className='decorContent__linesCircle2'>1</div>
              </div>
              <div className='decorContent__texts'>
                <span className='text_18 block-title'>Введите данные</span>
              </div>
            </li>
          </ul>

          <br />

          <div className='applicationForms__inputsList'>
            {Object.keys(deals).map((key, i) => {
              return (
                <div key={i} className='mt_20'>
                  <span className='applicationForms__inputs_description text_18_21'>
                    {DEAL_TYPES[key].title}
                  </span>
                  {key === "immovableProperty" && !isKadastrSearch && (
                    <div
                      className='mt_10'
                      style={{ display: "flex", marginLeft: "65px" }}
                    >
                      <AddressSuggestions
                        value={immovableSearchValue}
                        token={REACT_DADATA_TOKEN}
                        inputProps={{
                          type: "text",
                          placeholder: "Введите адрес",
                        }}
                        delay={500}
                        onChange={(e) => setImmovableSearchValue(e.value)}
                      />
                      <input
                        type='button'
                        className='button'
                        style={{
                          width: "70px",
                          backgroundColor: "#0075DB",
                        }}
                        onClick={(e) => dadataQuery()}
                        value='Поиск'
                      />
                    </div>
                  )}
                  {key === "immovableProperty" && isKadastrSearch && (
                    <div
                      className='mt_10'
                      style={{
                        display: "flex",
                      }}
                    >
                      <input
                        type='text'
                        className='formLine__input'
                        style={{
                          width: "25%",
                          marginLeft: "65px",
                        }}
                        placeholder='Введите кадастровый номер'
                        value={immovableSearchValue}
                        onChange={(e) => {
                          setImmovableSearchValue(e.target.value);
                        }}
                      />
                      <input
                        type='button'
                        className='button'
                        style={{
                          width: "70px",
                          backgroundColor: "#0075DB",
                        }}
                        onClick={(e) => apiegrnQuery()}
                        value='Поиск'
                      />
                    </div>
                  )}
                  {key === "immovableProperty" && (
                    <div
                      className='mb_20 mt_5'
                      style={{
                        display: "flex",
                        marginLeft: "65px",
                      }}
                    >
                      <label className='text_12_14'>
                        <input
                          type='checkbox'
                          key={key}
                          checked={isKadastrSearch}
                          onChange={(e) => {
                            setIsKadastrSearch((prevState) => !prevState);
                            setImmovableSearchValue("");
                          }}
                        />
                        <span className='ml_5'>Объект не найден по адресу</span>
                      </label>
                    </div>
                  )}
                  {key === "transport" && (
                    <div className='mt_10' style={{ display: "flex" }}>
                      <input
                        type='text'
                        className='formLine__input'
                        style={{
                          width: "25%",
                          marginLeft: "65px",
                        }}
                        placeholder={
                          isVinSearch
                            ? "Введите vin номер"
                            : "Введите гос номер"
                        }
                        value={transportSearchValue}
                        onChange={(e) =>
                          setTransportSearchValue(e.target.value)
                        }
                      />
                      <input
                        type='button'
                        className='button'
                        style={{
                          width: "70px",
                          backgroundColor: "#0075DB",
                        }}
                        onClick={(e) =>
                          isVinSearch ? gibddQuery() : avtokodQuery()
                        }
                        value='Поиск'
                      />
                    </div>
                  )}
                  {key === "transport" && (
                    <div
                      className='mb_20 mt_5'
                      style={{
                        display: "flex",
                        marginLeft: "65px",
                      }}
                    >
                      <label className='text_12_14'>
                        <input
                          type='checkbox'
                          key={key}
                          checked={isVinSearch}
                          onChange={() => {
                            setIsVinSearch((prevState) => !prevState);
                            setTransportSearchValue("");
                          }}
                        />
                        <span className='ml_5'>
                          Объект не найден по гос номеру
                        </span>
                      </label>
                    </div>
                  )}

                  {deals[key].map((item: any, j: number) => (
                    <div key={j} className='applicationForms__inputs_line'>
                      <div className='applicationForms__inputs applicationForms__inputs_mt8 applicationForms__inputs_little3'>
                        <span className='applicationForms__value text_12'>
                          № п/п
                        </span>
                        <input
                          type='text'
                          name='id'
                          className='applicationForms__input'
                          placeholder={`${i + 1}.${j + 1}`}
                          value={`${i + 1}.${j + 1}`}
                          disabled
                        />
                      </div>
                      {key !== "over300000" && (
                        <div className='applicationForms__inputs applicationForms__inputs_mt8'>
                          <span className='applicationForms__value text_12'>
                            {DEAL_TYPES[key].nameTitle}
                          </span>
                          <Input.TextArea
                            name='name'
                            className='applicationForms__input'
                            placeholder=''
                            value={deals[key][j].name}
                            onChange={(e) => handleChange(e, key, j)}
                            rows={1}
                            autoSize
                          />
                        </div>
                      )}
                      <div className='applicationForms__inputs applicationForms__inputs_mt8'>
                        <span className='applicationForms__value text_12'>
                          Реквизиты сделки
                        </span>
                        <span className='text_12'>
                          {i === 0 && j === 0 && (
                            <QuestionTooltip text='Пример: Договор купли продажи от 01.01.2001' />
                          )}
                        </span>
                        <Input.TextArea
                          name='requisites'
                          className='applicationForms__input'
                          value={deals[key][j].requisites}
                          onChange={(e) => handleChange(e, key, j)}
                          rows={1}
                          autoSize
                        />
                      </div>
                      <div className='applicationForms__inputs applicationForms__inputs_mt8'>
                        <span className='applicationForms__value text_12'>
                          Условия сделки
                        </span>
                        <span className='text_12'>
                          {i === 0 && j === 0 && (
                            <QuestionTooltip text='Пример: Продажа объекта по цене 1000000 рублей' />
                          )}
                        </span>
                        <Input.TextArea
                          name='terms'
                          className='applicationForms__input'
                          value={deals[key][j].terms}
                          onChange={(e) => handleChange(e, key, j)}
                          rows={1}
                          autoSize
                        />
                      </div>
                    </div>
                  ))}
                  <div>
                    {deals[key].length > 1 && (
                      <button
                        className='formLine__deals'
                        onClick={(e) => removeDeal(e, key, i)}
                      >
                        <img
                          src='img/minus.svg'
                          alt='minus'
                          className='svg formLine__addIcon'
                        />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}

            <div className='applicationForms__inputs_line mt_20'>
              <div className='checkbox checkbox_ml'>
                <input
                  type='checkbox'
                  className='check'
                  id='check'
                  onClick={() => setIsAgreed(!isAgreed)}
                />
                <label className='checkbox__text text_14' htmlFor='check'>
                  <span className='checkbox__textInner'>
                    Достоверность и полноту настоящих сведений подтверждаю
                  </span>
                </label>
              </div>
            </div>
            <div className='applicationForms__inputs_line mt_40 ml_40 text_18_21'>
              «{date.getDate()}» {getMonthRus(date)} {date.getFullYear()} г.
            </div>
          </div>

          {errorMessage && (
            <span
              style={{
                display: "block",
                color: "#FF4040",
                marginTop: "20px",
              }}
            >
              {errorMessage}
            </span>
          )}
        </div>

        <ApplicationButton disabled={!isAgreed} isLoading={isLoading}>
          Заполнить данные. Шаг 4
        </ApplicationButton>
      </form>
    </ApplicationWrapper>
  );
};

export default DealsPage;
