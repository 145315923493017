import { logout } from 'store/modules/Auth/actions';
import { STRINGS } from 'data/strings.data';

export const logoutService = (callback?: () => void) => {
  sessionStorage.removeItem(STRINGS.AUTH_TOKEN);
  localStorage.removeItem(STRINGS.AUTH_TOKEN);

  logout();

  if (callback) callback();
};
