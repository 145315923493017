import { typedAction } from '../../helpers';
import { getInitialDeals } from '../../../data/deals.data';

const INITIAL_DEALS = getInitialDeals();

type IDealsState = typeof INITIAL_DEALS;

export const setDeals = (data: any) => {
  localStorage.setItem('dealsData', JSON.stringify(data));
  return typedAction(
    'deals/SET_DATA',
    JSON.parse(localStorage.getItem('dealsData'))
  );
};

type IDealsAction = ReturnType<typeof setDeals>;

export const dealsReducer = (
  state = getInitialDeals(),
  action: IDealsAction
): IDealsState => {
  switch (action.type) {
    case 'deals/SET_DATA':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
