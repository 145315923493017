import React, { useEffect } from "react";
import { useState } from "react";
import { BreadCrumbs } from "components/common";
import { APP_PATHS } from "data/paths.data";
import { PAGE_TITLES } from "data/strings.data";
import { getDownloadsDocumentService } from "services/docs/docs.service";
import JSZip from "jszip";
import { fetchConfirmationToken } from "../../services/payment/fetchConfirmationToken.service";
import { v4 as uuidv4 } from "uuid";
import { fetchAuthUserService } from "../../services/auth/fetchAuthUser.service";
import {useDispatch, useSelector} from "react-redux";
import LoadingOverlay from "react-loading-overlay";
// import PDFViewer from 'pdf-viewer-reactjs'

import "./style.scss";
import {useHistory} from "react-router-dom";
import {IUser} from "../../interfaces/users.interfaces";
import {IRootState} from "../../store";

const ResultPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const isPaid = useSelector((state: IRootState) => state.auth.user.isPaid);

  const dispatch = useDispatch();

  // for payment status update
  useEffect(() => {
    if ( isPaid ) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      const { success, confirmationToken } = await fetchConfirmationToken(
          uuidv4()
      );
      setLoading(false);

      if (!success) {
        return;
      }

      if (!confirmationToken) {
        setLoading(true);
        const user = await fetchAuthUserService()(dispatch) as IUser;
        if (!user?.isPaid) {
          history.push(APP_PATHS.HOME)
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleDownload = async () => {
    setLoading(true);

    const res = await getDownloadsDocumentService();

    if (!res.success) {
      console.log("Паника");
      return setLoading(false);
    }

    const { downloads } = res;

    const zip = new JSZip();

    const obj_pdf = {
      debtorsQuestionnaire: "Анкета Должника",
      inventoryOfAcitizensProperty: "Опись имущества гражданина",
      listOfCreditorsAndDebtors: "Список кредиторов и должников гражданина",
      listOfTransactions: "Список сделок должника",
      bancruptApplication: "Заявление о банкротстве",
    };

    Object.keys(downloads).forEach((kind) => {
      zip.file(`${obj_pdf[kind]}.pdf`, downloads[kind], { base64: true });
    });

    const data = await zip.generateAsync({ type: "base64" });

    const linkSource = `data:application/zip;base64,${data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `Документы`;
    downloadLink.click();

    setLoading(false);
  };

  return (
      <LoadingOverlay active={loading} spinner>
        <>
          <BreadCrumbs
            links={[{ title: PAGE_TITLES.RESULT, path: APP_PATHS.RESULT }]}
          />

          <div className='result-page'>
            <div className='content-left'>
              <div className='docs-list'>
                <div className='block'>
                  <div className='block-title'>Результат</div>
                </div>
              </div>

              <button
                // className='decorAdv_btn button little download primary'
                className='button last_step applicationBtn'
                disabled={loading || !isPaid}
                style={
                  loading || !isPaid
                    ? { opacity: 0.8, background: "#559634", cursor: "auto" }
                    : {}
                }
                onClick={handleDownload}
              >
                Скачать полный список документов
              </button>
              <button
                // className='decorAdv_btn button little download primary'
                className='button last_step applicationBtn'
                onClick={() => history.push(APP_PATHS.DOCS_CHECKLIST)}
              >
                Открыть чеклист необходимых приложений к заявлению
              </button>
            </div>
          </div>
        </>
      </LoadingOverlay>
  );
};

export default ResultPage;
