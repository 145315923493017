import './style.scss';

import React, { Fragment, useState, ReactNode } from 'react';

import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { BreadCrumbs } from 'components/common';






const FAQItem: React.FC<{index: any}> = ({children, index}) => {
	const [isOpen, setIsOpen] = useState(false);
	const questionObject = [
		{
			"question": "КАК ПРОИСХОДИТ БАНКРОТСТВО ФИЗИЧЕСКИХ ЛИЦ?",
			"answer": "1.  Сбор документов на банкротство физлица; \n\n 2.  Составление заявления на банкротство; \n\n 3.  Оплата госпошлины и депозита на счёт арбитражного суда; \n\n 4.  Подача заявления в суд с пакетом необходимых документов; \n\n 5.  Судебное заседание по рассмотрению заявления; \n\n 6.  Непосредственно сама процедура банкротства, в которой ключевую роль играет специальная фигура - финансовый управляющий; \n\n 7.  Завершение процедуры банкротства."
		},
		{
			"question": "СКОЛЬКО ДЛИТСЯ ПРОЦЕДУРА БАНКРОТСТВА ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "Обычно, если гражданин не имеет имущества и существенного дохода, процедура банкротства физического лица для него длится около 7-8 месяцев с момента подачи заявления на банкротство. \n Точные сроки процедуры банкротства на 100% предугадать невозможно, т.к. есть множество факторов, влияющих на них: \n\n Загруженность суда и конкретного судьи, к которому попадет заявление о банкротстве; \n\n Полнота заявления и прилагаемых к нему документов. Не всегда получается собрать полный пакет документов. Отсутствие какого-либо документа может послужить оставлению заявления без движения; \n\n Нерасторопность или чрезмерная активность кредиторов."
		},
		{
			"question": "ЧТО НУЖНО ДЛЯ БАНКРОТСТВА ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "В первую очередь нужно понять подходит ли банкротство Вам, а также какие расходы и жизненные ограничения Вас ожидают в процессе банкротства физического лица. Затем, взвесив все плюсы и минусы, можно переходить к сбору необходимых документов и поиску финансового управляющего."
		},
		{
			"question": "СКОЛЬКО СТОИТ БАНКРОТСТВО ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "Это «больной вопрос» для людей, задумывающихся о банкротстве. Ведь потенциальные банкроты – люди, которые сильно стеснены в финансах. Процедура банкротства физического лица, к сожалению, не бесплатна. Только при подаче заявления на банкротство гражданину необходимо оплатить на счет арбитражного суда 25 300 рублей, из которых госпошлина - 300 рублей и депозит на вознаграждение финансового управляющего - 25 000 рублей. Плюс предстоит еще ряд обязательных трат в самой процедуре."
		},
		{
			"question": "КАК ПОДАТЬ ЗАЯВЛЕНИЕ НА БАНКРОТСТВО ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "Заявление о банкротстве физического лица подается в арбитражный суд по месту постоянной регистрации (прописки) одним из трех способов: \n\n 1.  Через почту России \n\n 2.  Через канцелярию суда \n\n3.  Через Интернет посредством электронного сервиса «Мой арбитр»."
		},
		{
			"question": "ВОЗМОЖНО ЛИ БАНКРОТСТВО ФИЗИЧЕСКОГО ЛИЦА БЕЗ ФИНАНСОВОГО УПРАВЛЯЮЩЕГО?",
			"answer": "В настоящее время банкротство физического лица без финансового управляющего возможно при сумме долгов менее 500 тысяч рублей. Если же сумма долгов более 500 000 рублей, то банкротство происходит в судебном порядке с обязательным участием финансового управляющего."
		},
		{
			"question": "ЗАКРЫВАЮТ ЛИ ВЫЕЗД ЗА ГРАНИЦУ ПОСЛЕ БАНКРОТСТВА ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "После завершения процедуры банкротства физического лица никаких запретов на выезд за границу нет! Запрет на выезд, который мог быть вынесен судебным приставом до банкротства в ходе исполнительного производства, снимается уже в начале процедуры банкротства."
		},
		{
			"question": "КАКИЕ СДЕЛКИ МОГУТ ОСПОРИТЬ ПРИ БАНКРОТСТВЕ?",
			"answer": "В процедуре банкротства физического лица могут быть оспорены: \n\n *  договора купли-продажи, дарения имущества; \n\n *  фиктивные договора займа, залога; \n\n*  отказ от наследства; \n\n*  брачные договора и соглашения о разделе имущества; \n\n*  алиментные соглашения; \n\n*  мировые соглашения, утвержденные судом; \n\n*  выход из учредителей ООО; \n\n*  преимущественное погашение долга отдельному кредитору; \n\n*  а также иные сделки, которые причинили вред кредиторам. \n\n  Указанные сделки оспариваются, если они были совершены в течение 3-х лет до банкротства. В отдельных случаях, могут оспариваться более ранние сделки, совершенные после 01 сентября 2010 года."
		},
		{
			"question": "ЗАЧЕМ КРЕДИТОРУ (БАНКУ) БАНКРОТИТЬ ДОЛЖНИКА?",
			"answer": "Банкротство физических лиц – это универсальный инструмент, который в умелых руках должника и его юристов позволяет освободиться от непосильных долгов; а в умелых руках кредитора позволяет более эффективно взыскивать задолженность. \n\n\n Кредиторы все чаще прибегают к банкротству должника-физического лица из-за более широких возможностей по розыску имущества, оспаривания сделок, а также для более быстрой реализации имущества финансовым управляющим."
		},
		{
			"question": "НЕ ПРИДЕТСЯ ЛИ ПЛАТИТЬ НАЛОГ (НДФЛ) ПРИ БАНКРОТСТВЕ ФИЗИЧЕСКОГО ЛИЦА?",
			"answer": "На сумму «прощенного» долга, а также на доход полученный от реализации имущества в процедуре банкротства налог на доходы физических лиц (НДФЛ) не начисляется."
		}
	];
	return (
		<li className={classNames('faq__item', { active: isOpen })}>
			<div className='faq__question usn' onClick={() => setIsOpen(!isOpen)}>
				{/* Как зарегистрироваться на портале{' '} */}
				{questionObject[index]["question"]}
				<img src='img/down.png' alt='' className='faq__down' />{' '}
			</div>
			<div style={{whiteSpace: 'pre-line', textAlign: 'left'}} className='faq__answer text_18_2'>
			{questionObject[index]["answer"]}
			</div>
			
		</li>
	);
};

const FAQPage: React.FC = () => {
	const history = useHistory();

	const navigateBack = (e: any) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<Fragment>
			<BreadCrumbs links={[{ title: 'Частые вопросы', path: '' }]} />

			<div className='faq wrapper'>
				<div className='faqInf'>
					<h2 className='null title_53'>Частые вопросы</h2>
					<button
						onClick={navigateBack}
						className='faq__link text_18_2 bold trz back_link'
					>
						<img src='img/back.png' className='back_link__icon' alt='' />
						Вернуться назад
					</button>
					{/* <span className="faq__titleReg null title_27 title">Регистрация</span> */}
					<ul className='faq__list'>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, i) => (
							<FAQItem index={i} />
							
						))}
						{/* <FAQItem/> */}
					</ul>
				</div>
			</div>
		</Fragment>
	);
};

export default FAQPage;
