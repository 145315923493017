import "./style.scss";

import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "store";
import { BreadCrumbs } from "components/common";
import { APP_PATHS } from "data/paths.data";
import { Document, Page, pdfjs } from "react-pdf";
import ReactWaterMark from "react-watermark-component";
import { API_SERVER_URL } from "config/env";
import { fetchConfirmationToken } from "services/payment/fetchConfirmationToken.service";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { downloadPdf } from "../../utils/download.utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PaymentPage: React.FC = () => {
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  const [checkout, setCheckout] = useState(null);

  const user = useSelector((state: IRootState) => state.auth.user);

  const isPaid = user.isPaid;

  useEffect(() => {
    const fetchData = async () => {
      const { confirmationToken } = await fetchConfirmationToken(uuidv4());

      setCheckout(
        new window["YooMoneyCheckoutWidget"]({
          confirmation_token: confirmationToken,
          return_url: `https://bankrotdoc.ru${APP_PATHS.RESULT}`,
          customization: {
            modal: true,
          },
          error_callback: () => {
            console.log("error");
          },
        })
      );
    };

    const script = document.createElement("script");

    script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
    script.onload = fetchData;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const documentOptions = {
    url: `${API_SERVER_URL}/pdf/${user.id}/bancruptApplication.pdf`,
    fileName: 'Заявление о банкротстве'
  }

  const options = {
    chunkWidth: 200,
    chunkHeight: 60,
    textAlign: "left",
    textBaseline: "bottom",
    globalAlpha: 0.6,
    font: "40px Microsoft Yahei",
    rotateAngle: -0.26,
    fillStyle: "#000",
  };

  return (
    <Fragment>
      <BreadCrumbs links={[{ title: "Оплата", path: "" }]} />

      <div className='payment payment_mt'>
        <span className='payment__title title_53'>
          {isPaid ? "Оплата прошла успешно!" : "Произведите оплату"}
        </span>
        {!isPaid && (
          <span className='payment__about text_18_2 bold'>
            Выберите удобный для Вас способ оплаты
          </span>
        )}
        <div className='payment__list'>
          <div
            style={{
              marginBottom: "30px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                height: "700px",
                overflowY: "scroll",
                cursor: isPaid ? "pointer" : "default",
                userSelect: "none",
              }}
              onClick={() => isPaid && downloadPdf(documentOptions)}
            >
              {!isPaid ? (
                <ReactWaterMark waterMarkText={"Образец"} options={options}>
                  <Document
                    file={{
                      url: `${API_SERVER_URL}/pdf/${user.id}/bancruptApplication.pdf`,
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {/* <Page pageNumber={pageNumber} /> */}
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => (
                        <Page pageNumber={page}>
                          {/* <View style={{  }}></View> */}
                        </Page>
                      ))}
                  </Document>
                </ReactWaterMark>
              ) : (
                <Document
                  file={{
                    url: documentOptions.url,
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {/* <Page pageNumber={pageNumber} /> */}
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page pageNumber={page}>
                        {/* <View style={{  }}></View> */}
                      </Page>
                    ))}
                </Document>
              )}
            </div>
          </div>

          {isPaid ? (
            <Link to={APP_PATHS.RESULT}>
              <button className='button button_secondary trz'>
                Скачать документ
              </button>
            </Link>
          ) : (
            <div
              className='payment__btn trz'
              onClick={() => checkout && checkout.render()}
            >
              <img src='img/visa.png' alt='' className='payment__icon' />
              <div className='payment__texts'>
                <span className='payment__title title_27'>
                  Банковская карта
                </span>
                <p className='null payment__text text_18_2'>
                  Мы принимаем к оплате карты Master Card, Visa, Мир
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentPage;
