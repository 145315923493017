import React from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageTitle, ProgressLine } from 'components/common';
import { APPLICATION_STEPS } from 'data/application.data';

const ApplicationWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const step =
    APPLICATION_STEPS[
      APPLICATION_STEPS.findIndex((el) => el.link === location.pathname)
    ];

  return (
    <>
      <BreadCrumbs
        links={[{ path: '', title: step.title }]}
        progressLine={<ProgressLine />}
      />

      <div className='application application_mt wrapper'>
        <PageTitle title={step.title} img={step.img} linkBack={step.previous} />
        {children}
      </div>
    </>
  );
};

export default ApplicationWrapper;
