import React, { useState } from "react";

type IProps = {
  key: number;
  handleCheckboxChange: any;
  label: string;
  isCheckedDefault: boolean;
};

export const Checkbox: React.FC<IProps> = ({
  key,
  handleCheckboxChange,
  label,
  isCheckedDefault = false,
}) => {
  const [isChecked, setIsChecked] = useState(isCheckedDefault);

  const toggleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);

    handleCheckboxChange(label);
  };

  return (
    <div className='mb_5' key={key}>
      <label>
        <input
          type='checkbox'
          key={key}
          checked={isChecked}
          onChange={toggleCheckboxChange}
          className='mr_5 clickable'
        />
        <span>{label}</span>
      </label>
    </div>
  );
};
