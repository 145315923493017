import axios from "axios";

export interface IDownloadPdfProps {
  url: string,
  fileName: string,
}

export interface IDownloadFromBase64Props {
  base64: string,
  fileName: string,
}

export const downloadPdf = async ({url, fileName} : IDownloadPdfProps) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
  });
}
