import axios from "axios";
import { API_SERVER_URL } from "config/env";
import { emailMutation } from "data/mutations.data";

export const emailService = async (data: {
  fullname?: string;
  phoneNumber: string;
  email: string;
}) => {
  try {
    const res = await axios.post(API_SERVER_URL, {
      query: emailMutation,
      variables: data,
    });

    if (res.data?.errors) {
      return {
        success: false,
        message: "Ошибка сервера, повторите через несколько минут",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: "Ошибка сервера, повторите через несколько минут",
    };
  }

  return {
    success: true,
  };
};
