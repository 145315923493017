import classNames from "classnames";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IRootState } from "store";
import { APP_PATHS } from "data/paths.data";
import { logoutService } from "services/auth/logout.service";
import { Modal } from "components/common";
import { TelegramModal } from "components/other/TelegramModal";

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
});

type IProps = ReturnType<typeof mapStateToProps>;

const Header: React.FC<IProps> = ({ auth }) => {
  const [telegramModalIsVisible, setTelegramModalIsVisible] = useState(false);
  const [descModalIsVisible, setDescModalIsVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const NAV_LIST = useMemo(() => {
    const navList = [
      { name: "Услуги", path: APP_PATHS.HOME },
      { name: "О сервисе", path: APP_PATHS.ABOUT },
      { name: "Частые вопросы", path: APP_PATHS.FAQ },
      // { name: 'Отзывы и предложения', path: APP_PATHS.SUGGESTIONS },
    ];

    if (auth.user.isPaid)
      navList.push({ name: "Результат", path: APP_PATHS.RESULT });

    return navList;
  }, [auth.user.isPaid]);

  return (
    <>
      <header className='header'>
        <div className='wrapper__big header_wrapper'>
          <Link to={APP_PATHS.HOME} className='logo'>
            {/* <div className='logo_container'> */}
            БанкротДок
            {/* </div> */}
          </Link>
          <div
            className={classNames("header_abs trz", { active: menuIsVisible })}
          >
            <nav className='header__nav'>
              {auth.isAuthenticated && (
                <ul className='header__list'>
                  {NAV_LIST.map((item, i) => (
                    <li key={i} className='header__item'>
                      <Link to={item.path} className='header__link trz link'>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li className='header__item'>
                    <span
                      className='header__link clickable trz link'
                      onClick={() => setTelegramModalIsVisible(true)}
                    >
                      Помощь и поддержка
                    </span>
                  </li>
                </ul>
              )}
            </nav>
            <div className='user user_ml'>
              {auth.isAuthenticated && (
                <>
                  <Link to={APP_PATHS.SETTINGS}>
                    <img
                      src='img/account.svg'
                      alt=''
                      className='user__icon svg'
                    />
                  </Link>
                  <span className='user__text trz text_14 white'>
                    {auth.user.lastName}{" "}
                    {auth.user.firstName ?`${auth.user.firstName[0]}. ` : ""}
                    {auth.user.middleName && auth.user.middleName.length > 0
                      ? `${auth.user.middleName[0]}.`
                      : ""}
                  </span>
                  {/* <div className='map map_ml'>
                    <img src='img/pin.svg' alt='' className='svg map__icon' />
                    <span className='text white trz map__text'>г. Иркутск</span>
                  </div> */}
                </>
              )}
            </div>
          </div>
          <div className='login-btn'>
            {auth.isAuthenticated && auth.isVerified && (
              <span
                onClick={() => logoutService(() => window.location.reload())}
              >
                Выйти
              </span>
            )}
            {!auth.isAuthenticated && !auth.isVerified && (
              <Link to='/login'>
                <span>Регистрация/Войти</span>
              </Link>
            )}
          </div>
          <div
            className={classNames("menu_btn", { active: menuIsVisible })}
            onClick={(e) => {
              e.preventDefault();
              setMenuIsVisible(!menuIsVisible);
            }}
          >
            <span></span>
          </div>
        </div>
      </header>
      <Modal
        isVisible={descModalIsVisible}
        onCancel={() => setDescModalIsVisible(false)}
      >
        <div id='about' className='about'>
          <h1 className='title'>О сервисе Физ.Банкрот</h1>
          <p className='about__text null text_2'>
            В каталог государственных сайтов включены официальные информационные
            интернет-ресурсы, посвященные деятельности государсвенных ведомств
          </p>
          <p className='about__text null text_2'>
            Навигация в каталоге организована таким образом, чтобы вы могли
            быстро найти нужное ведомство и его контактную информацию,
            ознакомиться с предаставляемыми услугами или перейти на официальный
            сайт.
          </p>
          <h2 className='title about__title null'>
            <span className='red'>Физ.</span>
            <span className='blue'>Банкрот</span>
          </h2>
          <span className='about__ad text_2'>
            Теперь пользоваться госуслугами очень просто!
          </span>
          <button className='about_btn button trz'>Перейти на портал</button>
        </div>
      </Modal>
      <TelegramModal
        isVisible={telegramModalIsVisible}
        onCancel={() => setTelegramModalIsVisible(false)}
      />
    </>
  );
};

export default connect(mapStateToProps, null)(Header);
