import { useState } from "react";
import { Link } from "react-router-dom";
import { APP_PATHS } from "data/paths.data";
import { TelegramModal } from "components/other/TelegramModal";

const Footer: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <footer className="footer">
      {/* Верхний уровень */}
      <div className="footer__top">
        <ul className="footer__list null">
          <li className="footer__item">
            <span className="footer__link clickable text_11">
              Помощь и поддержка
            </span>
          </li>
          <li className="footer__item">
            <a
              href="https://bancrupt-backend.herokuapp.com/pdf/static/PrivacyPolicy.pdf"
              target="_blank"
              className="footer__link text_11"
              rel="noreferrer"
            >
              Политика конфиденциальности
            </a>
          </li>
          <li className="footer__item">
            <a
              href="https://bancrupt-backend.herokuapp.com/pdf/static/TermsOfUse.pdf"
              target="_blank"
              className="footer__link text_11"
              rel="noreferrer"
            >
              Условия использования
            </a>
          </li>
        </ul>
      </div>

      {/* Нижний уровень */}
      <div className="footer__bottom">
        <div className="footer__right-part">
          <div className="footer__socials">
            <a
              href="https://t.me/bankrot_doc_bot"
              target="_blank"
              className="footer__social-link"
            >
              <img src="/img/telegram-logo.png" alt="Telegram" />
            </a>
            <a
              href="whatsapp://send?phone=+79933822122"
              target="_blank"
              className="footer__social-link"
            >
              <img src="/img/whatsapp-logo.png" alt="WhatsApp" />
            </a>
            <a
              href="viber://chat?number=79933822122"
              target="_blank"
              className="footer__social-link"
            >
              <img src="/img/viber-logo.png" alt="Viber" />
            </a>
            {/* Добавьте другие иконки мессенджеров по необходимости */}
          </div>
          <span className="footer__phone-number">
            <a href="tel:+79933822122">8 993 382 21 22</a>
          </span>
        </div>

        <div className="footer__copyright">
          Правообладатель Индивидуальный предприниматель Бахрунов Максим
          Борисович, ИНН: 032314770500
        </div>
      </div>

      <div className={isVisible ? "modal visible" : "modal"}>
        <div className="modal__wrapper">
          <div
            className="modal__empty-space clickable"
            onClick={() => setIsVisible(false)}
          ></div>
          <div className="modal__content-wrap">
            <div className="telegram_modal">
              <span className="title_27 mb_20 text_center">
                Для общения в чат боте, Вам необходимо установить Telegram на
                компьютер, либо зайти на данную страницу с мобильного устройства,
                на котором установлен Telegram
              </span>
              <div className="modal_footer">
                <a href="https://telegram.org/" target="_blank" rel="noreferrer">
                  Установить Telegram
                </a>
                <a href="https://t.me/bankrot_doc_bot" target="_blank" rel="noreferrer">
                  Перейти в чат бот
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
