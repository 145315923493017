import { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false);
  const [prevLocation, setPrevLocation] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPrevLocation(location.pathname);
    setProgress(true);
    if (location.pathname === prevLocation) setPrevLocation("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLocation]);

  TopBarProgress.config({
    barColors: {
      "0": "#fff",
      "1.0": "#0075DB",
    },
    shadowColor: "#fff",
  });

  return (
    <>
      {progress && <TopBarProgress />}
      <Switch>{children}</Switch>
    </>
  );
};

export default CustomSwitch;
