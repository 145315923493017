import React, {useState} from "react";
import "./style.scss";
import { BreadCrumbs, Modal } from "components/common";
import { PAGE_TITLES } from "data/strings.data";
import {DOCS_LIST} from "../../data/documents.data";
import { APP_PATHS } from "data/paths.data";
import {CollectModal} from "../../components/other/CollectModal";
import {FinishModal} from "../../components/other/FinishModal";
import { downloadPdf } from "utils/download.utils";

const DocItem: React.FC<{ number: number; text: string }> = ({number, text,}) => {
  return (
    <li>
      <div className='decorContent__linesCircle2'>{number}</div>
      <span>{text}</span>
    </li>
  );
};

const DocsChecklistPage: React.FC = () => {
  const [isVisibleCollectModal, setIsVisibleCollectModal] = useState(false);
  const [isVisibleFinishModal, setIsVisibleFinishModal] = useState(false);

  return (
      <>
        <BreadCrumbs
          links={[{ title: PAGE_TITLES.DOCS_CHECKLIST, path: APP_PATHS.DOCS_CHECKLIST }]}
        />

        <div className='result-page'>
          <div className='content-left'>
            <div className='docs-list'>
              <div className='block'>
                <div className='block-title'>Список документов, прилагаемых к заявлению о банкротстве</div>
                <ul className='list-container'>
                  {DOCS_LIST.map((doc, i) => (
                    <DocItem key={i} number={i + 1} text={doc} />
                  ))}
                </ul>
              </div>
            </div>


              <button
                className='button last_step applicationBtn'
                onClick={() => downloadPdf({
                  url: `https://bancrupt-backend.herokuapp.com/pdf/static/DocsList.pdf`,
                  fileName: 'Список документов'
                })}
              >
                Скачать чеклист
              </button>
              <button
                className='button last_step applicationBtn'
                onClick={() => setIsVisibleCollectModal(true)}
              >
                Заказать услугу сбора документов
              </button>
              <button
                className='button last_step applicationBtn'
                onClick={() => setIsVisibleFinishModal(true)}
              >
                Завершить оформление заявления
              </button>

          </div>
        </div>
        <CollectModal
          isVisible={isVisibleCollectModal}
          onCancel={() => setIsVisibleCollectModal(false)}
        />
        <FinishModal
          isVisible={isVisibleFinishModal}
          onCancel={() => setIsVisibleFinishModal(false)}
        />
      </>
  );
};

export default DocsChecklistPage;