import { getInitialUser } from 'data/users.data';
import { IAuthState } from 'interfaces/auth.interfaces';
import { IAuthAction } from './actions';
import { AUTH_INITIAL_STATE } from './state';

export const authReducer = (
	state = AUTH_INITIAL_STATE,
	action: IAuthAction
): IAuthState => {
	switch (action.type) {
		case 'auth/SET_AUTH_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'auth/SET_IS_AUTHENTICATED':
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case 'auth/SET_IS_VERIFIED':
			return {
				...state,
				isVerified: action.payload,
			};
		case 'auth/SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'user/LOGOUT':
			return {
				...state,
				user: getInitialUser(),
				isAuthenticated: false,
			};
		default:
			return state;
	}
};
