import axios from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_SERVER_URL } from "config/env";
import { STRINGS } from "data/strings.data";
import { IUser, NotificationType } from "interfaces/users.interfaces";
import { iamQuery } from "data/queries.data";
import {
  setAuthLoading,
  setAuthUser,
  setAuthUserIsAuthenticated,
  setAuthUserIsVerified,
} from "store/modules/Auth/actions";
import { logoutService } from "services/auth/logout.service";
import {APP_PATHS} from "../../data/paths.data";
import {Redirect} from "react-router-dom";
import React from "react";

export const fetchAuthUserService = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);
    
  if (!token) {
    dispatch(setAuthLoading(false));
    return;
  }

  let user: IUser;

  try {
    dispatch(setAuthLoading(true));

    const res = await axios.post(
      API_SERVER_URL,
      { query: iamQuery },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    user = res.data.data?.iam;
  } catch (err) {
    console.error(err);
    dispatch(setAuthLoading(false));
  }

  if (!user) {
    dispatch(setAuthLoading(false));
    return logoutService();
  }

  if (user) {
    dispatch(setAuthUserIsVerified(user.isVerifiedSMS));
    dispatch(setAuthUser(user));

    const notifications: NotificationType[] | any = JSON.parse(
      localStorage.getItem(user.phoneNumber + "notifications") ?? "[]"
    );

    const newNotifications = user.notifications.filter(({ body }) => {
      return !notifications.some((item) => item.body === body);
    }, notifications);

    localStorage.setItem(
      user.phoneNumber + "notifications",
      JSON.stringify([
        ...notifications,
        ...newNotifications.map((item) => ({ ...item, status: "new" })),
      ])
    );

    dispatch(setAuthUserIsAuthenticated(true));
  }

  dispatch(setAuthLoading(false));

  return user;
};
