import React, { Fragment } from 'react'

import Footer from '../complex/Footer'


const AuthLayout: React.FC = ({ children }) => {
	return (
		<Fragment>
			<main className="main">
				{children}
			</main>
			<Footer />
		</Fragment>
	)
}

export default AuthLayout
