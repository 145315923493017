import classNames from "classnames";
import { IModalProps } from "./interfaces";
import "./style.scss";

const Modal: React.FC<IModalProps> = ({
  isVisible,
  onCancel,
  className,
  width,
  children,
}) => {
  return (
    <div
      className={classNames(["modal", className], {
        "modal--is-visible": isVisible,
        "modal--width-auto": width === "auto",
      })}
    >
      <div className='modal__wrapper'>
        <div className='modal__empty-space clickable' onClick={onCancel} />

        <div className='modal__content-wrap'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
