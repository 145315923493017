import { Modal } from "components/common";
import { IModalProps } from "components/common/Modal/interfaces";
import "./styles.scss";

export const TelegramModal: React.FC<IModalProps> = (props) => (
  <Modal {...props}>
    <div className='telegram_modal'>
      <span className='title_27 mb_20 text_center'>
        Для общения в чат боте, Вам необходимо установить Telegram на компьютер,
        либо зайти на данную страницу с мобильного устройства, на котором
        установлен Telegram
      </span>

      <div className='modal_footer'>
        <a
          href='https://telegram.org/'
          target='_'
          onClick={props.onCancel}
          className='button_primary_outline mt_10 inline-block trz'
        >
          Установить Telegram
        </a>
        <a
          href='https://t.me/bankrot_doc_bot'
          target='_'
          onClick={props.onCancel}
          className='button_primary mt_10 white inline-block trz'
        >
          Перейти в чат бот
        </a>
      </div>
    </div>
  </Modal>
);
