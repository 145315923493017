/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { APP_PATHS } from "data/paths.data";
import { registerService } from "services/auth/register.service";
import { IRegisterFrom } from "interfaces/auth.interfaces";
import { PROJECT_NAME_HTML_RUS } from "data/common.data";
import { Input } from "antd";
const RegisterPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState<IRegisterFrom>({
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "+7",
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const handleChange = (e: any) => {
    if (e.target.name === "phoneNumber") {
      if (/^((\+[0-9]{1,2})+([0-9]){0,11})$/.test(e.target.value)) {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { passwordConfirm, ...formDto } = form;

    if (passwordConfirm !== form.password) {
      return setErrorMessage("Убедитесь что повторили пароль правильно");
    }

    const res = await registerService(formDto);

    if (!res.success) {
      return setErrorMessage(
        res.message || "Ошибка сервера, повторите через несколько минут"
      );
    }

    window.location.reload();
  };

  return (
    <Fragment>
      <div className='wrapper__little entrance'>
        <h1 className='title'><img src="img/logo.svg" style={{height: "250px", width: "300px"}} alt="" /></h1>
        <p className='text null entrance__about'>
          Единая система формирования заявлений о банкротстве физических лиц
        </p>
        <form className='form' onSubmit={handleSubmit}>
          <h2 className='form__title title_28 null'>Регистрация</h2>
          <input
            type='text'
            name='lastName'
            value={form.lastName}
            onChange={handleChange}
            className='input input_mt'
            placeholder='Фамилия'
            required
          />
          <input
            type='text'
            name='firstName'
            value={form.firstName}
            onChange={handleChange}
            className='input input_mt2'
            placeholder='Имя'
            required
          />
          <input
            type='text'
            name='middleName'
            value={form.middleName}
            onChange={handleChange}
            className='input input_mt2'
            placeholder='Отчество'
            required
          />
          <input
            type='tel'
            name='phoneNumber'
            pattern='^((\+?[0-9]{1,2})+([0-9]){10})$'
            value={form.phoneNumber}
            onChange={handleChange}
            className='input input_mt2'
            placeholder='+7ХХХХХХХХХХ'
            minLength={12}
            maxLength={12}
            required
          />
          <input
            type='email'
            name='email'
            value={form.email}
            onChange={handleChange}
            className='input input_mt2'
            placeholder='Электронная почта'
            required
          />
          <Input.Password
            name='password'
            value={form.password}
            onChange={handleChange}
            placeholder='Пароль'
            autoComplete='false'
            required
          />
          <Input.Password
            name='passwordConfirm'
            value={form.passwordConfirm}
            onChange={handleChange}
            placeholder='Повторите пароль'
            autoComplete='false'
            required
          />

          {errorMessage && (
            <span
              style={{ display: "block", color: "#FF4040", marginTop: "10px" }}
            >
              {errorMessage}
            </span>
          )}

          <p className='entrance__text text_14_17'>
            Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с{" "}
            <a href='#' className='blue bold'>
              Условиями использования
            </a>{" "}
            и{" "}
            <a href='#' className='blue bold'>
              Политикой конфиденциальности
            </a>
          </p>
          <button
            type='submit'
            className='entrance_btn2 button button_blue v2 trz'
          >
            Зарегистрироваться
          </button>
        </form>
        <span className='entrance__texts text_14'>
          Уже зарегистрировались?{" "}
          <Link to={APP_PATHS.LOGIN} className='link_blue'>
            Войти
          </Link>
        </span>
      </div>
    </Fragment>
  );
};

export default RegisterPage;
