import axios from 'axios';
import { API_SERVER_URL } from 'config/env';
import { STRINGS } from 'data/strings.data';
import { ICodeDto, IRegisterDto } from 'interfaces/auth.interfaces';
import {
  registerMutation,
  sendVerificationSMSMutation,
  sendVerificationEmailMutation,
  verifySMSMutation,
  verifyEmailMutation,
} from 'data/mutations.data';

export const registerService = async (data: IRegisterDto) => {
  let token;

  try {
    const res = await axios.post(API_SERVER_URL, {
      query: registerMutation,
      variables: data,
    });

    if (res.data?.errors) {
      let isUserExist;

      res.data.errors.forEach(({ message }) => {
        if (message.includes('Unique constraint')) {
          isUserExist = true;
        }
      });

      console.log(res.data?.errors, isUserExist);

      if (isUserExist) {
        return {
          success: false,
          message: 'Пользователь с таким email или номером уже существует',
        };
      }

      return {
        success: false,
        message: 'Ошибка сервера, повторите через несколько минут',
      };
    }

    token = res.data.data?.register;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: 'Ошибка сервера, повторите через несколько минут',
    };
  }

  if (!token) {
    return {
      success: false,
      message: 'Ошибка сервера, повторите через несколько минут',
    };
  }

  localStorage.setItem('authToken', token);

  try {
    await axios.post(
      API_SERVER_URL,
      {
        query: sendVerificationSMSMutation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
    return {
      success: false,
      message: 'Ошибка сервера, повторите через несколько минут',
    };
  }

  await sendVerificationSMSService();

  await sendVerificationEmailService();

  return {
    success: true,
  };
};

export const sendVerificationSMSService = async () => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  try {
    await axios.post(
      API_SERVER_URL,
      {
        query: sendVerificationSMSMutation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const sendVerificationEmailService = async () => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  try {
    await axios.post(
      API_SERVER_URL,
      {
        query: sendVerificationEmailMutation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const phoneVerificationService = async (data: ICodeDto) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: verifySMSMutation,
        variables: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.data?.data.verifySMS) {
      return res.data?.data.verifySMS;
    }
  } catch (err) {
    console.error(err);
    return false;
  }

  return false;
};

export const emailVerificationService = async (data: ICodeDto) => {
  const token =
    sessionStorage.getItem(STRINGS.AUTH_TOKEN) ||
    localStorage.getItem(STRINGS.AUTH_TOKEN);

  try {
    const res = await axios.post(
      API_SERVER_URL,
      {
        query: verifyEmailMutation,
        variables: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.data?.data.verifyEmail) {
      return res.data?.data.verifyEmail;
    }
  } catch (err) {
    console.error(err);
    return false;
  }

  return false;
};
