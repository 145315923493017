import {
  IPropertyTypeList,
  INITIAL_BANK_ACCOUNT,
  INITIAL_STOCK,
  INITIAL_OTHER_SECURITIES, INITIAL_VALUABLE_PROPERTY_ITEM,
} from 'interfaces/property.interfaces'; // INITIAL_IMMOVABLE_PROPERTY_ITEM, INITIAL_MOVABLE_PROPERTY_ITEM, INITIAL_VALUABLE_PROPERTY_ITEM



export const immovablePropertyTypes: IPropertyTypeList = {
  land: {
    title: 'Земельные участки',
    type: 'Земельный участок'
  },
  house: {
    title: 'Жилые дома, дачи',
    type: 'Жилой дом'
  },
  apartment: {
    title: 'Квартиры',
    type: 'Квартира'
  },
  garage: {
    title: 'Гаражи',
    type: 'Гараж'
  },
  other: {
    title: 'Иное недвижимое имущество',
    type: 'Иное недвижимое имущество'
  },
};

export const movablePropertyTypes: IPropertyTypeList = {
  carPassenger: {
    title: 'Автомобили легковые',
  },
  truck: {
    title: 'Автомобили грузовые',
  },
  motorVehicle: {
    title: 'Мототранспортные средства',
  },
  agriculturalMachinery: {
    title: 'Сельскохозяйственная техника',
  },
  waterTransport: {
    title: 'Водный транспорт',
  },
  airTransport: {
    title: 'Воздушный транспорт',
  },
  otherTransport: {
    title: 'Иные транспортные средства',
  },
};

export const valuablePropertyTypes: IPropertyTypeList = {
  cash: {
    title: 'Наличные денежные средства',
  },
  jewerly: {
    title:
      'Драгоценности, в том числе ювелирные украшения, и другие предметы роскоши',
  },
  artObject: {
    title: 'Предметы искусства',
  },
  profEquipment: {
    title: 'Имущество, необходимое для профессиональных занятий',
  },
  other: {
    title: 'Иное ценное имущество',
  },
};

export const INITIAL_PROPERTY = () => {
  const dataFromSS = sessionStorage.getItem('inventoryOfAcitizensProperty');
  if (dataFromSS) return JSON.parse(dataFromSS);

  const result: any = {};

  // Immovable property
  result.immovableProperty = {};
  Object.keys(immovablePropertyTypes).forEach((typeKey) => {
    result.immovableProperty[typeKey] = [
      {
        name: immovablePropertyTypes[typeKey].type,
        ownType: '',
        location: '',
        space: '',
        purchaseReason: '',
        ownerData: '',
      },
    ];
  });

  // Movable property
  result.movableProperty = {};
  Object.keys(movablePropertyTypes).forEach((typeKey) => {
    result.movableProperty[typeKey] = [
      {
        name: '',
        id: '',
        propertyType: '',
        location: '',
        cost: '',
        ownerData: '',
      },
    ];
  });

  // Bank accounts (банковские счета)
  result.bankAccounts = [{ ...INITIAL_BANK_ACCOUNT }];

  // Stocks (акции)
  result.stocks = [{ ...INITIAL_STOCK }];

  // Other securities (иные ценные бумаги)
  result.otherSecurities = [{ ...INITIAL_OTHER_SECURITIES }];

  // Other valuablue property
  result.valuableProperty = {};
  Object.keys(valuablePropertyTypes).forEach((typeKey) => {
    result.valuableProperty[typeKey] = [ { ...INITIAL_VALUABLE_PROPERTY_ITEM } ];
  });

  return result;
};
