import React from 'react'

import { Modal } from 'components/common'
import { IModalProps } from 'components/common/Modal/interfaces'
import {deleteUserService} from "../../../services/auth/deleteUser.service";


const DeleteAccountModal: React.FC<IModalProps> = (props) => {
	return (
		<Modal {...props}>
			<div className="twosign" id="twosign4">
				<span className="twosign__title title_27">Удаление учетной записи</span>
				{/*<div className="password password_mt2">*/}
				{/*	<span className="password__text text_18_2">Пароль</span>*/}
				{/*	<div className="password__inputBlock">*/}
				{/*		<input type="password" className="password__input trz input" />*/}
				{/*		<span className="password__show trz">Показать</span>*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className="password password_mt2">*/}
				{/*	<span className="password__text text_18_2">Подтвердите действие</span>*/}
				{/*	<div className="password__inputBlock">*/}
				{/*		<img src="img/reCa.png" alt="" />*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div className="twoBtns">
					<button
						onClick={props.onCancel}
						className="button twoBtns__btn button_transparent"
					>Отмена</button>
					<button className="button twoBtns__btn  button_blue" onClick={() => deleteUserService(() => window.location.reload())}>Удалить</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteAccountModal
